/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "../src/components/App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <>
      {(() => {
        const originalConsoleWarn = console.warn;

        console.warn = function (...args) {
          if (args.length > 0 && typeof args[0] === "string" && args[0].includes("[antd: Menu] `children`")) {
            return;
          }
          originalConsoleWarn.apply(console, args);
        };

        return null;
      })()}
      <App />
    </>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
