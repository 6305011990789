/**
 * @since 2023-04-14
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Select } from "antd";
import InputBox from "components/Shared/InputBox";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { validatePercentage } from "utils/validation";
import "./Allocation.scss";
const { Option } = Select;

const Allocation = ({
  selectorId,
  selectorIndex,
  allocationId,
  percentage,
  listAll,
  listRemaining,
  isEdit,
  onUpdate,
  onDelete,
  onValidationErrorsUpdate,
}) => {

  function mapIdsToItems() {
    const result = {};
    result[""] = "";
    listAll.forEach(item => {
      result[item.AllocationId] = item;
    });
    return result;
  }

  const groupByAllocationType = () => {
    if (!listRemaining) return [];
    return listRemaining.reduce((acc, item) => {
      if (!acc[item.AllocationType_DisplayName]) {
        acc[item.AllocationType_DisplayName] = [];
      }
      acc[item.AllocationType_DisplayName].push(item);
      return acc;
    }, {});
  }

  //region States

  const [groupedByType, setGroupedByType] = useState(groupByAllocationType());
  const [dictAllocationIdToItem, setDictAllocationIdToName] = useState(mapIdsToItems());

  //endregion

  //region Use Effects

  useEffect(() => {
    const validateErrors = async () => {
      const listErrors = await getValidationErrors(allocationId, percentage);
      await onValidationErrorsUpdate(selectorId, listErrors);
    };
    validateErrors();
  }, [selectorIndex, allocationId, percentage]);

  useEffect(() => {
    setGroupedByType(groupByAllocationType());
  }, [listRemaining]);

  useEffect(() => {
    setDictAllocationIdToName(mapIdsToItems());
  }, [listAll]);

  //endregion

  //region Methods

  const getName = (item) => {
    if (!item) return "";
    return item.AllocationType_DisplayName + ": " + item.AllocationName;
  }

  const getValidationErrors = (newAllocationId, newPercentage) => {
    if (!newAllocationId) newAllocationId = allocationId;
    if (!newPercentage) newPercentage = percentage;
    const listErrors = [];
    const isAllocationIdValid =
      !newAllocationId || newAllocationId.trim().length === 0;
    if (isAllocationIdValid) {
      listErrors.push(`Allocation in line ${selectorIndex + 1} is missing.`);
    }
    let { isValid: isPercentageValid, errorMessage } = validatePercentage(newPercentage, false);
    if (!isPercentageValid) {
      if (isAllocationIdValid) {
        listErrors.push(
          `Allocation in line ${selectorIndex + 1} percentage ${errorMessage}.`
        );
      } else {
        const allocationName = dictAllocationIdToItem[newAllocationId];
        listErrors.push(`${allocationName} percentage ${errorMessage}.`);
      }
    }
    return listErrors;
  };

  const handlePercentageChange = (event, newPercentage) => {
    const listValidationErrors = getValidationErrors(allocationId, newPercentage);
    onUpdate(
      selectorIndex,
      selectorId,
      allocationId,
      newPercentage,
      listValidationErrors
    );
  };

  const handleSelectedChange = (event, item) => {
    const allocationId = item.value;
    const listValidationErrors = getValidationErrors(allocationId, percentage);
    onUpdate(
      selectorIndex,
      selectorId,
      allocationId,
      percentage,
      listValidationErrors
    );
  };

  const showEditMode = () => {
    return (
      <div className="allocation-entry-table">
        <Select
          showSearch
          onChange={handleSelectedChange}
          className="blue-select allocation-strategy-select"
          placeholder={getName(dictAllocationIdToItem[allocationId])}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          getPopupContainer={(trigger) => trigger.parentElement}
          autoFocus={false}
          options={Object.entries(groupedByType).map(([allocationType, items]) => ({
            label: allocationType,
            options: items.map(item => ({
              label: getName(item),
              value: item.AllocationId,
            })),
          }))}
        />
        <div className="allocation-percentage-input-container">
          <InputBox
            value={percentage}
            maxLength={8}
            onUpdate={handlePercentageChange}
            className="allocation-input-box-width"
          />
        </div>

        <div className="allocation-close-icon-container">
          <IoMdClose
            className="close-icon"
            onClick={() => onDelete(selectorIndex, selectorId)}
          />
        </div>
      </div>
    );
  };

  const showDisplayMode = () => {
    return (
      <div className="allocation-display-container">
        <div className="strategy-name-container">
          {getName(dictAllocationIdToItem[allocationId])}
        </div>
        <div className="percentage-display-container">
          {percentage + "%"}
        </div>
      </div>
    );
  };

  //endregion

  return (
    <div className="allocation-wrapper">
      {isEdit ? showEditMode() : showDisplayMode()}
    </div>
  );
};

export default Allocation;
