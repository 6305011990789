/**
 * @since 2024-05-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

const parseNumber = (value) => {
  try {
    return parseFloat(String(value).replace(/[$,]/g, ""));
  } catch (error) {
    return -Infinity;
  }
};
export { parseNumber };
