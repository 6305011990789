/**
 * @since 2024-05-06
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import PanelProfile from "./PanelProfile";

export default PanelProfile;
