/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import EditableSection from "components/Shared/EditableSection";
import TextCopyright from "components/Shared/TextCopyright";
import TextWait from "components/Shared/TextWait";
import { useEffect, useState } from "react";
import PreviewChart from "./PreviewChart";
import TableAllocations from "./TableAllocations";
import TableRiskManagement from "./TableRiskManagement";
import SettingsTable from "./TableSettings";

const PanelSettings = ({
  email,
  portfolioSettings,
  containsOnlyStrategies,
  isLocked,
  listPortfolioNames,
  configuration,
  dictStrategies,
  dictImported,
  dictPremium,
  onPortfolioSettingsChange,
  onAnalysisClick,
}) => {
  const [isEdit_Enabled, setIsEdit_Enabled] = useState(true);
  const [isEdit_Settings, setIsEdit_Settings] = useState(false);
  const [isEdit_Allocations, setIsEdit_Allocations] = useState(false);
  const [isEdit_RiskManagement, setIsEdit_RiskManagement] = useState(false);

  useEffect(() => {
    const isEnabled =
      !isLocked &&
      !(isEdit_Settings || isEdit_Allocations || isEdit_RiskManagement);
    setIsEdit_Enabled(isEnabled);
  }, [isLocked, isEdit_Settings, isEdit_Allocations, isEdit_RiskManagement]);

  const handleAllocationUpdate = (allocations) => {
    const portfolioSettingsEdited = {
      ...portfolioSettings,
      Allocations: allocations,
    };
    onPortfolioSettingsChange(portfolioSettingsEdited);
  };

  const updateRiskManagement = (riskManagement) => {
    const portfolioSettingsEdited = {
      ...portfolioSettings,
      RiskManagement: riskManagement,
    };
    onPortfolioSettingsChange(portfolioSettingsEdited);
  };

  const updateSettings = async (settings) => {
    const portfolioSettingsEdited = {
      ...portfolioSettings,
      Settings: settings,
    };
    onPortfolioSettingsChange(portfolioSettingsEdited);
  };

  return (
    <>
      {!portfolioSettings ? (
        <TextWait />
      ) : (
        <>
          <EditableSection
            title={"SETTINGS"}
            isOpened={true}
            canEdit={isEdit_Enabled}
            isEdit={isEdit_Settings}
            updateIsEdit={setIsEdit_Settings}
          >
            <SettingsTable
              settings={portfolioSettings}
              listPortfolioNames={listPortfolioNames}
              configuration={configuration}
              onSettingsChange={updateSettings}
              isEdit={isEdit_Settings}
              onIsEditUpdate={setIsEdit_Settings}
            />
          </EditableSection>
          <EditableSection
            title={"ALLOCATIONS"}
            isOpened={true}
            canEdit={isEdit_Enabled}
            isEdit={isEdit_Allocations}
            updateIsEdit={setIsEdit_Allocations}
          >
            <TableAllocations
              allocationSettings={portfolioSettings.Allocations}
              dictStrategies={dictStrategies}
              dictImported={dictImported}
              dictPremium={dictPremium}
              onSettingsChange={handleAllocationUpdate}
              isEdit={isEdit_Allocations}
              onIsEditUpdate={setIsEdit_Allocations}
            />
          </EditableSection>
          {containsOnlyStrategies && (
            <EditableSection
              title={"RISK MANAGEMENT"}
              isOpened={true}
              canEdit={isEdit_Enabled}
              isEdit={isEdit_RiskManagement}
              updateIsEdit={setIsEdit_RiskManagement}
            >
              <TableRiskManagement
                settings={portfolioSettings}
                onSettingsChange={updateRiskManagement}
                isEdit={isEdit_RiskManagement}
                onIsEditUpdate={setIsEdit_RiskManagement}
              />
            </EditableSection>
          )}
          <EditableSection
            title={"PREVIEW"}
            isOpened={true}
            canEdit={false}
            isEdit={false}
          >
            <PreviewChart email={email} portfolioSettings={portfolioSettings} />
          </EditableSection>
          <br />
          <br />
          <ButtonBlue label="SEE ANALYSIS" onClick={onAnalysisClick} />
          <TextCopyright />
        </>
      )}
    </>
  );
};
export default PanelSettings;
