/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import DateRangeSelector from "components/Shared/DateRangeSelector";
import InputBox from "components/Shared/InputBox";
import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toDollars } from "utils/formatting";
import { validateAmount, validateDate, validateName } from "utils/validation";
import "./TableSettings.scss";

const TableSettings = ({ configuration, listPortfolioNames, settings, onSettingsChange, isEdit, onIsEditUpdate }) => {
  const intl = useIntl();

  const getDefaultSettings = () => {
    return {
      Name: {
        type: "InputBox",
        fieldName: "PortfolioName",
        value: settings.Settings.PortfolioName,
        tooltip: intl.formatMessage({ id: "portfoliobuilder-settings-name" }),
      },
      Capital: {
        type: "Input.Dollar",
        fieldName: "Capital",
        value: toDollars(settings.Settings.Capital),
        tooltip: intl.formatMessage({ id: "portfoliobuilder-settings-capital" }),
      },
      Description: {
        type: "MultiLineInputBox",
        fieldName: "Description",
        value: settings.Settings.Description,
        tooltip: intl.formatMessage({ id: "portfoliobuilder-settings-description" }),
      },
      Benchmark: {
        type: "MultipleChoiceBox",
        fieldName: "Benchmark",
        value: settings.Settings.Benchmark,
        choices: configuration.Benchmarks.map((item) => item.BenchmarkName),
        tooltip: intl.formatMessage({ id: "portfoliobuilder-settings-benchmark" }),
      },
      Period: {
        type: "DatePeriod",
        fieldName: "Period",
        startDate: settings.Settings.StartDate,
        endDate: settings.Settings.EndDate,
        tooltip: intl.formatMessage({ id: "portfoliobuilder-settings-period" }),
      },
    };
  };

  const [settingsFields, setSettingsFields] = useState(getDefaultSettings());
  const listLeftSettings = ["Name", "Description"];
  const listRightSettings = ["Capital", "Benchmark", "Period"];
  const [listValidationErrors, setListValidationErrors] = useState([]);

  useEffect(() => {
    const listErrors = [];
    // Portfolio Name
    const portfolioName = String(settingsFields["Name"].value).trim();
    let { isValid, errorMessage } = validateName(portfolioName);
    if (!isValid) listErrors.push(`Portfolio Name: ${errorMessage}`);
    // Portfolio Name - Already Used
    if (portfolioName != settings.Settings.PortfolioName && listPortfolioNames.includes(portfolioName)) {
      listErrors.push("Portfolio name already used.");
    }
    // Capital
    const capital = String(settingsFields["Capital"].value).trim();
    ({ isValid, errorMessage } = validateAmount(capital));
    if (!isValid) listErrors.push(`Capital: ${errorMessage}`);
    // Start Date
    const startDate = String(settingsFields["Period"].startDate).trim();
    ({ isValid, errorMessage } = validateDate(startDate));
    if (!isValid) listErrors.push(`Start Date: ${errorMessage}`);
    // End Date
    const endDate = String(settingsFields["Period"].endDate).trim();
    ({ isValid, errorMessage } = validateDate(endDate, startDate));
    if (!isValid) listErrors.push(`End Date: ${errorMessage}`);
    setListValidationErrors(listErrors);
  }, [settingsFields]);

  const cancel = () => {
    setSettingsFields(getDefaultSettings());
    onIsEditUpdate(false);
  };

  const handleUpdateStartDate = (newStartDate) => {
    setSettingsFields({
      ...settingsFields,
      Period: {
        ...settingsFields["Period"],
        startDate: newStartDate,
      },
    });
  };

  const handleUpdateEndDate = (newEndDate) => {
    setSettingsFields({
      ...settingsFields,
      Period: {
        ...settingsFields["Period"],
        endDate: newEndDate,
      },
    });
  };

  const save = () => {
    const newSettings = { ...settings.Settings };
    for (const name in settingsFields) {
      const fieldName = settingsFields[name].fieldName;
      if (fieldName === "Period") {
        newSettings["StartDate"] = settingsFields["Period"].startDate;
        newSettings["EndDate"] = settingsFields["Period"].endDate;
      } else newSettings[fieldName] = settingsFields[name].value;
    }
    onSettingsChange(newSettings);
    onIsEditUpdate(false);
  };

  const handleInputChange = (settingName, settingNewValue) => {
    let newValue = String(settingNewValue);
    if (settingsFields[settingName].type === "Input.Dollar") {
      // Add thousand separator
      newValue = toDollars(newValue);
    }
    setSettingsFields({
      ...settingsFields,
      [settingName]: {
        ...settingsFields[settingName],
        value: newValue,
      },
    });
  };

  const renderErrors = () => {
    return (
      <div className="errors-container">
        {listValidationErrors.map((error) => (
          <div className="error">{error}</div>
        ))}
      </div>
    );
  };

  const renderSettings = (listSettings) => {
    return listSettings.map((name) => (
      <div className="setting-item" key={`${name}.Outer`}>
        <div className="setting-row" key={`${name}.Main`}>
          <div className="setting-name" key={`${name}.Name`} style={{ marginTop: isEdit ? "12px" : "0px" }}>
            <Tooltip tooltipText={settingsFields[name].tooltip} size="small">
              {name}
            </Tooltip>
          </div>
          <div className="setting-value" key={`${name}.Value`}>
            {isEdit
              ? renderEditInputField(name, settingsFields[name])
              : renderViewInputField(name, settingsFields[name])}
          </div>
        </div>
      </div>
    ));
  };

  const renderEditInputField = (name, item) => {
    switch (item.type) {
      case "InputBox":
      case "Input.Dollar":
        return <InputBox name={name} value={item.value} maxLength={30} onUpdate={handleInputChange} />;
      case "MultiLineInputBox":
        return <InputBox name={name} value={item.value} nRows={7} maxLength={400} onUpdate={handleInputChange} />;
      case "MultipleChoiceBox":
        return <MultipleChoiceBox name={name} choices={item.choices} value={item.value} onUpdate={handleInputChange} />;
      case "DatePeriod":
        return (
          <DateRangeSelector
            startDate={settingsFields["Period"].startDate}
            endDate={settingsFields["Period"].endDate}
            onUpdateStartDate={handleUpdateStartDate}
            onUpdateEndDate={handleUpdateEndDate}
            isEditMode={true}
          />
        );
      default:
        console.log(`Error: ${name} has unknown type ${item.type}`);
    }
  };

  const renderViewInputField = (name, item) => {
    if (name === "Benchmark") {
      return item.value === "None" ? "Not selected" : item.value;
    }
    switch (item.type) {
      case "DatePeriod":
        return (
          <DateRangeSelector
            startDate={settingsFields["Period"].startDate}
            endDate={settingsFields["Period"].endDate}
            onUpdateStartDate={handleUpdateStartDate}
            onUpdateEndDate={handleUpdateEndDate}
            isEditMode={false}
          />
        );
      default:
        return item.value;
    }
  };

  return (
    <div className="table-settings-portfolio">
      <div className="table-settings-container">
        <div className="table-settings-left">
          {renderSettings(listLeftSettings)}
          {isEdit && renderErrors()}
        </div>
        <div className="table-settings-right">{renderSettings(listRightSettings)}</div>
      </div>
      {isEdit && <BarSaveCancel onCancel={cancel} onSave={save} isSaveDisabled={listValidationErrors.length > 0} />}
    </div>
  );
};
export default TableSettings;
