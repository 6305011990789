/**
@since 2024-05-26
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright All rights reserved
*/

import ChartReturns from "components/Shared/ChartReturns";
import { useEffect, useState } from "react";

const ItemChartPerformance = ({
  itemData,
  strategyName,
  category,
  chartType,
  chartFilter,
}) => {
  const [chartData, setChartData] = useState(null);
  const [chartCurrency, setChartCurrency] = useState("$");

  useEffect(() => {
    if (!itemData || !strategyName || !category || !chartType || !chartFilter)
      return;
    refreshChart(strategyName, category, chartType, chartFilter);
  }, [itemData, strategyName, category, chartType, chartFilter]);

  const refreshChart = async (
    strategyName,
    category,
    chartType,
    chartFilter
  ) => {
    if (chartType === "No Chart") {
      setChartData(null);
      return;
    }
    // Set names
    const chartName = chartType.slice(0, -4); // e.g. Profit & Loss
    const chartCurrency = chartType.slice(-2, -1); // e.g. $
    setChartCurrency(chartCurrency);
    /*
    // Log
    console.log("strategyName", strategyName);
    console.log("category", category);
    console.log("chartType", chartType);
    console.log("chartName", chartName);
    console.log("chartCurrency", chartCurrency);
    console.log("chartFilter", chartFilter);
    console.log("itemData", itemData);
    */
    const listEntryNames = getListEntryNames(
      strategyName,
      category,
      chartFilter
    );
    const listChartValues = [];
    if (["Position", "Price"].includes(chartType)) {
      for (const entryName of listEntryNames) {
        listChartValues.push({
          Name: entryName,
          Description: "",
          Returns: Object.values(itemData).map(
            (item) => item[strategyName][category][entryName][chartType]
          ),
        });
      }
    } else {
      for (const entryName of listEntryNames) {
        listChartValues.push({
          Name: entryName,
          Description: "",
          Returns: Object.values(itemData).map(
            (item) =>
              item[strategyName][category][entryName][chartName][chartCurrency]
          ),
        });
      }
    }
    const dictChartData = {
      Title: chartName,
      Dates: Object.keys(itemData),
      Values: listChartValues,
    };
    setChartData(dictChartData);
  };

  const getListEntryNames = (strategyName, category, chartFilter) => {
    if (chartFilter !== "All") {
      return [chartFilter];
    }
    const listSnapshots = Object.keys(itemData);
    const snapshot = listSnapshots[listSnapshots.length - 1];
    const listEntryNames = Object.keys(
      itemData[snapshot][strategyName][category]
    );
    if (category === "By Instrument") {
      delete listEntryNames["Overall"];
    }
    return listEntryNames;
  };

  return (
    <>
      {!chartData ? null : (
        <ChartReturns
          chartData={chartData}
          isFirstOnFront={true}
          formatValues={chartCurrency}
          includeY0={false}
          startFrom0={chartType.includes("Profit & Loss")}
        />
      )}
    </>
  );
};
export default ItemChartPerformance;
