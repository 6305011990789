/**
 * @since 2023-08-01
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Modal } from "antd";
import BarSaveCancel from "components/Shared/BarSaveCancel";
import DisplayBox from "components/Shared/DisplayBox";
import InputBox from "components/Shared/InputBox";
import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import { parseReturnsFile } from "dataHandling/general";
import React, { useEffect, useState } from "react";
import { validatePercentage } from "utils/validation";
import "./ModalReturnsSelector.scss";
import TableReturns from "./TableReturns";

const ModalReturnsSelector = ({ isOpen, filename, dates, returns, onSave, onCancel }) => {
  const [newFilename, setNewFilename] = useState(filename ? "Select a file containing returns" : "");
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [dictReturns, setDictReturns] = useState({});
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [listColumns, setListColumns] = useState([]);
  const [returnsMultiplier, setReturnsMultiplier] = useState(1);

  const fileInputRef = React.createRef();

  useEffect(() => {
    refreshAll();
  }, [isOpen]);

  useEffect(() => {
    if (!returns) {
      setDictReturns({});
      return;
    }
    setDictReturns({
      Columns: returns ? ["Returns"] : [],
      Dates: dates,
      Returns: returns,
    });
  }, [dates, returns]);

  const refreshAll = async () => {
    //
  };

  const handleFileChange = async (event) => {
    if (!event.target.files.length) {
      // No file selected, user probably cancelled the dialog
      return;
    }
    const file = event.target.files[0];
    setIsFileLoading(true);
    setNotificationMessage("Loading");
    setErrorMessage("");
    setDictReturns({});
    setSelectedColumn("");
    setListColumns([]);
    const newDictReturns = await parseReturnsFile(file);
    setNotificationMessage("");
    if (!newDictReturns || newDictReturns["Columns"].length === 0) {
      setErrorMessage("Could not load " + file.name + ". The first column should contain dates.");
      setIsFileLoading(false);
      return;
    }
    setErrorMessage("");
    setDictReturns(newDictReturns);
    setNewFilename(file.name);
    setSelectedColumn(newDictReturns["Columns"][0]);
    setListColumns(newDictReturns["Columns"]);
    setIsFileLoading(false);
  };

  const handleSelectedColumn = (controlName, newValue) => {
    setSelectedColumn(newValue);
  };

  const handleFileInput = () => {
    fileInputRef.current.click();
  };

  const handleMultiplierChange = (name, value) => {
    const { isValid, errorMessage } = validatePercentage(value, false, 0, 1000);
    setReturnsMultiplier(value);
    if (isValid) {
      setErrorMessage("");
    } else {
      setErrorMessage(`Returns multiplier not valid`);
    }
  };

  const handleSave = () => {
    const newDates = dictReturns["Dates"];
    const multiplier = parseFloat(returnsMultiplier);
    const columnIndex = listColumns.findIndex((element) => element === selectedColumn);
    const newReturns = dictReturns["Data"].map((row) => parseFloat(row[columnIndex]) * multiplier);
    onSave(newFilename, newDates, newReturns);
  };

  return (
    <Modal
      open={isOpen}
      title={"RETURNS SELECTION"}
      footer={null}
      onCancel={onCancel}
      className="modal-returns-selector"
    >
      <div className="file-upload-container">
        <div className="file-upload-wrapper">
          <div className="file-upload-label">Choose a file to upload:</div>
          <div className="file-upload-box">
            <DisplayBox
              name="FilenameSelector"
              value={newFilename}
              onClick={handleFileInput}
              disabled={isFileLoading}
            />
            <input
              type="file"
              ref={fileInputRef}
              className="file-input"
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .pickle"
              onChange={isFileLoading ? null : handleFileChange}
            />
          </div>
        </div>
      </div>
      <div className="column-selector-container">
        <div className="column-label">Column containing returns:</div>
        <div className="multiple-choice-wrapper">
          <MultipleChoiceBox
            name={"SelectColumn"}
            choices={listColumns}
            value={selectedColumn}
            onUpdate={handleSelectedColumn}
          />
        </div>
      </div>
      <div className="column-selector-container">
        <div className="column-label">Returns Multiplier:</div>
        <div className="multiple-choice-wrapper">
          {selectedColumn ? (
            <InputBox name="Multiplier" value={returnsMultiplier} onUpdate={handleMultiplierChange} />
          ) : (
            <div className="multiplier-wrapper">{returnsMultiplier}</div>
          )}
        </div>
      </div>
      <div className="notification-message">{notificationMessage}</div>
      <div className="error-message">{errorMessage}</div>
      <br />
      <div className="table-returns-container">
        <TableReturns returnsData={dictReturns} />
      </div>
      <BarSaveCancel
        onCancel={onCancel}
        onSave={handleSave}
        isSaveDisabled={selectedColumn.length === 0 || errorMessage.length > 0}
      />
    </Modal>
  );
};

export default ModalReturnsSelector;
