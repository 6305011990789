/**
 * @since 2024-01-20
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import "./TextMessage.scss";

const TextMessage = ({ message }) => {
  return <div className="text-message">{message}</div>;
};

export default TextMessage;
