/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ItemTableScores from "components/Shared/Report/Items/ItemTableScores";
import { useEffect, useState } from "react";
import "./TableScoresWithFilters.scss";

const TableScoresWithFilters = ({ scoresTable, showRecommendations = true }) => {

  //region Constants

  const buttonsSettings = [
    {
      value: "All",
      label: "All",
      color: "#78BCFB",
    },
    {
      value: "Pass",
      label: "Pass",
      color: "green",
    },
    {
      value: "Warn",
      label: "Warn",
      color: "orange",
    },
    {
      value: "Fail",
      label: "Fail",
      color: "red",
    },
  ];

  //endregion

  //region States

  const [scoresTableFiltered, setScoresTableFiltered] = useState(scoresTable);
  const [selectedStatus, setSelectedStatus] = useState("All");

  //endregion

  //region Use effects

  useEffect(() => {
    setScoresTableFiltered(filterScoresTable(scoresTable, selectedStatus));
  }, [scoresTable]);

  //endregion

  //region Methods

  function filterScoresTable(scoresTable, newStatus) {
    if (newStatus === "All") return scoresTable;
    const filteredData = { ...scoresTable };

    filteredData.ValuesByCategory = scoresTable.ValuesByCategory.map(
      (category) => {
        const filteredCategory = { ...category };

        filteredCategory.Items = category.Items.filter(
          (item) => item[3] === newStatus
        );

        return filteredCategory;
      }
    ).filter((category) => category.Items.length > 0);

    return filteredData;
  }

  function getNumberOfElements(status) {
    let n = 0;
    scoresTable.ValuesByCategory.forEach((category) => {
      category.Items.forEach((item) => {
        if (status === "All" || item[3] === status) {
          n++;
        }
      });
    });
    return n;
  }

  const handleFilterSelected = (event, newStatus) => {
    if (newStatus === null) return;
    setSelectedStatus(newStatus);
    setScoresTableFiltered(filterScoresTable(scoresTable, newStatus));
  };

  //endregion

  return (
    < div className="tableScoresWithFilters" >
      <div className="panelHeader">
        <ToggleButtonGroup
          value={selectedStatus}
          exclusive
          onChange={handleFilterSelected}
          aria-label="view"
        >
          {buttonsSettings.map(({ value, label, color }, index) => (
            <ToggleButton
              key={value}
              value={value}
              aria-label={label}
              style={{
                color,
                borderColor: selectedStatus === value ? color : "initial",
                borderWidth: selectedStatus === value ? "1px" : "initial",
                borderStyle: selectedStatus === value ? "solid" : "initial",
                marginRight:
                  index !== buttonsSettings.length - 1 ? "1px" : "0px",
              }}
            >
              {`${label} (${getNumberOfElements(value)})`}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <ToggleButtonGroup
          className="buttonGroupRight"
          value={selectedStatus}
          exclusive
          onChange={null}
          aria-label="view"
        >
          <ToggleButton
            key={"OverallScore"}
            value={"OverallScore"}
            aria-label={"Overall Score"}
            className="overallScoreButton"
          >
            {"   OVERALL SCORE: " + scoresTable.OverallScore + "%   "}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <ItemTableScores
        itemData={scoresTableFiltered}
        showHeaders={true}
        showRecommendations={showRecommendations}
      />
    </div >
  );
};

export default TableScoresWithFilters;
