/**
 * @since 2023-10-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import "./PanelHeader.scss";

const PanelHeader = ({
  menuItem,
  icon,
  toggleButtonGroup,
  isNavBarVisible,
}) => {
  return (
    <div className={`panelHeader ${!isNavBarVisible ? "nav-visible" : ""}`}>
      <h1 className="title-header">
        <div
          className="title-content"
          title={menuItem.ItemId}
          test-id="view-title"
        >
          {icon}
          {menuItem.getTitle()}
        </div>
        {toggleButtonGroup}
      </h1>
    </div>
  );
};

export default PanelHeader;
