/**
 * @since 2023-10-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import "./TextCopyright.scss";

const TextCopyright = () => {
  return (
    <div className="textCopyright">
      © 2024 Central Limit Technologies. All Rights Reserved. Patent 63/581252.
      <br />
    </div>
  );
};

export default TextCopyright;
