/**
 * @since 2024-05-26
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ItemChartPerformance from "./ItemChartPerformance";

export default ItemChartPerformance;
