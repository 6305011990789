/**
 * @since 2023-10-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TableSettings_TextMessage from "./TableSettings_TextMessage";

export default TableSettings_TextMessage;
