/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { useState } from "react";
import "./MultipleChoiceBox.scss";

const MultipleChoiceBox = ({ name, choices, value, onUpdate }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    onUpdate(name, event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      key={`MultipleChoiceBox.${name}`}
      className={`clearable-combobox ${isFocused ? "focused" : ""}`}
    >
      <div
        key={`MultipleChoiceBox.${name}.Container`}
        className="combobox-container"
      >
        <select
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {choices.map((choice) => (
            <option key={choice} value={choice}>
              {choice}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export default MultipleChoiceBox;
