/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import BarActionsAnalysis from "components/Shared/BarActionsAnalysis";
import ButtonBlue from "components/Shared/ButtonBlue";
import Report from "components/Shared/Report";
import TextCopyright from "components/Shared/TextCopyright";
import TextWait from "components/Shared/TextWait";
import { loadPremiumAnalysis } from "dataHandling/premium";
import { useEffect, useState } from "react";

const PanelAnalysis = ({
  email,
  portfolioId,
  portfolioName,
  onScoresClick,
}) => {
  //region States

  const [status, setStatus] = useState("");
  const [analysisData, setAnalysisData] = useState({});

  //endregion

  //region Use Effects

  useEffect(() => {
    fetchData();
  }, [portfolioId, email]);

  //endregion

  //region Methods

  const fetchData = async () => {
    const newAnalysisData = await loadPremiumAnalysis(email, portfolioId);
    setAnalysisData(newAnalysisData);
    setStatus("OK");
  };

  //endregion

  return (
    <>
      {status === "WAITING" && <TextWait />}
      {status === "OK" && (
        <>
          <Report
            email={email}
            moduleType={"PREMIUM"}
            moduleId={portfolioId}
            reportData={analysisData}
          />
          <br />
          <br />
          <BarActionsAnalysis
            email={email}
            moduleType={"PREMIUM"}
            moduleId={portfolioId}
            moduleName={portfolioName}
            showTrades={true}
          />
          <br />
          <ButtonBlue label="SEE SCORES" onClick={onScoresClick} />
          <TextCopyright />
        </>
      )}
    </>
  );
};
export default PanelAnalysis;
