/**
 * @since 2023-10-29
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Modal } from "antd";
import BarSaveCancel from "components/Shared/BarSaveCancel";
import { getTermsAndConditions } from "dataHandling/general";
import React, { useEffect, useRef, useState } from "react";
import "./ModalTermsAndConditions.scss";

const ModalTermsAndConditions = ({ isOpen, onAccept, onDecline }) => {
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const textContainerRef = useRef(null);

  useEffect(() => {
    (async () => {
      const newTermsAndConditions = await getTermsAndConditions();
      setTermsAndConditions(newTermsAndConditions);
    })();
  }, []);

  useEffect(() => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  function displayTextWithNewlines(text) {
    if (!text) return <div></div>;
    return (
      <div>
        {text.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index !== text.split("\n").length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <Modal
      open={isOpen}
      title={"TERMS AND CONDITIONS"}
      footer={null}
      onCancel={onDecline}
      className="modal-terms-and-conditions"
      centered={true}
    >
      <div ref={textContainerRef} className="text-container">
        {displayTextWithNewlines(termsAndConditions)}
      </div>
      <div className="footer-container">
        <BarSaveCancel onCancel={onDecline} onSave={onAccept} saveText={"accept"} cancelText={"decline"} />
      </div>
    </Modal>
  );
};

export default ModalTermsAndConditions;
