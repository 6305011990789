/**
 * @since 2023-04-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import SectionScoresStart from "./SectionScoresStart";

export default SectionScoresStart;
