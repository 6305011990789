/**
 * @since 2024-01-20
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TextMessage from "./TextMessage";

export default TextMessage;
