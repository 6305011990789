/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { acceptTermsAndConditions, loadMenuItems } from "dataHandling/general";
import {
  initializeUser,
  loadConfigurationData,
  notifyUserLogin,
} from "dataHandling/general.js";
import { login, register } from "dataHandling/user";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  Button,
  Container,
  Form,
  Input,
  Title,
  ToggleButton,
} from "./Login.Style.js";
import "./Login.css";
import ModalTermsAndConditions from "./ModalTermsAndConditions/ModalTermsAndConditions";
import "./electron.d.ts";

const Login = ({ showRegistration = false }) => {
  const [isLogin, setIsLogin] = useState<boolean>(!showRegistration);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [infoMessage, setInfoMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [companyWebsite, setCompanyWebsite] = useState<string>("");
  const [referralCode, setReferralCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const emailRef = useRef<HTMLInputElement>(null);
  const [isOpen_ModalTermsAndConditions, setIsOpen_ModalTermsAndConditions] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.clear();
    if (emailRef.current) {
      emailRef.current.focus();
    }

    // Retrieve stored credentials
    const getStoredCredentials = async () => {
      if (window.electronAPI) {
        const { username, password } =
          await window.electronAPI.getCredentials();
        if (username && password) {
          setEmail(username);
          setPassword(password);
        }
      }
    };
    getStoredCredentials();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    console.log("urlParams", urlParams);
    if (urlParams.has("referral")) {
      const referral = urlParams.get("referral");
      if (referral) {
        setReferralCode(referral);
      }
    }
  }, [location]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLogin) {
      loginUser();
    } else {
      registerNewUser();
    }
  };

  const handleAcceptTermsAndConditions = async () => {
    await acceptTermsAndConditions(email);
    setIsOpen_ModalTermsAndConditions(false);
    setInfoMessage("");
    loginUser();
  };

  const handleDeclineTermsAndConditions = () => {
    setIsOpen_ModalTermsAndConditions(false);
    setInfoMessage("");
  };

  const loginUser = async () => {
    setInfoMessage("Please wait");
    setErrorMessage("");
    let response = await login(email, password);
    if (response.body.success) {
      // Save credentials
      if (window.electronAPI) {
        await window.electronAPI.saveCredentials({ username: email, password });
      }
      // Set cookies
      const cookies = new Cookies();
      cookies.set("token", response.body.accessToken, {
        path: "/",
        expires: new Date(Date.now() + 86400000), // One day
      });
      cookies.set("refresh_token", response.body.refreshToken, {
        path: "/",
        expires: new Date(Date.now() + 86400000), // One day
      });
      setInfoMessage("Success.");
      setErrorMessage("");
      // Load terms and conditions
      const menuItems = await loadMenuItems(email);
      if (menuItems["User"]["termsAndConditions"] == false) {
        setIsOpen_ModalTermsAndConditions(true);
        return;
      }
      // Store data
      localStorage.setItem("email", email);
      const configurationData = await loadConfigurationData(email);
      localStorage.setItem("configuration", JSON.stringify(configurationData));
      notifyUserLogin(email);
      navigate("/", { replace: true });
    } else {
      setPassword("");
      setInfoMessage("");
      setErrorMessage(response.body.error);
    }
  };

  const registerNewUser = async () => {
    setInfoMessage("Please wait...");
    setErrorMessage("");
    const response = await register(
      email,
      password,
      firstName,
      lastName,
      companyName,
      companyWebsite,
      phoneNumber,
      referralCode
    );
    if (response.body.success) {
      setInfoMessage(
        "Registration completed. Please check your email for the verification link."
      );
      setErrorMessage("");
      initializeUser(email);
    } else {
      setInfoMessage("");
      setErrorMessage(response.body.error);
    }
  };

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Title>Central Limit AI</Title>
        <br />
        <Input
          name="email"
          type="email"
          autoComplete="username"
          placeholder="Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value.toLowerCase());
            setInfoMessage("");
            setErrorMessage("");
          }}
          ref={emailRef}
        />
        <Input
          name="password"
          type="password"
          autoComplete="current-password"
          placeholder="Password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
            setInfoMessage("");
            setErrorMessage("");
          }}
        />
        {isLogin ? (
          <>
            <Button type="submit">Login</Button>
            <ToggleButton
              type="button"
              onClick={() => {
                setIsLogin(false);
                setInfoMessage("");
                setErrorMessage("");
              }}
            >
              <br />
              Register
            </ToggleButton>
            <Link to="/reset-password" className="link">
              Forgot password?
            </Link>
            <Link
              to="mailto:support@centrallimit.ai?subject=Central%20Limit%20AI%20Login%20Support"
              className="link"
            >
              Contact support
            </Link>
          </>
        ) : (
          <>
            <Input
              name="firstName"
              type="input"
              placeholder="First Name"
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(e.target.value);
                setInfoMessage("");
                setErrorMessage("");
              }}
            />
            <Input
              name="lastName"
              type="input"
              placeholder="Last Name"
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(e.target.value);
                setInfoMessage("");
                setErrorMessage("");
              }}
            />
            <Input
              name="companyName"
              type="input"
              placeholder="Company Name"
              value={companyName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyName(e.target.value);
                setInfoMessage("");
                setErrorMessage("");
              }}
            />
            <Input
              name="companyWebsite"
              type="input"
              placeholder="Company Website"
              value={companyWebsite}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyWebsite(e.target.value);
                setInfoMessage("");
                setErrorMessage("");
              }}
            />
            <Input
              name="phoneNumber"
              type="input"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhoneNumber(e.target.value);
                setInfoMessage("");
                setErrorMessage("");
              }}
            />
            <Input
              name="referralCode"
              type="input"
              placeholder="Referral"
              value={referralCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setReferralCode(e.target.value);
                setInfoMessage("");
                setErrorMessage("");
              }}
            />
            <Button type="submit">Register</Button>
            <ToggleButton
              type="button"
              onClick={() => {
                setIsLogin(true);
                setInfoMessage("");
                setErrorMessage("");
              }}
            >
              <br />
              <br />
              Back to Login
            </ToggleButton>
          </>
        )}
        {errorMessage && (
          <p style={{ color: "red", textAlign: "center" }}>
            <br />
            <br />
            <br />
            {errorMessage}
          </p>
        )}
        {infoMessage && (
          <p style={{ color: "green", textAlign: "center" }}>
            <br />
            <br />
            <br />
            {infoMessage}
          </p>
        )}
      </Form>
      <ModalTermsAndConditions
        isOpen={isOpen_ModalTermsAndConditions}
        onAccept={handleAcceptTermsAndConditions}
        onDecline={handleDeclineTermsAndConditions}
      />
    </Container>
  );
};

export default Login;
