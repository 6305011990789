/**
 * @since 2023-07-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import PanelReportViewer from "components/Shared/PanelReportViewer";
import SectionScoresError from "components/Shared/SectionScoresError";
import TextCopyright from "components/Shared/TextCopyright";
import TextWait from "components/Shared/TextWait";
import {
  createPortfolioAnalysis,
  getStatusPortfolioAnalysis,
  loadPortfolioReports,
  waitForPortfolioAnalysis,
} from "dataHandling/portfolio";
import { useEffect, useState } from "react";

const PanelReports = ({ email, portfolioId }) => {
  //region States

  const [status, setStatus] = useState("");
  const [reportsData, setReportsData] = useState({});

  //endregion

  //region Use Effects

  useEffect(() => {
    fetchData();
  }, [portfolioId, email]);

  //endregion

  //region Methods

  const fetchData = async () => {
    if (!portfolioId) return;
    let newStatus = await getStatusPortfolioAnalysis(email, portfolioId);
    if (newStatus === "WAITING") {
      setStatus(newStatus);
      newStatus = await waitForPortfolioAnalysis(email, portfolioId);
    }
    if (newStatus === "OK") {
      const newReportsData = await loadPortfolioReports(email, portfolioId);
      setReportsData(newReportsData);
    }
    setStatus(newStatus);
  };

  const startScoresAnalysis = async () => {
    setStatus("WAITING");
    await createPortfolioAnalysis(email, portfolioId);
    fetchData();
  };

  //endregion

  return (
    <>
      {status === "NEW" && (
        <ButtonBlue
          label="CREATE SCORES"
          alignment="left"
          onClick={startScoresAnalysis}
        />
      )}
      {status === "WAITING" && <TextWait />}
      {status === "ERROR" && (
        <SectionScoresError onClick={startScoresAnalysis} />
      )}
      {status === "OK" && (
        <>
          <PanelReportViewer
            reportsData={reportsData}
            showRecommendations={true}
          />
          <br />
          <br />
          <TextCopyright />
        </>
      )}
      ;
    </>
  );
};
export default PanelReports;
