/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toPercentage } from "utils/formatting";
import { validatePercentage } from "utils/validation";
import "./TableRiskManagement.scss";

const TableRiskManagement = ({
  settings,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  const intl = useIntl();

  const getDefaultSettings = () => {
    return {
      "Instrument Notional": {
        type: "PercentageInput",
        displayName: "Notional",
        fieldName: "Instrument_Notional",
        value: settings.RiskManagement.Instrument_Notional,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-notional",
        }),
      },
      "Instrument Volatility": {
        type: "PercentageInput",
        displayName: "Volatility",
        fieldName: "Instrument_Volatility",
        value: settings.RiskManagement.Instrument_Volatility,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-volatility",
        }),
      },
      "Instrument Stop Loss": {
        type: "PercentageInput",
        displayName: "Stop Loss",
        fieldName: "Instrument_StopLoss",
        value: settings.RiskManagement.Instrument_StopLoss,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-stoploss",
        }),
      },
      "Instrument Take Profit": {
        type: "PercentageInput",
        displayName: "Take Profit",
        fieldName: "Instrument_TakeProfit",
        value: settings.RiskManagement.Instrument_TakeProfit,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-takeprofit",
        }),
      },
      "Instrument Trailing Stop Loss": {
        type: "PercentageInput",
        displayName: "Trailing Stop Loss",
        fieldName: "Instrument_TrailingStopLoss",
        value: settings.RiskManagement.Instrument_TrailingStopLoss,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-trailingstoploss",
        }),
      },
      "Instrument Trailing Take Profit": {
        type: "PercentageInput",
        displayName: "Trailing Take Profit",
        fieldName: "Instrument_TrailingTakeProfit",
        value: settings.RiskManagement.Instrument_TrailingTakeProfit,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-trailingtakeprofit",
        }),
      },
      "Instrument Trade One Signal Per Day": {
        type: "CheckBox",
        displayName: "One Signal Per Day",
        fieldName: "Instrument_OneSignalPerDay",
        value: settings.RiskManagement.Instrument_OneSignalPerDay
          ? "Yes"
          : "No",
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-onesignalperday",
        }),
      },
      "Sector Notional (Gross)": {
        type: "PercentageInput",
        displayName: "Notional (Gross)",
        fieldName: "Sector_Notional_Gross",
        value: settings.RiskManagement.Sector_Notional_Gross,
        tooltip: intl.formatMessage({
          id: "riskmanagement-sector-notionalgross",
        }),
      },
      "Sector Notional (Net)": {
        type: "PercentageInput",
        displayName: "Notional (Net)",
        fieldName: "Sector_Notional_Net",
        value: settings.RiskManagement.Sector_Notional_Net,
        tooltip: intl.formatMessage({
          id: "riskmanagement-sector-notionalnet",
        }),
      },
      "Sector Volatility": {
        type: "PercentageInput",
        displayName: "Volatility",
        fieldName: "Sector_Volatility",
        value: settings.RiskManagement.Sector_Volatility,
        tooltip: intl.formatMessage({ id: "riskmanagement-sector-volatility" }),
      },
      "Portfolio Notional (Gross)": {
        type: "PercentageInput",
        displayName: "Notional (Gross)",
        fieldName: "Portfolio_Notional_Gross",
        value: settings.RiskManagement.Portfolio_Notional_Gross,
        tooltip: intl.formatMessage({
          id: "riskmanagement-portfolio-notionalgross",
        }),
      },
      "Portfolio Notional (Net)": {
        type: "PercentageInput",
        displayName: "Notional (Net)",
        fieldName: "Portfolio_Notional_Net",
        value: settings.RiskManagement.Portfolio_Notional_Net,
        tooltip: intl.formatMessage({
          id: "riskmanagement-portfolio-notionalnet",
        }),
      },
      "Portfolio Volatility": {
        type: "PercentageInput",
        displayName: "Volatility",
        fieldName: "Portfolio_Volatility",
        value: settings.RiskManagement.Portfolio_Volatility,
        tooltip: intl.formatMessage({
          id: "riskmanagement-portfolio-volatility",
        }),
      },
    };
  };

  const [riskManagementSettings, setRiskManagementSettings] = useState(
    getDefaultSettings()
  );

  const [listValidationErrors, setListValidationErrors] = useState([]);

  const listPortfolioSettings = [
    "Portfolio Notional (Gross)",
    "Portfolio Notional (Net)",
    "Portfolio Volatility",
  ];
  const listSectorSettings = [
    "Sector Notional (Gross)",
    "Sector Notional (Net)",
    "Sector Volatility",
  ];

  const [listInstrumentSettings, setListInstrumentSettings] = useState([]);

  useEffect(() => {
    const instrumentSettings = [
      "Instrument Notional",
      "Instrument Volatility",
      "Instrument Stop Loss",
      "Instrument Take Profit",
      "Instrument Trailing Stop Loss",
      "Instrument Trailing Take Profit",
    ];
    if (!settings.Settings.Frequency.includes("Minute")) {
      instrumentSettings.push("Instrument Trade One Signal Per Day");
    }
    setListInstrumentSettings(instrumentSettings);
  }, [settings.Settings.Frequency]);

  useEffect(() => {
    // Validate
    const listErrors = [];
    for (const name in riskManagementSettings) {
      if (riskManagementSettings[name].type === "PercentageInput") {
        const { isValid, errorMessage } = validatePercentage(
          riskManagementSettings[name].value,
          true
        );
        if (!isValid) listErrors.push(`${name} ${errorMessage}`);
      }
    }
    setListValidationErrors(listErrors);
  }, [riskManagementSettings]);

  const cancel = () => {
    setRiskManagementSettings(getDefaultSettings());
    onIsEditUpdate(false);
  };

  const save = () => {
    // Update global settings
    const newRiskManagement = { ...settings.RiskManagement };
    for (const name in riskManagementSettings) {
      const fieldName = riskManagementSettings[name]["fieldName"];
      const fieldType = riskManagementSettings[name]["type"];
      let fieldValue = String(riskManagementSettings[name]["value"]);
      if (fieldType === "CheckBox") {
        // Convert to boolean
        fieldValue = fieldValue === "Yes" ? true : false;
      }
      newRiskManagement[fieldName] = fieldValue;
    }
    // Save
    onSettingsChange(newRiskManagement);
    onIsEditUpdate(false);
  };

  const handleInputChange = (settingName, riskManagementNewValue) => {
    setRiskManagementSettings({
      ...riskManagementSettings,
      [settingName]: {
        ...riskManagementSettings[settingName],
        value: riskManagementNewValue,
      },
    });
  };

  const renderErrors = () => {
    return (
      <div className="errors-container">
        {listValidationErrors.map((error) => (
          <div className="error">{error}</div>
        ))}
      </div>
    );
  };

  const renderSettings = (listSettings) => {
    return listSettings.map((name) => (
      <div className="riskmanagement-item" key={`${name}-Outer`}>
        <div className="riskmanagement-row" key={`${name}-Main`}>
          <div
            className={`riskmanagement-name ${isEdit ? "" : "not-editing"}`}
            key={`${name}.Name`}
          >
            <Tooltip
              tooltipText={riskManagementSettings[name].tooltip}
              size="small"
            >
              {riskManagementSettings[name].displayName}
            </Tooltip>
          </div>
          <div className="riskmanagement-value" key={`${name}-Value`}>
            {isEdit
              ? renderInputField(name, riskManagementSettings[name])
              : toPercentage(riskManagementSettings[name].value)}
          </div>
        </div>
      </div>
    ));
  };

  const renderInputField = (name, item) => {
    switch (item.type) {
      case "PercentageInput":
        return (
          <InputBox
            name={name}
            value={riskManagementSettings[name].value}
            maxLength={10}
            onUpdate={handleInputChange}
          />
        );
      case "CheckBox":
        return (
          <MultipleChoiceBox
            name={name}
            choices={["Yes", "No"]}
            value={item.value}
            onUpdate={handleInputChange}
          />
        );
      default:
        console.log(`Error: ${name} has unknown type ${item.type}`);
    }
  };

  return (
    <div className="risk-management-strategy">
      <div className="main-container">
        <div className="section first">
          <div className="section-header">INSTRUMENT</div>
          {renderSettings(listInstrumentSettings)}
        </div>
        <div className="section">
          <div className="section-header">SECTOR</div>
          {renderSettings(listSectorSettings)}
        </div>
        <div className="section">
          <div className="section-header">PORTFOLIO</div>
          {renderSettings(listPortfolioSettings)}
        </div>
      </div>
      {isEdit && renderErrors()}
      {isEdit && (
        <BarSaveCancel
          onCancel={cancel}
          onSave={save}
          isSaveDisabled={listValidationErrors.length > 0}
        />
      )}
    </div>
  );
};

export default TableRiskManagement;
