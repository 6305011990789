/**
 * @since 2024-05-03
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Modal } from "antd";
import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import { useEffect, useState } from 'react';
import { validateName } from "utils/validation";
import "./ModalPasswordChange.scss";

const ModalPasswordChange = ({ isOpen, onCancel, onPasswordChange }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [listValidationErrors, setListValidationErrors] = useState([]);


  useEffect(() => {
    validateFields(newPassword, confirmPassword);
  }, [newPassword, confirmPassword]);


  const savePassword = () => {
    setNewPassword("");
    setConfirmPassword("");
    if (newPassword === confirmPassword) {
      onPasswordChange(newPassword);
    } else {
      alert('Passwords do not match!');
    }
  }

  const handleCancel = () => {
    setNewPassword("");
    setConfirmPassword("");
    onCancel();
  }

  const handleConfirmPasswordChange = (settingName, settingNewValue) => {
    setConfirmPassword(settingNewValue);
  };

  const handleNewPasswordChange = (settingName, settingNewValue) => {
    setNewPassword(settingNewValue);
  };

  const showErrors = () => {
    return (<div className="errors-container">
    {listValidationErrors.map((error) => (
      <div className="error">{error}</div>
    ))}
    </div>);
  };



  const validateFields = (newPassword, confirmPassword) => {
    const listErrors = [];
    // Minimum length
    newPassword = String(newPassword).trim();
    confirmPassword = String(confirmPassword).trim();
    if (newPassword.length === 0) {
        // skip checks
        setListValidationErrors(listErrors);
        return;
    }
    const { isValid, errorMessage } = validateName(newPassword, 6, 30);
    if (!isValid) listErrors.push(`New password: ${errorMessage}`);
    // Different passwords
    if (newPassword !== confirmPassword) {
      listErrors.push(`The passwords do not match!`);
    }
    setListValidationErrors(listErrors)
  }
  
  return (
    <Modal open={isOpen} title={"CHANGE PASSWORD"} onCancel={handleCancel} footer={null} className="modal-password-change">
      <div
        style={{
          marginTop: "40px",     
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          color: "rgba(255, 255, 255, 0.5)",
        }}>
          <div className="setting-item" key={`NewPassword.Outer`}>
            <div className="setting-row" key={`NewPassword.Main`}>
              <div className="setting-name" key={`NewPassword.Name`}>
                  New Password
              </div>
              <div className="setting-value" key={`NewPassword-Value`}>
                <InputBox name="NewPassword" value={newPassword} maxLength={30} onUpdate={handleNewPasswordChange} />
              </div>
            </div>
          </div>
          <div className="setting-item" key={`ConfirmPassword.Outer`}>
            <div className="setting-row" key={`ConfirmPassword.Main`}>
              <div className="setting-name" key={`ConfirmPassword.Name`}>
                  Confirm Password
              </div>
              <div className="setting-value" key={`ConfirmPassword-Value`}>
                <InputBox name="ConfirmPassword" value={confirmPassword} maxLength={30} onUpdate={handleConfirmPasswordChange} />
              </div>
            </div>
          </div>
      </div>
      {showErrors()}
      <BarSaveCancel onSave={savePassword} onCancel={handleCancel} isSaveDisabled={newPassword.length === 0 || listValidationErrors.length > 0} />
    </Modal>
  );
};

export default ModalPasswordChange;
