/**
 * @since 2023-06-06
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { useRef, useState } from "react";
import "./Tooltip.scss";

const Tooltip = ({
  children,
  tooltipText,
  size = "normal",
  isMenu = false,
}) => {
  const [hovering, setHovering] = useState(false);
  const [position, setPosition] = useState({ x: null, y: null });
  const tooltipRef = useRef(null);
  const tooltipClass = `tooltip-text${
    size === "small" ? " tooltip-text-small" : ""
  } ${hovering ? "visible" : ""}`;

  const handleMouseMove_Main = (e) => {
    let x = e.clientX;
    let y = e.clientY;

    // get tooltip dimensions
    const tooltipWidth = tooltipRef.current?.offsetWidth || 0;
    const tooltipHeight = tooltipRef.current?.offsetHeight || 0;

    // adjust x position if tooltip is going off the right edge of the screen
    if (window.innerWidth - e.clientX < tooltipWidth) {
      x = e.clientX - tooltipWidth;
    }

    // adjust y position if tooltip is going off the bottom edge of the screen
    if (window.innerHeight - e.clientY < tooltipHeight) {
      y = e.clientY - tooltipHeight;
    }

    setPosition({ x, y });
  };

  const handleMouseMove_Menu = (e) => {
    let x = e.clientX + window.scrollX;
    let y = e.clientY + window.scrollY;

    // get tooltip dimensions
    const tooltipWidth = tooltipRef.current?.offsetWidth || 0;
    const tooltipHeight = tooltipRef.current?.offsetHeight || 0;

    // adjust x position if tooltip is going off the right edge of the screen
    if (window.innerWidth - e.clientX < tooltipWidth) {
      x = e.clientX - tooltipWidth + window.scrollX;
    }

    // adjust y position if tooltip is going off the bottom edge of the screen
    if (window.innerHeight - e.clientY < tooltipHeight) {
      y = e.clientY - tooltipHeight + window.scrollY;
    }

    setPosition({ x, y });
  };

  const handleMouseMove = isMenu ? handleMouseMove_Menu : handleMouseMove_Main;

  const styles = {
    left: `${position.x}px`,
    top: `${position.y}px`,
  };

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onMouseMove={handleMouseMove}
      className="tooltip-container"
    >
      {children}
      {hovering && (
        <div
          className={tooltipClass}
          style={styles}
          dangerouslySetInnerHTML={{ __html: tooltipText }}
          ref={tooltipRef}
        ></div>
      )}
    </div>
  );
};

export default Tooltip;
