/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Menu, Modal } from "antd";
import MenuItem from "components/NavBar/MenuItem";
import ModalSubscription from "components/Shared/ModalSubscription/ModalSubscription";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlusCircle, BsStars } from "react-icons/bs";
import { FiCopy, FiLogOut, FiMenu, FiPieChart, FiRss } from "react-icons/fi";
import { MdOutlineDiamond } from "react-icons/md";
import { PiLockKeyFill, PiUploadSimpleBold } from "react-icons/pi";
import {
  RiLineChartFill,
  RiMailSendLine,
  RiSettings2Line,
} from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ModalFeedback from "./ModalFeedback/ModalFeedback";
import ModalSettings from "./ModalSettings/ModalSettings";
import "./NavBar.scss";

const NavBar = ({
  email,
  menuItems,
  selectedMenuItem,
  onItemSelected,
  onDuplicateImported,
  onDuplicatePortfolio,
  onDuplicateSignal,
  onDuplicateStrategy,
  onDeleteImported,
  onDeletePortfolio,
  onDeleteSignal,
  onDeleteStrategy,
  onNewImported,
  onNewPortfolio,
  onNewSignal,
  onNewStrategy,
  isNavBarVisible,
  onNavBarToggled,
  onSettingsChanged,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [expandedKeys, setExpandedKeys] = useState(
    new Set([
      "Title|Strategy",
      "Title|Premium",
      "Title|Imported",
      "Title|Portfolio",
      "Title|Signals",
    ])
  );
  const [isOpen_ModalFeedback, setIsOpen_ModalFeedback] = useState(false);
  const [isOpen_ModalSubscription, setIsOpen_ModalSubscription] =
    useState(false);
  const [isOpen_ModalSettings, setIsOpen_ModalSettings] = useState(false);
  const [selectedKey, setSelectedKey] = useState(selectedMenuItem.getKey());
  const maxItemsToCollapseOtherSections = 12;
  const [isCollapseOtherSections, setIsCollapseOtherSections] = useState(false);

  const labelImported =
    "Import returns of external strategies from an excel, csv, or pickle file.";
  const labelPortfolio = "Combine several strategies into a portfolio.";
  const labelSignals =
    "Run your strategies live and receive real-time notifications.";
  const labelStrategies = "Create your own customized strategies.";
  const labelPremium =
    "Strategies developed by Central Limit Technologies.<br/>Can be traded by subscribing to the Trader or Porfolio Manager version.";

  useEffect(() => {
    //localStorage.setItem("menuItems", JSON.stringify(menuItems));
    let nItems = 0;
    Object.keys(menuItems).forEach((key) => {
      if (!["User", "Locked"].includes(key)) {
        nItems += Object.keys(menuItems[key]).length;
      }
    });
    setIsCollapseOtherSections(nItems >= maxItemsToCollapseOtherSections);
  }, [menuItems]);

  useEffect(() => {
    setSelectedKey(selectedMenuItem.getKey());
  }, [selectedMenuItem]);

  useEffect(() => {}, [expandedKeys]);

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("token");
    navigate("/login");
  };

  const handleClick = (e) => {
    const [type, key, name] = e.key.split("|");
    switch (type) {
      case "Logout":
        logout();
        break;
      case "Feedback":
        setIsOpen_ModalFeedback(true);
        break;
      case "Subscription":
        setIsOpen_ModalSubscription(true);
        break;
      case "Settings":
        setIsOpen_ModalSettings(true);
        break;
      default:
        // Selected Strategy, Portfolio, Signals, etc.
        onItemSelected(type, key, name);
    }
  };

  const handleFeedbackCancel = () => {
    setIsOpen_ModalFeedback(false);
  };

  const handleFeedbackSent = () => {
    setIsOpen_ModalFeedback(false);
    Modal.info({
      title: "Feedback sent",
      content: "Thank you! Your feedback has been sent successfully.",
      okText: "OK",
      className: "deletion-modal",
      icon: null,
    });
  };

  const handleSettingsCancel = () => {
    setIsOpen_ModalSettings(false);
  };

  const handleSettingsChanged = () => {
    onSettingsChanged();
    setIsOpen_ModalSettings(false);
  };

  const handleSubscriptionRequestCancel = () => {
    setIsOpen_ModalSubscription(false);
  };

  const handleImportedDeletion = () => {
    Modal.confirm({
      className: "deletion-modal",
      icon: null,
      title: `Are you sure you want to delete "${selectedMenuItem.ItemName}"?`,
      content: "",
      onOk() {
        onDeleteImported(selectedMenuItem.ItemId);
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  const handleImportedDuplication = () => {
    onDuplicateImported(selectedMenuItem.ItemId);
  };

  const handlePortfolioDeletion = () => {
    Modal.confirm({
      className: "deletion-modal",
      icon: null,
      title: `Are you sure you want to delete "${selectedMenuItem.ItemName}"?`,
      content: "",
      onOk() {
        onDeletePortfolio(selectedMenuItem.ItemId);
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  const handlePortfolioDuplication = () => {
    onDuplicatePortfolio(selectedMenuItem.ItemId);
  };

  const handleSignalDeletion = () => {
    Modal.confirm({
      className: "deletion-modal",
      icon: null,
      title: `Are you sure you want to delete "${selectedMenuItem.ItemName}"?`,
      content: "",
      onOk() {
        onDeleteSignal(selectedMenuItem.ItemId);
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  const handleSignalDuplication = () => {
    onDuplicateSignal(selectedMenuItem.ItemId);
  };

  const handleStrategyDeletion = () => {
    Modal.confirm({
      className: "deletion-modal",
      icon: null,
      title: `Are you sure you want to delete "${selectedMenuItem.ItemName}"?`,
      content: "",
      onOk() {
        onDeleteStrategy(selectedMenuItem.ItemId);
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  const handleStrategyDuplication = () => {
    onDuplicateStrategy(selectedMenuItem.ItemId);
  };

  const handleSubMenuToggle = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => !expandedKeys.has(key));
    const latestCloseKey = Array.from(expandedKeys).find(
      (key) => !openKeys.includes(key)
    );

    // Update only selected
    if (isCollapseOtherSections && latestOpenKey) {
      setExpandedKeys(new Set([latestOpenKey]));
      return;
    }

    // Update all
    const updatedExpandedKeys = new Set(expandedKeys);
    if (latestOpenKey) {
      updatedExpandedKeys.add(latestOpenKey);
    }
    if (latestCloseKey) {
      updatedExpandedKeys.delete(latestCloseKey);
    }
    setExpandedKeys(updatedExpandedKeys);
  };

  const switchMenuItemUrl = (newUrl) => {
    if (location.pathname !== newUrl) {
      navigate(newUrl, { relative: true });
    }
  };

  const toggleNavBar = () => {
    onNavBarToggled(!isNavBarVisible);
  };

  const ComponentMenuCategory = ({
    label,
    count,
    icon,
    labelHooverTitle,
    labelHooverAdd,
    onIconClick,
    expanded,
  }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          lineHeight: "18px",
        }}
      >
        <Tooltip tooltipText={labelHooverTitle} size="small" isMenu={true}>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            {icon}
            <span style={{ marginLeft: 8, lineHeight: "18px" }}>
              {count ? label + " (" + count + ")" : label}
            </span>
          </div>
        </Tooltip>
        {expanded ? (
          <Tooltip tooltipText={labelHooverAdd} size="small" isMenu={true}>
            <span
              onClick={(e) => {
                e.stopPropagation();
                onIconClick();
              }}
              style={{ cursor: "pointer" }}
            >
              <BsPlusCircle
                style={{
                  fontSize: 16,
                  color: "#78BCFB",
                  marginTop: "3px",
                  marginRight: "18px",
                }}
              />
            </span>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const ComponentMenuItem = ({ label, icon }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {icon}
      <span style={{ marginLeft: 8 }}>{label}</span>
    </div>
  );

  //region Methods - Show Menu Items

  const showMenu_Premium = () => {
    if (!menuItems.Premium) return null;
    return (
      <Menu.SubMenu
        key="Title|Premium"
        title={
          <ComponentMenuCategory
            label="PREMIUM"
            count={Object.keys(menuItems.Premium).length}
            icon={<MdOutlineDiamond style={{ fontSize: 18 }} />}
            labelHooverTitle={labelPremium}
            expanded={false}
          />
        }
      >
        {Object.keys(menuItems.Premium)
          .sort()
          .map((premiumName) => (
            <Menu.Item
              test-id={`premium-menu-item-${menuItems.Premium[premiumName]}`}
              key={new MenuItem(
                "PREMIUM",
                menuItems.Premium[premiumName],
                premiumName
              ).getKey()}
              onClick={(event) =>
                switchMenuItemUrl(`premium/${menuItems.Premium[premiumName]}`)
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {premiumName}
                {selectedKey ===
                  new MenuItem(
                    "PREMIUM",
                    menuItems.Premium[premiumName],
                    premiumName
                  ).getKey() && (
                  <span
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "6px",
                    }}
                  ></span>
                )}
              </div>
            </Menu.Item>
          ))}
        <Menu.Item key="Separator|Premium" className="my-div-menu" />
      </Menu.SubMenu>
    );
  };

  const showMenu_Imported = () => {
    if (!menuItems.Imported) return null;
    return (
      <Menu.SubMenu
        key="Title|Imported"
        title={
          <ComponentMenuCategory
            label="IMPORTED"
            count={Object.keys(menuItems.Imported).length}
            icon={<PiUploadSimpleBold style={{ fontSize: 18 }} />}
            labelHooverTitle={labelImported}
            labelHooverAdd="Import a new strategy"
            onIconClick={onNewImported}
            expanded={expandedKeys.has("Title|Imported")}
          />
        }
      >
        {Object.keys(menuItems.Imported)
          .sort()
          .map((importedName) => (
            <Menu.Item
              test-id={`imported-menu-item-${menuItems.Imported[importedName]}`}
              key={new MenuItem(
                "IMPORTED",
                menuItems.Imported[importedName],
                importedName
              ).getKey()}
              onClick={() =>
                switchMenuItemUrl(
                  `imported/${menuItems.Imported[importedName]}`
                )
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {importedName}
                {selectedKey ===
                  new MenuItem(
                    "IMPORTED",
                    menuItems.Imported[importedName],
                    importedName
                  ).getKey() && (
                  <span
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "6px",
                    }}
                  >
                    <Tooltip
                      tooltipText="Clone this imported strategy"
                      size="small"
                      isMenu={true}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImportedDuplication();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <FiCopy size={16} />
                      </span>
                    </Tooltip>
                    <Tooltip
                      tooltipText="Delete this imported strategy"
                      size="small"
                      isMenu={true}
                    >
                      <span
                        style={{ marginLeft: "6px", cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImportedDeletion();
                        }}
                      >
                        <AiOutlineDelete size={18} />
                      </span>
                    </Tooltip>
                  </span>
                )}
              </div>
            </Menu.Item>
          ))}
        <Menu.Item key="Separator|Imported" className="my-div-menu" />
      </Menu.SubMenu>
    );
  };

  const showMenu_Portfolios = () => {
    if (!menuItems.Portfolios) return null;
    return (
      <Menu.SubMenu
        key="Title|Portfolio"
        title={
          <ComponentMenuCategory
            label="PORTFOLIOS"
            count={Object.keys(menuItems.Portfolios).length}
            icon={<FiPieChart style={{ fontSize: 18 }} />}
            labelHooverTitle={labelPortfolio}
            labelHooverAdd="Create a new portfolio"
            onIconClick={onNewPortfolio}
            expanded={expandedKeys.has("Title|Portfolio")}
          />
        }
      >
        {Object.keys(menuItems.Portfolios)
          .sort()
          .map((portfolioName) => (
            <Menu.Item
              test-id={`portfolio-menu-item-${menuItems.Portfolios[portfolioName]}`}
              key={new MenuItem(
                "PORTFOLIO",
                menuItems.Portfolios[portfolioName],
                portfolioName
              ).getKey()}
              onClick={() =>
                switchMenuItemUrl(
                  `portfolio/${menuItems.Portfolios[portfolioName]}`
                )
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {showMenuEntry_WithLock("Portfolios", portfolioName)}
                {selectedKey ===
                  new MenuItem(
                    "PORTFOLIO",
                    menuItems.Portfolios[portfolioName],
                    portfolioName
                  ).getKey() && (
                  <span
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "6px",
                    }}
                  >
                    <Tooltip
                      tooltipText="Clone this portfolio"
                      size="small"
                      isMenu={true}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePortfolioDuplication();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <FiCopy size={16} />
                      </span>
                    </Tooltip>
                    {!menuItems.Locked.includes(
                      menuItems.Portfolios[portfolioName]
                    ) && (
                      <Tooltip
                        tooltipText="Delete this portfolio"
                        size="small"
                        isMenu={true}
                      >
                        <span
                          style={{ marginLeft: "6px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePortfolioDeletion();
                          }}
                        >
                          <AiOutlineDelete size={18} />
                        </span>
                      </Tooltip>
                    )}
                  </span>
                )}
              </div>
            </Menu.Item>
          ))}
        <Menu.Item key="Separator|Portfolios" className="my-div-menu" />
      </Menu.SubMenu>
    );
  };

  const showMenuEntry_WithLock = (menuType, entryName) => {
    const isLocked = menuItems["Locked"].includes(
      menuItems[menuType][entryName]
    );
    if (!isLocked) {
      return entryName;
    }
    return (
      <>
        <PiLockKeyFill
          size={16}
          style={{
            position: "absolute",
            left: "30px",
            top: "12px",
          }}
        />
        <span style={{ marginLeft: "2px" }}>{entryName}</span>
      </>
    );
  };

  const showMenu_Signals = () => {
    if (!menuItems.Signals) return null;
    return (
      <Menu.SubMenu
        key="Title|Signals"
        title={
          <ComponentMenuCategory
            label="SIGNALS"
            count={Object.keys(menuItems.Signals).length}
            icon={<FiRss style={{ fontSize: 18 }} />}
            labelHooverTitle={labelSignals}
            labelHooverAdd="Create a new signal"
            onIconClick={onNewSignal}
            expanded={expandedKeys.has("Title|Signals")}
          />
        }
      >
        {Object.keys(menuItems.Signals)
          .sort()
          .map((signalName) => (
            <Menu.Item
              test-id={`signal-menu-item-${menuItems.Signals[signalName]}`}
              key={new MenuItem(
                "SIGNAL",
                menuItems.Signals[signalName],
                signalName
              ).getKey()}
              onClick={() =>
                switchMenuItemUrl(`signal/${menuItems.Signals[signalName]}`)
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {showMenuEntry_WithLock("Signals", signalName)}
                {selectedKey ===
                  new MenuItem(
                    "SIGNAL",
                    menuItems.Signals[signalName],
                    signalName
                  ).getKey() && (
                  <span
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "6px",
                    }}
                  >
                    <Tooltip
                      tooltipText="Clone this signal"
                      size="small"
                      isMenu={true}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSignalDuplication();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <FiCopy size={16} />
                      </span>
                    </Tooltip>
                    <Tooltip
                      tooltipText="Delete this signal"
                      size="small"
                      isMenu={true}
                    >
                      <span
                        style={{ marginLeft: "6px", cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSignalDeletion();
                        }}
                      >
                        <AiOutlineDelete size={18} />
                      </span>
                    </Tooltip>
                  </span>
                )}
              </div>
            </Menu.Item>
          ))}
        <Menu.Item key="Separator|Signals" className="my-div-menu" />
      </Menu.SubMenu>
    );
  };

  const showMenu_Strategies = () => {
    if (!menuItems.Strategies) return null;
    return (
      <Menu.SubMenu
        key="Title|Strategy"
        title={
          <ComponentMenuCategory
            label="STRATEGIES"
            count={Object.keys(menuItems.Strategies).length}
            icon={<RiLineChartFill style={{ fontSize: 18 }} />}
            labelHooverTitle={labelStrategies}
            labelHooverAdd="Create a new strategy"
            onIconClick={onNewStrategy}
            expanded={expandedKeys.has("Title|Strategy")}
          />
        }
      >
        {Object.keys(menuItems.Strategies)
          .sort()
          .map((strategyName) => (
            <Menu.Item
              test-id={`strategy-menu-item-${menuItems.Strategies[strategyName]}`}
              key={new MenuItem(
                "STRATEGY",
                menuItems.Strategies[strategyName],
                strategyName
              ).getKey()}
              onClick={(event) =>
                switchMenuItemUrl(
                  `strategy/${menuItems.Strategies[strategyName]}`
                )
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {showMenuEntry_WithLock("Strategies", strategyName)}
                {selectedKey ===
                  new MenuItem(
                    "STRATEGY",
                    menuItems.Strategies[strategyName],
                    strategyName
                  ).getKey() && (
                  <span
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "6px",
                    }}
                  >
                    <Tooltip
                      tooltipText="Clone this strategy"
                      size="small"
                      isMenu={true}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleStrategyDuplication();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <FiCopy size={16} />
                      </span>
                    </Tooltip>
                    {!menuItems.Locked.includes(
                      menuItems.Strategies[strategyName]
                    ) && (
                      <Tooltip
                        tooltipText="Delete this strategy"
                        size="small"
                        isMenu={true}
                      >
                        <span
                          style={{ marginLeft: "6px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStrategyDeletion();
                          }}
                        >
                          <AiOutlineDelete size={18} />
                        </span>
                      </Tooltip>
                    )}
                  </span>
                )}
              </div>
            </Menu.Item>
          ))}
        <Menu.Item key="Separator|Strategies" className="my-div-menu" />
      </Menu.SubMenu>
    );
  };

  //endregion

  return (
    <div className="navbar-style">
      <div className="nav-toggle-icon" onClick={toggleNavBar}>
        <FiMenu size={22} />
      </div>
      {isNavBarVisible && (
        <div
          className={`nav-menu ${
            isNavBarVisible ? "nav-menu-open" : "nav-menu-closed"
          }`}
        >
          {menuItems?.User && (
            <div style={{ height: "100%" }}>
              <img
                src="https://storage.googleapis.com/www.centrallimit.ai/images/LogoWhiteNoText.svg"
                alt="Logo"
                className="image"
                style={{
                  width: "160px",
                  marginTop: "17px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <h1
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "16px",
                  marginBottom: "0px",
                  marginTop: "4px",
                  cursor: "default",
                  letterSpacing: "1px",
                  textAlign: "center",
                }}
              >
                CENTRAL LIMIT AI{" "}
                {menuItems["User"]["subscription"].toUpperCase()}
              </h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "11px",
                  marginBottom: "12px",
                  marginTop: "2px",
                  color: "rgba(255, 255, 255, 0.7)",
                  cursor: "default",
                }}
              >
                <h3 style={{ textAlign: "center" }}>v24.07.29</h3>
              </div>
              <div className="menu-wrapper">
                <Menu
                  theme={"dark"}
                  onClick={handleClick}
                  onOpenChange={handleSubMenuToggle}
                  className="menu"
                  style={{
                    width: 256,
                    backgroundColor: "rgb(25,26,29)",
                  }}
                  openKeys={Array.from(expandedKeys)}
                  selectedKeys={[selectedKey]}
                  mode="inline"
                >
                  <Menu.Item
                    key="Separator|Logo"
                    className="my-div-menu"
                  ></Menu.Item>
                  {showMenu_Strategies()}
                  {showMenu_Imported()}
                  {showMenu_Premium()}
                  {showMenu_Portfolios()}
                  {showMenu_Signals()}
                  {
                    /*/ Subscription */
                    menuItems["User"]["subscription"] == "Lite" && (
                      <Menu.Item key="Subscription">
                        <ComponentMenuItem
                          label="SUBSCRIPTION"
                          icon={<BsStars style={{ fontSize: 22 }} />}
                        />
                      </Menu.Item>
                    )
                  }
                  {/*/ Feedback */}
                  <Menu.Item key="Feedback">
                    <ComponentMenuItem
                      label="FEEDBACK"
                      icon={<RiMailSendLine style={{ fontSize: 18 }} />}
                    />
                  </Menu.Item>
                  {/*/ Logout */}
                  <Menu.Item key="Separator|Feedback" className="my-div-menu" />
                  {/*/ Settings */}
                  <Menu.Item key="Settings">
                    <ComponentMenuItem
                      label="SETTINGS"
                      icon={<RiSettings2Line style={{ fontSize: 18 }} />}
                    />
                  </Menu.Item>
                  <Menu.Item key="Logout" id="logout-button">
                    <ComponentMenuItem
                      label="LOGOUT"
                      icon={<FiLogOut style={{ fontSize: 18 }} />}
                    />
                  </Menu.Item>
                </Menu>
              </div>
            </div>
          )}
          <ModalSubscription
            isOpen={isOpen_ModalSubscription}
            email={email}
            onCancel={handleSubscriptionRequestCancel}
          />
          <ModalFeedback
            isOpen={isOpen_ModalFeedback}
            email={email}
            onCancel={handleFeedbackCancel}
            onSent={handleFeedbackSent}
          />
          <ModalSettings
            isOpen={isOpen_ModalSettings}
            email={email}
            menuItems={menuItems}
            onCancel={handleSettingsCancel}
            onSettingsChanged={handleSettingsChanged}
          />
        </div>
      )}
    </div>
  );
};

export default NavBar;
