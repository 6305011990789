/**
@since 2024-05-26
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright All rights reserved
*/

import * as echarts from "echarts";
import { useEffect, useRef } from "react";
import { toDollars, toPercentage } from "utils/formatting";

const ChartReturns = ({
  isLoading,
  chartData,
  height = 400,
  showXAxis = true,
  includeY0 = true,
  isFirstOnFront = false,
  formatValues = null,
  startFrom0 = false,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (isLoading) return;

    const chartInstance = echarts.getInstanceByDom(chartRef.current);
    if (chartInstance) {
      chartInstance.dispose();
    }
    const myChart = echarts.init(chartRef.current);
    const showMonth = hasMultipleEntriesPerYear(chartData.Dates);
    const allReturns = Object.values(chartData.Values).map(
      (item) => item.Returns
    );
    const flattenedReturns = [].concat(...allReturns);
    const minValue = includeY0
      ? Math.min(0, ...flattenedReturns)
      : Math.min(flattenedReturns);
    const maxValue = includeY0
      ? Math.max(0, ...flattenedReturns)
      : Math.max(flattenedReturns);
    const yAxisPadding = (maxValue - minValue) * 0.1;
    const minY = Math.floor(minValue - yAxisPadding);
    const maxY = Math.ceil(maxValue + yAxisPadding);

    function forwardFill(array) {
      let lastValidValue = null;
      return array.map((value) => {
        if (value !== "") {
          lastValidValue = value;
          return value;
        }
        return lastValidValue;
      });
    }

    function formatLabel(value) {
      if (formatValues === "$") {
        return toDollars(value);
      } else if (formatValues === "%") {
        return toPercentage(value);
      } else {
        return value;
      }
    }

    const seriesData = chartData.Values.map((item, index) => {
      const data = forwardFill(item.Returns);
      if (startFrom0) {
        data.unshift(0.0);
      }
      return {
        ...item,
        name: item.Name,
        type: "line",
        showSymbol: false,
        data: data,
        z: isFirstOnFront && index === 0 ? 10 : 0,
      };
    });

    const firstDateTime = chartData.Dates[0].replace(
      /\d{2}:\d{2}:\d{2}$/,
      "00:00:00"
    );
    const dates = startFrom0
      ? [firstDateTime, ...chartData.Dates]
      : chartData.Dates;

    // Chart
    const option = {
      grid: {
        left: "0%",
        right: "0%",
        top: "2%",
        bottom: "0%",
        containLabel: true,
      },
      legend: {
        type: "scroll",
        textStyle: {
          color: "white",
          fontSize: 14,
        },
        top: 0,
        left: 50,
        data: chartData.Values.map((item) => item.Name),
        formatter: (name) => {
          const series = seriesData.find((item) => item.name === name);
          const value = series?.data[series.data.length - 1];
          return `${name}: ${formatLabel(value)}`;
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: function (params) {
          let res = params[0].axisValueLabel + "<br/>";
          params.forEach((param) => {
            res +=
              param.marker +
              " " +
              param.seriesName +
              ": " +
              formatLabel(param.value) +
              "<br/>";
          });
          return res;
        },
        backgroundColor: "#191A1D",
        borderColor: "#78BCFB",
        borderWidth: 1,
        textStyle: {
          color: "rgba(255, 255, 255, 0.5)",
          fontSize: 12,
        },
      },
      xAxis: {
        type: "category",
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            opacity: 0.1,
          },
          minorSplitLine: {
            show: true,
            lineStyle: {
              opacity: 0.1,
            },
          },
        },
        data: dates,
        axisPointer: {
          show: true,
          type: "line",
          snap: true,
          label: {
            show: true,
            formatter: function (params) {
              return params.value;
            },
          },
        },
        axisLabel: {
          show: showXAxis,
          rotate: 45,
          formatter: function (value) {
            return datePrettier(value, showMonth, sameDay);
          },
        },
      },
      yAxis: {
        type: "value",
        min: minY,
        max: maxY,
        splitLine: {
          show: false,
        },
        axisLine: {
          show: true,
        },
      },
      series: seriesData,
    };

    myChart.setOption(option);

    function resizeChart() {
      const parentWidth = myChart?.getDom().parentNode.clientWidth * 0.98;
      const chartHeight = myChart?.getDom().offsetHeight * 0.98;
      myChart?.resize({ width: parentWidth, height: chartHeight });
    }

    const onWindowResize = () => {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (!chartInstance) return;

      chartInstance.resize();
      resizeChart();
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [chartData, isFirstOnFront, formatValues, startFrom0]);

  const hasMultipleEntriesPerYear = (dates) => {
    const yearCounts = dates.reduce((acc, date) => {
      const year = new Date(date).getFullYear();
      acc[year] = (acc[year] || 0) + 1;
      return acc;
    }, {});
    return Object.values(yearCounts).some((count) => count > 1);
  };

  const isAllSameDay = (dates) => {
    const firstDate = new Date(dates[0]);
    return dates.every((date) => {
      const currentDate = new Date(date);
      return (
        currentDate.getDate() === firstDate.getDate() &&
        currentDate.getMonth() === firstDate.getMonth() &&
        currentDate.getFullYear() === firstDate.getFullYear()
      );
    });
  };

  const sameDay = isAllSameDay(chartData.Dates);

  const datePrettier = (dateString, showMonth, sameDay) => {
    const date = new Date(dateString);
    if (sameDay) {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      return showMonth ? `${year}-${month}` : `${year}`;
    }
  };

  return (
    <>
      {!isLoading && (
        <div ref={chartRef} style={{ width: "100%", height: height }} />
      )}
    </>
  );
};

export default ChartReturns;
