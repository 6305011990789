/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import EditableSection from "components/Shared/EditableSection";
import { loadSignalSettings, saveSignalSettings } from "dataHandling/signal";
import { useEffect, useState } from "react";
import "./PanelSettings.scss";
import TableSettings from "./TableSettings";
import TableSettings_Api from "./TableSettings_Api";
import TableSettings_Email from "./TableSettings_Email";
import TableSettings_Slack from "./TableSettings_Slack";
import TableSettings_TextMessage from "./TableSettings_TextMessage";

const PanelSettings = ({
  email,
  signalId,
  signalName,
  listSignalNames,
  nActiveSignals,
  configuration,
  dictPortfolios,
  onSignalNameChange,
  onSettingsRefresh,
  onLiveClick,
}) => {
  const [signalSettings, setSignalSettings] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit_Enabled, setIsEdit_Enabled] = useState(true);
  const [isEdit_Signal, setIsEdit_Signal] = useState(false);
  const [isEdit_Api, setIsEdit_Api] = useState(false);
  const [isEdit_Email, setIsEdit_Email] = useState(false);
  const [isEdit_Slack, setIsEdit_Slack] = useState(false);
  const [isEdit_TextMessage, setIsEdit_TextMessage] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const newSignalSettings = await loadSignalSettings(email, signalId);
      setSignalSettings(newSignalSettings);
      setIsLoading(false);
    })();
  }, [signalName]);

  useEffect(() => {
    setIsEdit_Enabled(
      !(
        isEdit_Signal ||
        isEdit_Api ||
        isEdit_Email ||
        isEdit_Slack ||
        isEdit_TextMessage
      )
    );
  }, [
    isEdit_Signal,
    isEdit_Api,
    isEdit_Email,
    isEdit_Slack,
    isEdit_TextMessage,
  ]);

  const updateSettings = async (settings) => {
    const oldSignalName = signalName;
    const newSignalName = settings.SignalName;
    save(settings);
    // Reload the signal
    if (oldSignalName !== newSignalName) {
      onSignalNameChange(oldSignalName, newSignalName);
    } else {
      onSettingsRefresh();
    }
  };

  const updateSettings_Api = async (apiKey) => {
    const signalSettingsEdited = { ...signalSettings, ApiKey: apiKey };
    save(signalSettingsEdited);
  };

  const updateSettings_Email = async (
    listEmails,
    sendStartOfDayNotifications,
    sendTradesNotifications,
    sendEndOfDayNotifications,
    sendRiskManagementNotifications
  ) => {
    const signalSettingsEdited = {
      ...signalSettings,
      ListEmails: listEmails,
      SendEmailStartOfDay: sendStartOfDayNotifications,
      SendEmailTrades: sendTradesNotifications,
      SendEmailEndOfDay: sendEndOfDayNotifications,
      SendEmailRiskManagement: sendRiskManagementNotifications,
    };
    save(signalSettingsEdited);
  };

  const updateSettings_Slack = async (
    slackWebhookUrl,
    sendStartOfDayNotifications,
    sendTradesNotifications,
    sendEndOfDayNotifications,
    sendRiskManagementNotifications
  ) => {
    const signalSettingsEdited = {
      ...signalSettings,
      SlackWebhookUrl: slackWebhookUrl,
      SendSlackStartOfDay: sendStartOfDayNotifications,
      SendSlackTrades: sendTradesNotifications,
      SendSlackEndOfDay: sendEndOfDayNotifications,
      SendSlackRiskManagement: sendRiskManagementNotifications,
    };
    save(signalSettingsEdited);
  };

  const updateSettings_TextMessage = async (
    textMessageNumber,
    sendStartOfDayNotifications,
    sendTradesNotifications,
    sendEndOfDayNotifications,
    sendRiskManagementNotifications
  ) => {
    const signalSettingsEdited = {
      ...signalSettings,
      TextMessageNumber: textMessageNumber,
      SendTextStartOfDay: sendStartOfDayNotifications,
      SendTextTrades: sendTradesNotifications,
      SendTextEndOfDay: sendEndOfDayNotifications,
      SendTextRiskManagement: sendRiskManagementNotifications,
    };
    save(signalSettingsEdited);
  };

  const save = (signalSettingsEdited) => {
    setSignalSettings(signalSettingsEdited);
    saveSignalSettings(email, signalId, signalSettingsEdited);
  };

  return (
    <div className="panel-settings">
      {isLoading ? (
        <div className="loading-section">Loading...</div>
      ) : (
        <>
          <EditableSection
            title={"SETTINGS"}
            isOpened={true}
            canEdit={isEdit_Enabled}
            isEdit={isEdit_Signal}
            updateIsEdit={setIsEdit_Signal}
          >
            <TableSettings
              dictPortfolios={dictPortfolios}
              listSignalNames={listSignalNames}
              settings={signalSettings}
              nActiveSignals={nActiveSignals}
              onSettingsChange={updateSettings}
              isEdit={isEdit_Signal}
              onIsEditUpdate={setIsEdit_Signal}
            />
          </EditableSection>
          {signalSettings.UseEmail && (
            <EditableSection
              title={"EMAIL SETTINGS"}
              isOpened={true}
              canEdit={isEdit_Enabled}
              isEdit={isEdit_Email}
              updateIsEdit={setIsEdit_Email}
            >
              <TableSettings_Email
                email={email}
                signalId={signalId}
                listEmails={signalSettings.ListEmails}
                sendStartOfDayNotifications={signalSettings.SendEmailStartOfDay}
                sendTradesNotifications={signalSettings.SendEmailTrades}
                sendEndOfDayNotifications={signalSettings.SendEmailEndOfDay}
                sendRiskManagementNotifications={
                  signalSettings.SendEmailRiskManagement
                }
                onSettingsChange={updateSettings_Email}
                isEdit={isEdit_Email}
                onIsEditUpdate={setIsEdit_Email}
              />
            </EditableSection>
          )}
          {signalSettings.UseTextMessage && (
            <EditableSection
              title={"TEXT MESSAGE SETTINGS"}
              isOpened={true}
              canEdit={isEdit_Enabled}
              isEdit={isEdit_TextMessage}
              updateIsEdit={setIsEdit_TextMessage}
            >
              <TableSettings_TextMessage
                email={email}
                signalId={signalId}
                textMessageNumber={signalSettings.TextMessageNumber}
                sendStartOfDayNotifications={signalSettings.SendTextStartOfDay}
                sendTradesNotifications={signalSettings.SendTextTrades}
                sendEndOfDayNotifications={signalSettings.SendTextEndOfDay}
                sendRiskManagementNotifications={
                  signalSettings.SendTextRiskManagement
                }
                onSettingsChange={updateSettings_TextMessage}
                isEdit={isEdit_TextMessage}
                onIsEditUpdate={setIsEdit_TextMessage}
              />
            </EditableSection>
          )}
          {signalSettings.UseSlack && (
            <EditableSection
              title={"SLACK SETTINGS"}
              isOpened={true}
              canEdit={isEdit_Enabled}
              isEdit={isEdit_Slack}
              updateIsEdit={setIsEdit_Slack}
            >
              <TableSettings_Slack
                slackWebhookUrl={signalSettings.SlackWebhookUrl}
                sendStartOfDayNotifications={signalSettings.SendSlackStartOfDay}
                sendTradesNotifications={signalSettings.SendSlackTrades}
                sendEndOfDayNotifications={signalSettings.SendSlackEndOfDay}
                sendRiskManagementNotifications={
                  signalSettings.SendSlackRiskManagement
                }
                onSettingsChange={updateSettings_Slack}
                isEdit={isEdit_Slack}
                onIsEditUpdate={setIsEdit_Slack}
              />
            </EditableSection>
          )}
          {signalSettings.UseApi && (
            <EditableSection
              title={"API SETTINGS"}
              isOpened={true}
              canEdit={isEdit_Enabled}
              isEdit={isEdit_Api}
              updateIsEdit={setIsEdit_Api}
            >
              <TableSettings_Api
                signalId={signalId}
                apiKey={signalSettings.ApiKey}
                onSettingsChange={updateSettings_Api}
                isEdit={isEdit_Api}
                onIsEditUpdate={setIsEdit_Api}
              />
            </EditableSection>
          )}
        </>
      )}
      <div className="see-signals-live-stats">
        <button className="see-live-button" onClick={onLiveClick}>
          SEE LIVE STATS
        </button>
      </div>
    </div>
  );
};

export default PanelSettings;
