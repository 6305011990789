/*
@since: 2023-8-16
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { resetPassword } from "../../dataHandling/user";
import { Container, Form, Input, Title } from "../Login/Login.Style";
import "./ResetPassword.css";

type AlertType = "success" | "error";

export default function ResetPassword() {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<AlertType | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);
  const resetPass: React.FormEventHandler<HTMLFormElement> = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const response = await resetPassword(email);
    if (response.body.success) {
      setAlertType("success");
      setMessage(response.body?.message);
    } else {
      setMessage(response.body?.error);
      setAlertType("error");
    }
    setLoading(false);
  };
  return (
    <>
      <Container>
        <Form onSubmit={resetPass}>
          <Title>Reset Password</Title>
          <Input
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAlertType(null);
              setEmail(e.target.value.toLowerCase());
            }}
            ref={emailRef}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
          <Button htmlType="submit" loading={isLoading} className="reset-button">
            Reset
          </Button>
          <Link to="/login" className="link">
            Go back to Login
          </Link>
          {message && (
            <p style={{ color: alertType === "success" ? "green" : "red", textAlign: "center" }}>
              <br />
              <br />
              <br />
              {message}
            </p>
          )}
        </Form>
      </Container>
    </>
  );
}
