/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TableScoresWithFilters from "./TableScoresWithFilters";

export default TableScoresWithFilters;
