/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import PanelHeader from "components/Shared/PanelHeader";
import ToggleButtonGroupResearch from "components/Shared/ToggleButtonGroupResearch";
import { MdOutlineDiamond } from "react-icons/md";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import PanelAnalysis from "./PanelAnalysis";
import PanelReports from "./PanelReports";
import PanelScores from "./PanelScores";
import PanelSettings from "./PanelSettings";

const PremiumBuilder = ({
  email,
  menuItem,
  configuration,
  isNavBarVisible,
}) => {
  const navigate = useNavigate();

  const goToAnalysisView = () => {
    navigate("analysis", { relative: true });
  };

  const goToReportsView = () => {
    navigate("reports", { relative: true });
  };

  const goToScoresView = () => {
    navigate("scores", { relative: true });
  };

  return (
    <>
      {menuItem && (
        <>
          <PanelHeader
            menuItem={menuItem}
            icon={
              <MdOutlineDiamond
                style={{
                  fontSize: "30px",
                }}
              />
            }
            toggleButtonGroup={
              <ToggleButtonGroupResearch
                selectedValue={menuItem.ItemView}
                strategyType="premium strategy"
              />
            }
            isNavBarVisible={isNavBarVisible}
          />
          <Routes>
            <Route element={<Outlet />}>
              <Route
                path={"settings"}
                element={
                  <PanelSettings
                    email={email}
                    portfolioId={menuItem.ItemId}
                    configuration={configuration}
                    onAnalysisClick={goToAnalysisView}
                  />
                }
              />
              <Route
                path={"analysis"}
                element={
                  <PanelAnalysis
                    email={email}
                    portfolioId={menuItem.ItemId}
                    portfolioName={menuItem.ItemName}
                    onScoresClick={goToScoresView}
                  />
                }
              />
              <Route
                path={"reports"}
                element={
                  <PanelReports email={email} portfolioId={menuItem.ItemId} />
                }
              />
              <Route
                path={"scores"}
                element={
                  <PanelScores
                    email={email}
                    portfolioId={menuItem.ItemId}
                    onReportsClick={goToReportsView}
                  />
                }
              />
              <Route path="*" element={<Navigate to="settings" />} />
            </Route>
          </Routes>
        </>
      )}
    </>
  );
};
export default PremiumBuilder;
