/**
 * @since 2023-10-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ToggleButtonGroupSignals from "./ToggleButtonGroupSignals";

export default ToggleButtonGroupSignals;
