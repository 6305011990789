/**
 * @since 2023-10-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TextError from "./TextError";

export default TextError;
