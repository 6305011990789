/**
 * @since 2023-08-10
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import InputBox from "components/Shared/InputBox";
import React from "react";
import "./DateRangeSelector.scss";

const DateRangeSelector = ({
  startDate,
  endDate,
  onUpdateStartDate,
  onUpdateEndDate,
  isEditMode,
}) => {
  const showRange = () => {
    if (!startDate && !endDate) return "Whole history";
    if (!startDate) return `Until ${endDate}`;
    if (!endDate) return `From ${startDate}`;
    return `${startDate} to ${endDate}`;
  };

  return isEditMode ? (
    <div className="date-range-selector">
      <InputBox
        name="Start Date"
        value={startDate}
        onUpdate={(event, value) => onUpdateStartDate(value.trim())}
      />
      to
      <InputBox
        name="End Date"
        value={endDate}
        onUpdate={(event, value) => onUpdateEndDate(value.trim())}
      />
    </div>
  ) : (
    showRange()
  );
};

export default DateRangeSelector;
