/**
 * @since 2024-06-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ItemTablePortfolioWeights from "./ItemTablePortfolioWeights";

export default ItemTablePortfolioWeights;
