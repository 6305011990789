/**
 * @since 2023-05-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Modal } from "antd";
import BarSaveCancel from "components/Shared/BarSaveCancel";
import InteractiveIcon from "components/Shared/InteractiveIcon";
import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import PanelInstruments from "components/Shared/PanelInstruments";
import { deleteUniverseSettings } from "dataHandling/universe";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { BsPlusCircle } from "react-icons/bs";
import { FiCopy } from "react-icons/fi";
import ModalInstrumentSelector from "../ModalInstrumentSelector/ModalInstrumentSelector";
import "./ModalUniverseSelector.scss";

const ModalUniverseSelector = ({
  isOpen,
  email,
  strategyId,
  universeId,
  listUniverses,
  dictInstruments,
  onSave,
  onCancel,
  onRefreshUniverses,
}) => {
  const [newUniverseId, setNewUniverseId] = useState(universeId);
  const [universeToEdit, setUniverseToEdit] = useState(null);
  const [operationType, setOperationType] = useState("");
  const [universe, setUniverse] = useState(listUniverses[0]);
  const [listUniverseNames, setListUniverseNames] = useState(
    listUniverses.map((item) => item.UniverseName).sort()
  );
  const [listInstrumentsToShow, setListInstrumentsToShow] = useState(
    Object.values(dictInstruments)
  );
  const [isOpenInstrumentSelector, setIsOpenInstrumentSelector] =
    useState(false);

  useEffect(() => {
    refreshAll();
  }, [isOpen]);

  useEffect(() => {
    setListUniverseNames(listUniverses.map((item) => item.UniverseName).sort());
    refreshAll();
  }, [listUniverses]);

  useEffect(() => {
    setNewUniverseId(universeId);
  }, [universeId]);

  useEffect(() => {
    refreshAll();
  }, [newUniverseId]);

  const findUniverse = (universeId) => {
    if (universeId === undefined) {
      return listUniverses[0];
    }
    const newUniverse = listUniverses.find((u) => u.UniverseId === universeId);
    if (newUniverse !== undefined) return newUniverse;
    return listUniverses[0];
  };

  const refreshAll = async () => {
    const newUniverse = findUniverse(newUniverseId);
    setUniverse(newUniverse);
    const newListInstrumentsToShow = Object.values(dictInstruments).filter(
      (instrument) =>
        newUniverse.ListSecurityNames.includes(instrument.SecurityName)
    );
    setListInstrumentsToShow(newListInstrumentsToShow);
  };

  const handleUniverseChange = (name, value) => {
    let newUniverse = listUniverses.find((obj) => obj.UniverseName === value);
    setUniverse(newUniverse);
    const newListInstrumentsToShow = Object.values(dictInstruments).filter(
      (instrument) =>
        newUniverse.ListSecurityNames.includes(instrument.SecurityName)
    );
    setListInstrumentsToShow(newListInstrumentsToShow);
  };

  const handleDeleteUniverse = async () => {
    Modal.confirm({
      className: "deletion-modal",
      icon: null,
      title: `Are you sure you want to delete this collection?`,
      content: `You are about to delete "${universe.UniverseName}".`,
      onOk() {
        deleteUniverse();
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  const deleteUniverse = async () => {
    const output = await deleteUniverseSettings(
      email,
      strategyId,
      universe.UniverseId
    );
    if (output.status == "ERROR") {
      Modal.info({
        title: "Cannot delete universe",
        content:
          "The universe is currently being used by at least another strategy.",
        icon: "error",
        okText: "OK",
        className: "deletion-modal",
      });
    }
    await onRefreshUniverses();
    setUniverse(listUniverses[0]);
  };

  const handleDuplicateUniverse = () => {
    setOperationType("Duplicate");
    setUniverseToEdit({
      ...universe,
      UniverseId: "",
      UniverseName: universe.UniverseName + " Copy",
    });
    setIsOpenInstrumentSelector(true);
  };

  const handleEditUniverse = () => {
    setOperationType("Edit");
    setUniverseToEdit(universe);
    setIsOpenInstrumentSelector(true);
  };

  const handleNewUniverse = () => {
    setOperationType("New");
    setUniverseToEdit({
      ...universe,
      UniverseId: "",
      UniverseName: "New Instrument Collection",
      Description: "",
      IsCustom: true,
      ListIndexConstituents: [],
      ListSecurityNames: [],
    });
    setIsOpenInstrumentSelector(true);
  };

  const handleCloseInstrumentDialog_Cancel = () => {
    setIsOpenInstrumentSelector(false);
  };

  const handleCloseInstrumentDialog_Save = async (newUniverseId) => {
    setIsOpenInstrumentSelector(false);
    setNewUniverseId(newUniverseId);
    await onRefreshUniverses();
  };

  const handleSave = () => {
    onSave(universe.UniverseId);
  };

  return (
    <Modal
      open={isOpen}
      title={"UNIVERSE SELECTION"}
      footer={null}
      onCancel={onCancel}
      className="modal-universe-selector"
    >
      <div
        style={{
          marginTop: "40px",
          marginBottom: "40px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyItems: "left",
          justifyContent: "normal",
          gap: "14px",
        }}
      >
        <div style={{ width: "400px" }}>
          <MultipleChoiceBox
            name="Universe"
            choices={listUniverseNames}
            value={universe.UniverseName}
            onUpdate={handleUniverseChange}
          />
        </div>
        <InteractiveIcon
          Icon={BsPlusCircle}
          size={20}
          tooltip="Create a new collection"
          onClick={handleNewUniverse}
        />
        <InteractiveIcon
          Icon={FiCopy}
          size={20}
          tooltip="Duplicate this collection"
          onClick={handleDuplicateUniverse}
        />
        {universe.IsCustom && (
          <>
            <InteractiveIcon
              Icon={BiPencil}
              size={20}
              tooltip="Change the instruments inside this collection"
              onClick={handleEditUniverse}
            />
            <InteractiveIcon
              Icon={AiOutlineDelete}
              size={20}
              tooltip="Delete this collection"
              onClick={handleDeleteUniverse}
            />
          </>
        )}
      </div>
      <div style={{ height: "100%" }}>
        <PanelInstruments listInstruments={listInstrumentsToShow} />
      </div>
      <BarSaveCancel onCancel={onCancel} onSave={handleSave} />
      <ModalInstrumentSelector
        isOpen={isOpenInstrumentSelector}
        email={email}
        universe={universeToEdit}
        listUniverseNames={listUniverseNames}
        listAllInstruments={Object.values(dictInstruments)}
        operationType={operationType}
        onSave={handleCloseInstrumentDialog_Save}
        onCancel={handleCloseInstrumentDialog_Cancel}
      />
    </Modal>
  );
};

export default ModalUniverseSelector;
