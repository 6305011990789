/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import EditableSection from "components/Shared/EditableSection";
import TextCopyright from "components/Shared/TextCopyright";
import TextWait from "components/Shared/TextWait";
import {
  loadStrategySettings,
  saveStrategySettings,
} from "dataHandling/strategy";
import { loadAllUniverseSettings } from "dataHandling/universe";
import { useEffect, useState } from "react";
import PreviewChart from "./PreviewChart";
import TableRiskManagement from "./TableRiskManagement";
import TableRules from "./TableRules";
import TableSettings from "./TableSettings";
import TableSpreads from "./TableSpreads";

const PanelSettings = ({
  email,
  strategyId,
  isLocked,
  listStrategyFrequencies,
  listStrategyNames,
  configuration,
  onStrategyNameChange,
  onAnalysisClick,
}) => {
  const [strategySettings, setStrategySettings] = useState();
  const [universeSettings, setUniverseSettings] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit_Enabled, setIsEdit_Enabled] = useState(true);
  const [isEdit_Settings, setIsEdit_Settings] = useState(false);
  const [isEdit_Spreads, setIsEdit_Spreads] = useState(false);
  const [isEdit_EntryRules, setIsEdit_EntryRules] = useState(false);
  const [isEdit_ExitRules, setIsEdit_ExitRules] = useState(false);
  const [isEdit_RiskManagement, setIsEdit_RiskManagement] = useState(false);
  const listUniverseDefault = configuration.InstrumentUniverses;
  const [listUniverses, setListUniverses] = useState(listUniverseDefault);
  const [containsSpreads, setContainsSpreads] = useState(false);
  const [isIntradayOnly, setIsIntradayOnly] = useState(false);

  useEffect(() => {
    handleRefreshUniverses();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refreshStrategySettings();
      setIsLoading(false);
    })();
  }, [strategyId, email]);

  useEffect(() => {
    const isEnabled =
      !isLocked &&
      !(
        isEdit_Settings ||
        isEdit_Spreads ||
        isEdit_EntryRules ||
        isEdit_ExitRules ||
        isEdit_RiskManagement
      );
    setIsEdit_Enabled(isEnabled);
  }, [
    isLocked,
    isEdit_Settings,
    isEdit_Spreads,
    isEdit_EntryRules,
    isEdit_ExitRules,
    isEdit_RiskManagement,
  ]);

  useEffect(() => {
    setContainsSpreads(
      strategySettings ? strategySettings.Settings.ContainsSpreads : false
    );
    setIsIntradayOnly(strategySettings?.Settings.Frequency.includes("Minute"));
  }, [strategySettings, listUniverses]);

  const refreshStrategySettings = async () => {
    if (!strategyId) return;
    const newStrategySettings = await loadStrategySettings(email, strategyId);
    setStrategySettings(newStrategySettings);
    localStorage.setItem(
      "strategySettings",
      JSON.stringify(newStrategySettings)
    );
  };

  const updateRiskManagement = (riskManagement) => {
    const strategySettingsEdited = {
      ...strategySettings,
      RiskManagement: riskManagement,
    };
    save(strategySettingsEdited);
  };

  const handleRefreshUniverses = async () => {
    const newListCustomUniverses = await loadAllUniverseSettings(email);
    const newListUniverses = [
      ...listUniverseDefault,
      ...newListCustomUniverses,
    ];
    setListUniverses(newListUniverses);
  };

  const handleUpdateEntryRules = (entryRules) => {
    const strategySettingsEdited = {
      ...strategySettings,
      EntryRules: entryRules,
    };
    save(strategySettingsEdited);
  };

  const handleUpdateExitRules = (exitRules) => {
    const strategySettingsEdited = {
      ...strategySettings,
      ExitRules: exitRules,
    };
    save(strategySettingsEdited);
  };

  const handleUpdateSettings = async (settings) => {
    const oldStrategyName = strategySettings.Settings.StrategyName;
    const newStrategyName = settings.StrategyName;
    const newStrategySettings = { ...strategySettings, Settings: settings };
    save(newStrategySettings);
    // Reload the strategy
    if (oldStrategyName !== newStrategyName) {
      onStrategyNameChange(oldStrategyName, newStrategyName);
    }
  };

  const handleUpdateSpreads = (listSpreads) => {
    const strategySettingsEdited = {
      ...strategySettings,
      Spreads: listSpreads,
    };
    save(strategySettingsEdited);
  };

  const save = async (strategySettingsEdited) => {
    await saveStrategySettings(email, strategyId, strategySettingsEdited);
    await refreshStrategySettings();
  };

  return (
    <>
      {isLoading ? (
        <TextWait />
      ) : (
        <>
          <EditableSection
            title={"SETTINGS"}
            isOpened={true}
            canEdit={isEdit_Enabled}
            isEdit={isEdit_Settings}
            updateIsEdit={setIsEdit_Settings}
          >
            <TableSettings
              email={email}
              settings={strategySettings}
              listStrategyNames={listStrategyNames}
              configuration={configuration}
              onSettingsChange={handleUpdateSettings}
              isEdit={isEdit_Settings}
              onIsEditUpdate={setIsEdit_Settings}
              listStrategyFrequencies={listStrategyFrequencies}
              listUniverses={listUniverses}
              onRefreshUniverses={handleRefreshUniverses}
            />
          </EditableSection>
          {containsSpreads && (
            <EditableSection
              title={"SPREADS"}
              isOpened={true}
              canEdit={isEdit_Enabled}
              isEdit={isEdit_Spreads}
              updateIsEdit={setIsEdit_Spreads}
            >
              <TableSpreads
                email={email}
                strategySettings={strategySettings}
                universeSettings={universeSettings}
                dictInstruments={configuration.Instruments}
                onSpreadsChange={handleUpdateSpreads}
                isEdit={isEdit_Spreads}
                onIsEditUpdate={setIsEdit_Spreads}
              />
            </EditableSection>
          )}
          <EditableSection
            title={"ENTRY RULES"}
            isOpened={true}
            canEdit={isEdit_Enabled}
            isEdit={isEdit_EntryRules}
            updateIsEdit={setIsEdit_EntryRules}
          >
            <div>
              <TableRules
                configuration={configuration}
                isEntry={true}
                isIntradayOnly={isIntradayOnly}
                rules={strategySettings.EntryRules}
                onRulesUpdate={handleUpdateEntryRules}
                isEdit={isEdit_EntryRules}
                onIsEditUpdate={setIsEdit_EntryRules}
              />
            </div>
          </EditableSection>
          <EditableSection
            title={"EXIT RULES"}
            isOpened={true}
            canEdit={isEdit_Enabled}
            isEdit={isEdit_ExitRules}
            updateIsEdit={setIsEdit_ExitRules}
          >
            <div>
              <TableRules
                configuration={configuration}
                isEntry={false}
                isIntradayOnly={isIntradayOnly}
                rules={strategySettings.ExitRules}
                onRulesUpdate={handleUpdateExitRules}
                isEdit={isEdit_ExitRules}
                onIsEditUpdate={setIsEdit_ExitRules}
              />
            </div>
          </EditableSection>
          <EditableSection
            title={"RISK MANAGEMENT"}
            isOpened={true}
            canEdit={isEdit_Enabled}
            isEdit={isEdit_RiskManagement}
            updateIsEdit={setIsEdit_RiskManagement}
          >
            <TableRiskManagement
              settings={strategySettings}
              onSettingsChange={updateRiskManagement}
              isEdit={isEdit_RiskManagement}
              onIsEditUpdate={setIsEdit_RiskManagement}
            />
          </EditableSection>
          <EditableSection
            title={"PREVIEW"}
            isOpened={true}
            canEdit={false}
            isEdit={false}
          >
            <div>
              <PreviewChart email={email} strategySettings={strategySettings} />
            </div>
          </EditableSection>
          <br />
          <br />
          <ButtonBlue label="SEE ANALYSIS" onClick={onAnalysisClick} />
          <TextCopyright />
        </>
      )}
    </>
  );
};
export default PanelSettings;
