/**
 * @since 2023-08-10
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ItemTableDataFrame from "./ItemTableDataFrame";

export default ItemTableDataFrame;
