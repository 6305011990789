/**
 * @since 2023-10-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "components/Shared/Tooltip";
import { Link } from "react-router-dom";
import "./ToggleButtonGroupSignals.scss";

const ToggleButtonGroupSignals = ({ selectedValue }) => {
  return (
    <ToggleButtonGroup
      className="toggleButtonGroupSignals"
      value={selectedValue.toUpperCase()}
      exclusive
      aria-label="view"
    >
      <Tooltip tooltipText={"Change the settings of this live signals"}>
        <ToggleButton
          value={"SETTINGS"}
          aria-label={"Settings"}
          test-id="view-settings-button"
        >
          <Link to={"settings"} className="unstyledLink">
            Settings
          </Link>
        </ToggleButton>
      </Tooltip>
      <Tooltip tooltipText={"View live trading stats for this live signals"}>
        <ToggleButton
          value={"LIVE"}
          aria-label={"Live"}
          test-id="view-live-button"
        >
          <Link to={"live"} className="unstyledLink">
            Live
          </Link>
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

export default ToggleButtonGroupSignals;
