/**
 * @since 2023-12-21
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import { useEffect, useState } from "react";
import ItemChartPerformance from "./ItemChartPerformance";
import "./ItemPerformanceStats.scss";
import ItemTablePerformance from "./ItemTablePerformance";

const ItemPerformanceStats = ({ itemData }) => {
  const [listCategories, setListCategories] = useState([]);
  const [listStrategies, setListStrategies] = useState(["Portfolio"]);
  const [strategyName, setStrategyName] = useState("Portfolio");
  const [category, setCategory] = useState("By Strategy");
  const [snapshot, setSnapshot] = useState(null);
  const [chartFilter, setChartFilter] = useState("All");
  const [listChartFilters, setListChartFilters] = useState(["All"]);
  const [chartType, setChartType] = useState("Profit & Loss ($)");

  const listChartTypes_Default = [
    "No Chart",
    "Profit & Loss ($)",
    "Profit & Loss (%)",
    "Gross Notional ($)",
    "Gross Notional (%)",
    "Net Notional ($)",
    "Net Notional (%)",
    "Volatility ($)",
    "Volatility (%)",
    "Value At Risk ($)",
    "Value At Risk (%)",
  ];
  const listChartTypes_ByInstrument = [
    ...listChartTypes_Default.slice(0, 1),
    "Position",
    "Price",
    ...listChartTypes_Default.slice(1),
  ];
  const [listChartTypes, setListChartTypes] = useState(listChartTypes_Default);

  useEffect(() => {
    if (itemData === null) return;
    const keys = Object.keys(itemData).sort();
    const newSnapshot = keys[keys.length - 1];
    setSnapshot(newSnapshot);
  }, [itemData]);

  useEffect(() => {
    if (!itemData || !snapshot) return;
    setListStrategies(Object.keys(itemData[snapshot]));
    if (!strategyName) return;
    setListCategories(Object.keys(itemData[snapshot][strategyName]));
    refreshFilter(strategyName, category);
  }, [snapshot]);

  useEffect(() => {
    if (!itemData || !snapshot || !category || !strategyName) return;
    setListCategories(Object.keys(itemData[snapshot][strategyName]));
    refreshFilter(strategyName, category);
  }, [strategyName]);

  useEffect(() => {
    if (!itemData || !snapshot || !strategyName || !category) return;
    setListChartTypes(
      category === "By Instrument"
        ? listChartTypes_ByInstrument
        : listChartTypes_Default
    );
    refreshFilter(strategyName, category);
  }, [category]);

  const handleCategoryChange = async (name, newCategory) => {
    if (!newCategory || category === newCategory) return;
    if (
      ["Position", "Price"].includes(chartType) &&
      newCategory !== "By Instrument"
    ) {
      setChartType("Profit & Loss ($)");
    }
    refreshFilter(strategyName, newCategory);
    setCategory(newCategory);
  };

  const handleChartTypeChange = async (name, newChartType) => {
    if (!newChartType || chartType === newChartType) return;
    setChartType(newChartType);
  };

  const handleStrategyChange = async (name, newStrategyName) => {
    if (!newStrategyName || strategyName === newStrategyName) return;
    setChartFilter("All");
    if (newStrategyName !== "Portfolio" && category === "By Strategy") {
      setCategory("Overall");
    }
    setStrategyName(newStrategyName);
  };

  const handleChartFilterChange = async (name, newChartFilter) => {
    if (!newChartFilter || chartFilter === newChartFilter) return;
    setChartFilter(newChartFilter);
  };

  const refreshFilter = (strategy, category) => {
    const listFilters = [
      "All",
      ...Object.keys(itemData[snapshot][strategy][category]),
    ];
    setListChartFilters(listFilters);
    if (!listFilters.includes(chartFilter)) {
      setChartFilter("All");
    }
  };

  return (
    <div className="item-performance-stats">
      <div className="performance-stats-container">
        <MultipleChoiceBox
          name="strategy-selection"
          choices={listStrategies}
          value={strategyName}
          onUpdate={handleStrategyChange}
        />
        <MultipleChoiceBox
          name="category-selection"
          choices={listCategories}
          value={category}
          onUpdate={handleCategoryChange}
        />
        <MultipleChoiceBox
          name="chart-type-selection"
          choices={listChartTypes}
          value={chartType}
          onUpdate={handleChartTypeChange}
        />
        {chartType !== "No Chart" && (
          <MultipleChoiceBox
            name="chart-filter-selection"
            choices={listChartFilters}
            value={chartFilter}
            onUpdate={handleChartFilterChange}
          />
        )}
      </div>
      {itemData && (
        <div className="chart-container">
          {
            <ItemChartPerformance
              itemData={itemData}
              strategyName={strategyName}
              category={category}
              chartType={chartType}
              chartFilter={chartFilter}
            />
          }
        </div>
      )}
      <div className="table-container">
        <ItemTablePerformance
          strategyName={strategyName}
          itemData={itemData}
          snapshot={snapshot}
          category={category}
        />
      </div>
    </div>
  );
};

export default ItemPerformanceStats;
