/**
 * @since 2023-12-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import PanelLiveStats from "./PanelLiveStats";

export default PanelLiveStats;
