/**
 * @since 2023-11-25
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import InputBox from "components/Shared/InputBox";
import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import { validateInteger, validateRatio } from "utils/validation";
import "./Spread.scss";

const Spread = ({ spreadId, spreadSettings, dictInstruments, isEdit, onUpdate, onValidationErrorsUpdate }) => {
  //region States

  const [spreadSettingsEdited, setSpreadSettingsEdited] = useState({ ...spreadSettings });
  const [instrument, setInstrument] = useState(null);

  //endregion

  //region Use Effects

  useEffect(() => {
    validateErrors(spreadSettingsEdited);
  }, [spreadSettingsEdited]);

  useEffect(() => {
    setSpreadSettingsEdited(spreadSettings);
    setInstrument(dictInstruments[spreadSettings.SecurityName]);
  }, [spreadSettings]);

  //endregion

  //region Methods

  const handleBetaChange = async (name, value) => {
    const newSpreadSettings = {
      ...spreadSettingsEdited,
      BetaRatio: value,
    };
    setSpreadSettingsEdited(newSpreadSettings);
    onUpdate(spreadId, newSpreadSettings);
  };

  const handleFixedRatioChange = async (name, value) => {
    const newSpreadSettings = {
      ...spreadSettingsEdited,
      FixedRatio: value,
    };
    setSpreadSettingsEdited(newSpreadSettings);
    onUpdate(spreadId, newSpreadSettings);
  };

  const handleNPeriodsChange = async (name, value) => {
    const newSpreadSettings = {
      ...spreadSettingsEdited,
      NPeriods: value,
    };
    setSpreadSettingsEdited(newSpreadSettings);
    onUpdate(spreadId, newSpreadSettings);
  };

  const handleSpreadTypeChange = async (name, value) => {
    const newSpreadSettings = {
      ...spreadSettingsEdited,
      SpreadType: value,
    };
    setSpreadSettingsEdited(newSpreadSettings);
    onUpdate(spreadId, newSpreadSettings);
  };

  const handleVolatilityChange = async (name, value) => {
    const newSpreadSettings = {
      ...spreadSettingsEdited,
      VolatilityRatio: value,
    };
    setSpreadSettingsEdited(newSpreadSettings);
    onUpdate(spreadId, newSpreadSettings);
  };

  const validateErrors = async (newSpreadSettings) => {
    if (!newSpreadSettings) newSpreadSettings = spreadSettingsEdited;
    const listErrors = [];
    if (newSpreadSettings.SpreadType === "Fixed Ratio") {
      let { isValid, errorMessage } = validateRatio(newSpreadSettings.FixedRatio);
      if (!isValid) listErrors.push(`${spreadSettingsEdited.SecurityName}: Invalid fixed ratio`);
    } else if (newSpreadSettings.SpreadType === "Beta Ratio") {
      let { isValid, errorMessage } = validateInteger(newSpreadSettings.NPeriods);
      if (!isValid) listErrors.push(`${spreadSettingsEdited.SecurityName}: Invalid n periods`);
      ({ isValid, errorMessage } = validateRatio(newSpreadSettings.BetaRatio));
      if (!isValid) listErrors.push(`${spreadSettingsEdited.SecurityName}: Invalid beta ratio`);
    } else if (newSpreadSettings.SpreadType === "Volatility Ratio") {
      let { isValid, errorMessage } = validateInteger(newSpreadSettings.NPeriods);
      if (!isValid) listErrors.push(`${spreadSettingsEdited.SecurityName}: Invalid n periods`);
      ({ isValid, errorMessage } = validateRatio(newSpreadSettings.VolatilityRatio));
      if (!isValid) listErrors.push(`${spreadSettingsEdited.SecurityName}: Invalid volatility ratio`);
    } else {
      listErrors.push(
        `${spreadSettingsEdited.SecurityName}: ${newSpreadSettings.SpreadType} is not a valid spread type`
      );
    }
    await onValidationErrorsUpdate(spreadId, listErrors);
  };

  //endregion

  return (
    <div className="spread-entry-table">
      {" "}
      <div className="strategy-name-container">
        <Tooltip tooltipText={instrument?.Description} size="small">
          {spreadSettingsEdited.SecurityName}
        </Tooltip>
      </div>
      <Tooltip tooltipText="Spread type:<br/>- Fixed Ratio<br/>- Beta Ratio<br/>- Volatility Ratio" size="small">
        <div className="spread-type" style={{ marginLeft: "10px" }}>
          {isEdit ? (
            <MultipleChoiceBox
              name={spreadId + "-spread-type"}
              choices={["Fixed Ratio", "Beta Ratio", "Volatility Ratio"]}
              value={spreadSettingsEdited?.SpreadType || ""}
              onUpdate={handleSpreadTypeChange}
            />
          ) : (
            spreadSettingsEdited?.SpreadType
          )}
        </div>
      </Tooltip>
      {
        /* Fixed Ratio */
        spreadSettingsEdited?.SpreadType === "Fixed Ratio" && (
          <Tooltip
            tooltipText={
              "Fixed Ratio: for each share of " +
              instrument?.SecurityName1 +
              " purchase " +
              spreadSettingsEdited?.FixedRatio +
              " shares of " +
              instrument?.SecurityName2
            }
            size="small"
          >
            <div className="fixed-ratio" style={{ marginLeft: "10px" }}>
              {isEdit ? (
                <InputBox value={spreadSettingsEdited?.FixedRatio} maxLength={10} onUpdate={handleFixedRatioChange} />
              ) : (
                spreadSettingsEdited?.FixedRatio
              )}
            </div>
          </Tooltip>
        )
      }
      {
        /* N Periods */
        ["Beta Ratio", "Volatility Ratio"].includes(spreadSettingsEdited?.SpreadType) && (
          <Tooltip
            tooltipText={`Number of periods used for calculating the ${spreadSettingsEdited?.SpreadType.toLowerCase()}: days for daily strategies, minutes for minute-based strategies, etc.`}
            size="small"
          >
            <div className="spread-quantity" style={{ marginLeft: "10px" }}>
              {isEdit ? (
                <div className="spread-quantity">
                  <InputBox value={spreadSettingsEdited?.NPeriods} maxLength={10} onUpdate={handleNPeriodsChange} />
                </div>
              ) : (
                spreadSettingsEdited?.NPeriods
              )}
            </div>
          </Tooltip>
        )
      }
      {
        /* Beta */
        spreadSettingsEdited?.SpreadType === "Beta Ratio" && (
          <Tooltip
            tooltipText={`Beta ratio between the two instruments. For example if the beta between the strategies is 0.5 and this value is set to 1, the spread will allocate two shares of ${instrument?.SecurityName2} for each share of ${instrument?.SecurityName1}`}
            size="small"
          >
            <div className="spread-quantity" style={{ marginLeft: "10px" }}>
              {isEdit ? (
                <div className="spread-quantity">
                  <InputBox value={spreadSettingsEdited?.BetaRatio} maxLength={10} onUpdate={handleBetaChange} />
                </div>
              ) : (
                spreadSettingsEdited?.BetaRatio
              )}
            </div>
          </Tooltip>
        )
      }
      {
        /* Volatility */
        spreadSettingsEdited?.SpreadType === "Volatility Ratio" && (
          <Tooltip
            tooltipText={`Volatility ratio between the two instruments. For example if the volatility between the strategies is 0.5 and this value is set to 1, the spread will allocate two shares of ${instrument?.SecurityName2} for each share of ${instrument?.SecurityName1}`}
            size="small"
          >
            <div className="spread-quantity" style={{ marginLeft: "10px" }}>
              {isEdit ? (
                <div className="spread-quantity">
                  <InputBox
                    value={spreadSettingsEdited?.VolatilityRatio}
                    maxLength={10}
                    onUpdate={handleVolatilityChange}
                  />
                </div>
              ) : (
                spreadSettingsEdited?.VolatilityRatio
              )}
            </div>
          </Tooltip>
        )
      }
    </div>
  );
};

export default Spread;
