/**
 * @since 2024-05-04
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import LabelBox from "./LabelBox";

export default LabelBox;
