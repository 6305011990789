import * as echarts from "echarts";
import { useEffect, useRef } from "react";

const ChartReturnsAndDrawdowns = ({ isLoading, chartData, height = 500, showXAxis = true, showLegend = true }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (isLoading) return;

    const chartInstance = echarts.getInstanceByDom(chartRef.current);
    if (chartInstance) {
      chartInstance.dispose();
    }

    const myChart = echarts.init(chartRef.current);
    const showMonth = hasMultipleEntriesPerYear(chartData.Dates);
    const allReturns = Object.values(chartData.Values).map((item) => item.Returns);
    const flattenedReturns = [].concat(...allReturns);
    const minValue = Math.min(0, ...flattenedReturns);
    const maxValue = Math.max(0, ...flattenedReturns);
    const yAxisPadding = (maxValue - minValue) * 0.1;
    const minY = Math.floor(minValue - yAxisPadding);
    const maxY = Math.ceil(maxValue + yAxisPadding);

    const option = {
      grid: [
        {
          left: "0%",
          right: "0%",
          bottom: "30%",
          top: "2%",
          containLabel: true,
        },
        {
          left: "0%",
          right: "0%",
          top: "65%",
          height: "30%",
          containLabel: true,
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: function (params) {
          let res = params[0].axisValueLabel + "<br/>";
          params.forEach((param) => {
            res += param.marker + " " + param.seriesName + ": " + param.value + "<br/>";
          });
          return res;
        },
        backgroundColor: "#191A1D",
        borderColor: "#78BCFB",
        borderWidth: 1,
        textStyle: {
          color: "rgba(255, 255, 255, 0.5)",
          fontSize: 12,
        },
      },
      series: [
        ...chartData.Values.map((item) => ({
          name: item.Name,
          type: "line",
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: item.Returns,
          showSymbol: false,
        })),
        {
          name: "Drawdowns",
          type: "line",
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: chartData.Drawdowns,
          itemStyle: { color: "#F082FA" },
          showSymbol: false,
        },
      ],
      xAxis: [
        {
          type: "category",
          gridIndex: 0,
          data: chartData.Dates,
          show: false, // Hides the x-axis for the first plot
        },
        {
          type: "category",
          gridIndex: 1,
          data: chartData.Dates,
          axisLabel: {
            show: showXAxis,
            rotate: 45,
            formatter: function (value) {
              return datePrettier(value, showMonth);
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          gridIndex: 0,
          min: minY,
          max: maxY,
          axisLabel: {
            formatter: "{value} %",
          },
          splitLine: {
            show: false, // Hide vertical lines for the first plot
          },
        },
        {
          type: "value",
          gridIndex: 1,
          // Customize as per your drawdown data
          axisLabel: {
            formatter: "{value} %",
          },
          splitLine: {
            show: false, // Hide vertical lines for the second plot
          },
        },
      ],
      legend: {
        type: "scroll",
        textStyle: {
          color: "white",
          fontSize: 14,
        },
        top: 0,
        left: 50,
        data: chartData.Values.map((item) => item.Name),
      },
    };

    myChart.setOption(option);

    function resizeChart() {
      const parentWidth = myChart?.getDom().parentNode.clientWidth * 0.98;
      const chartHeight = myChart?.getDom().offsetHeight * 0.98;
      myChart?.resize({ width: parentWidth, height: chartHeight });
    }

    const onWindowResize = () => {
      resizeChart();
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [isLoading, chartData, showXAxis, showLegend]);

  const hasMultipleEntriesPerYear = (dates) => {
    const yearCounts = dates.reduce((acc, date) => {
      const year = new Date(date).getFullYear();
      acc[year] = (acc[year] || 0) + 1;
      return acc;
    }, {});
    return Object.values(yearCounts).some((count) => count > 1);
  };

  const datePrettier = (dateString, showMonth) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return showMonth ? `${year}-${month}` : `${year}`;
  };

  return <div ref={chartRef} style={{ width: "100%", height: height }} />;
};

export default ChartReturnsAndDrawdowns;
