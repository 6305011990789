/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import PropTypes from "prop-types";

const ItemText = ({ itemData }) => {
  return (
    <div
      style={{
        color: "white",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "14px",
        cursor: "default",
      }}
      dangerouslySetInnerHTML={{ __html: itemData }}
    />
  );
};

ItemText.propTypes = {
  itemData: PropTypes.string.isRequired,
};
export default ItemText;
