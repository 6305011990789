/**
 * @since 2023-11-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import SectionScoresError from "./SectionScoresError";

export default SectionScoresError;
