/**
 * @since 2023-04-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { BASE_BACKEND_URL } from "./constants";
import { createExcelDownload } from "./utils";

const acceptTermsAndConditions = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/acceptTermsAndConditions/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const cancelSubscription = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/cancelSubscription/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const downloadDailyReturns = async (
  email,
  moduleType,
  moduleId,
  moduleName
) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/downloadDailyReturns/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, moduleType, moduleId }),
      }
    );
    if (response.ok) {
      await createExcelDownload(response, moduleName + ".DailyReturns.xlsx");
    } else {
      console.error("Cannot download daily returns.");
    }
  } catch (error) {
    console.error(error);
  }
};

const downloadMonthlyReturns = async (
  email,
  moduleType,
  moduleId,
  moduleName
) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/downloadMonthlyReturns/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, moduleType, moduleId }),
      }
    );
    if (response.ok) {
      await createExcelDownload(response, moduleName + ".MonthlyReturns.xlsx");
    } else {
      console.error("Cannot download monthly returns.");
    }
  } catch (error) {
    console.error(error);
  }
};

const downloadTrades = async (email, moduleType, moduleId, moduleName) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/downloadTrades/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, moduleType, moduleId }),
      }
    );
    if (response.ok) {
      await createExcelDownload(response, moduleName + ".Trades.xlsx");
    } else {
      console.error("Cannot download trades.");
    }
  } catch (error) {
    console.error(error);
  }
};

const getSubscriptionLinks = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/getSubscriptionLinks/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const getTermsAndConditions = async () => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/getTermsAndConditions/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const initializeUser = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/initializeUser/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadConfigurationData = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/getConfigurationData/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadMenuItems = async (email) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/getMenuItems/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const notifyUserLogin = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/notifyUserLogin/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const parseReturnsFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/parseReturnsFile/`,
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

const saveProfileSettings = async (email, dictProfileSettings) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/saveProfileSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, dictProfileSettings }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const sendFeedback = async (email, category, feedback) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/sendFeedback/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, category, feedback }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export {
  acceptTermsAndConditions,
  cancelSubscription,
  downloadDailyReturns,
  downloadMonthlyReturns,
  downloadTrades,
  getSubscriptionLinks,
  getTermsAndConditions,
  initializeUser,
  loadConfigurationData,
  loadMenuItems,
  notifyUserLogin,
  parseReturnsFile,
  saveProfileSettings,
  sendFeedback,
};
