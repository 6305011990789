/**
 * @since 2023-12-21
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ItemPerformanceStats from "./ItemPerformanceStats";

export default ItemPerformanceStats;
