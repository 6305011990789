/**
 * @since 2023-05-15
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import React, { useState } from "react";
import { FaSearch } from "react-icons/fa"; // import search icon
import "./SearchBox.scss";

const SearchBox = ({ name, value, onUpdate, maxLength = 30, height = 40 }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    onUpdate(name, event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="search-box-container">
      <FaSearch className="search-box-icon" />
      <input
        key={`InputBox.${name}`}
        className={`search-box-input ${isFocused ? "focused" : ""}`}
        style={{ height: height, cursor: "text" }}
        type="text"
        value={value}
        maxLength={maxLength}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default SearchBox;
