/**
 * @since 2023-11-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import {
  downloadDailyReturns,
  downloadMonthlyReturns,
  downloadTrades,
} from "dataHandling/general";
import { BiDownload } from "react-icons/bi";
import "./BarActionsAnalysis.scss";

const BarActionsAnalysis = ({
  email,
  moduleType,
  moduleId,
  moduleName,
  showTrades = false,
}) => {
  const handleDownloadDaily = async () => {
    await downloadDailyReturns(email, moduleType, moduleId, moduleName);
  };

  const handleDownloadMonthly = async () => {
    await downloadMonthlyReturns(email, moduleType, moduleId, moduleName);
  };

  const handleDownloadTrades = async () => {
    await downloadTrades(email, moduleType, moduleId, moduleName);
  };

  return (
    <div className="bar-actions-analysis">
      <h2
        style={{
          color: "white",
          marginTop: "30px",
          marginBottom: "30px",
          fontSize: "24px",
          color: "rgb(255, 255, 255, 0.7)",
        }}
      >
        Download
      </h2>
      <div className="download-section">
        <div className="download-item" onClick={handleDownloadDaily}>
          <BiDownload className="download-icon" />
          <span className="download-label">Daily Returns</span>
        </div>
        <div className="download-item" onClick={handleDownloadMonthly}>
          <BiDownload className="download-icon" />
          <span className="download-label">Monthly Returns</span>
        </div>
        {showTrades && (
          <div className="download-item" onClick={handleDownloadTrades}>
            <BiDownload className="download-icon" />
            <span className="download-label">Trades</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default BarActionsAnalysis;
