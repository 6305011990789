/**
 * @since 2024-06-01
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ItemTableCorrelations from "./ItemTableCorrelations";

export default ItemTableCorrelations;
