/**
 * @since 2023-05-12
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { BASE_BACKEND_URL } from "./constants";

const deleteUniverseSettings = async (email, strategyId, universeId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/deleteUniverseSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId, universeId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadAllUniverseSettings = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadAllUniverseSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadUniverseSettings = async (email, universeId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadUniverseSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, universeId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const saveUniverseSettings = async (email, universeSettings) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/saveUniverseSettings/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, universeSettings }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export {
  deleteUniverseSettings,
  loadAllUniverseSettings,
  loadUniverseSettings,
  saveUniverseSettings,
};
