/**
 * @since 2023-05-15
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Modal } from "antd";
import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import PanelInstrumentsEdit from "components/Shared/PanelInstrumentsEdit";
import { saveUniverseSettings } from "dataHandling/universe";
import { useEffect, useState } from "react";
import { validateName } from "utils/validation";
import "./ModalInstrumentSelector.scss";

const ModalInstrumentSelector = ({
  isOpen,
  email,
  universe,
  listUniverseNames,
  listAllInstruments,
  operationType,
  onSave,
  onCancel,
}) => {
  const [title, setTitle] = useState("INSTRUMENT SELECTION");
  const [universeName, setUniverseName] = useState(universe?.UniverseName);
  const [universeDescription, setUniverseDescription] = useState(universe?.UniverseDescription);
  const [listSelectedInstruments, setListSelectedInstuments] = useState([]);
  const [listRemainingInstruments, setListRemainingInstruments] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  //region UseEffects

  useEffect(() => {
    if (operationType === "New") setTitle("NEW INSTRUMENT COLLECTION");
    else if (operationType === "Duplicate") setTitle("DUPLICATE INSTRUMENT COLLECTION");
    else if (operationType === "Edit") setTitle("EDIT INSTRUMENT COLLECTION");
    else setTitle("INSTRUMENT COLLECTION");
  }, [operationType]);

  useEffect(() => {
    setUniverseName(universe?.UniverseName);
    setUniverseDescription(universe?.UniverseDescription);
    const listSecurityNames = universe?.ListSecurityNames || [];
    setListSelectedInstuments(
      listAllInstruments.filter((instrument) => listSecurityNames.includes(instrument.SecurityName))
    );
    validateFields(universe?.UniverseName, listSelectedInstruments);
  }, [universe]);

  useEffect(() => {
    setListRemainingInstruments(
      listAllInstruments.filter((s1) => !listSelectedInstruments.some((s2) => s2.SecurityName === s1.SecurityName))
    );
    validateFields(universeName, listSelectedInstruments);
  }, [listSelectedInstruments]);

  //endregion

  const handleUniverseNameUpdate = (fieldName, newUniverseName) => {
    setUniverseName(newUniverseName);
    validateFields(newUniverseName, listSelectedInstruments);
  };

  const handleAddSecurity = (security) => {
    const securityDict = Array.from(listRemainingInstruments).find((s) => security.SecurityName === s.SecurityName);
    if (securityDict === null) return;
    setListSelectedInstuments(
      [...listSelectedInstruments, securityDict].sort((a, b) => a.SecurityName.localeCompare(b.SecurityName))
    );
    setListRemainingInstruments(listRemainingInstruments.filter((s) => security.SecurityName !== s.SecurityName));
  };

  const handleRemoveSecurity = (security) => {
    const securityDict = Array.from(listSelectedInstruments).find((s) => security.SecurityName === s.SecurityName);
    if (securityDict === null) return;
    setListSelectedInstuments(listSelectedInstruments.filter((s) => security.SecurityName !== s.SecurityName));
    setListRemainingInstruments(
      [...listRemainingInstruments, securityDict].sort((a, b) => a.SecurityName.localeCompare(b.SecurityName))
    );
  };

  const handleSave = async () => {
    const newUniverseSettings = {
      ...universe,
      UniverseName: universeName,
      UniverseDescription: universeDescription,
      IsCustom: true,
      ListIndexConstituents: [],
      ListSecurityNames: listSelectedInstruments.map((item) => item.SecurityName),
    };
    const universeId = await saveUniverseSettings(email, newUniverseSettings);
    onSave(universeId);
  };

  const validateFields = (newUniverseName, newListInstruments) => {
    const listErrors = [];
    const errorName = validateUniverseName(newUniverseName);
    if (errorName) listErrors.push(errorName);
    const errorInstruments = validateListInstruments(newListInstruments);
    if (errorInstruments) listErrors.push(errorInstruments);
    setErrorMessage(listErrors.join(", "));
  };

  const validateUniverseName = (newUniverseName) => {
    if (operationType != "Edit" && listUniverseNames.includes(newUniverseName)) {
      return "Name already used.";
    }
    const { isValid, errorMessage } = validateName(newUniverseName, 1, 30);
    if (!isValid) {
      return `Name ${errorMessage}`;
    }
    return null;
  };

  const validateListInstruments = (newListInstruments) => {
    if (newListInstruments.length === 0) {
      return "At least one instrument should be selected.";
    }
    return null;
  };

  return (
    <Modal open={isOpen} title={title} footer={null} onCancel={onCancel} className="modal-instrument-selector">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "420px",
            marginTop: "40px",
            marginBottom: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "14px",
            color: "rgba(255, 255, 255, 0.5)",
          }}
        >
          Name
          <InputBox name={"UniverseName"} value={universeName} onUpdate={handleUniverseNameUpdate} />
        </div>
        {errorMessage.length > 0 && (
          <p
            style={{
              color: "red",
              marginLeft: "10px",
              marginTop: "18px",
            }}
          >
            {" "}
            {errorMessage}
          </p>
        )}
      </div>
      <PanelInstrumentsEdit
        isOpen={isOpen}
        listInstruments={listSelectedInstruments}
        isAddition={false}
        onRowSelected={handleRemoveSecurity}
      />
      <PanelInstrumentsEdit
        isOpen={isOpen}
        listInstruments={listRemainingInstruments}
        isAddition={true}
        onRowSelected={handleAddSecurity}
      />
      <BarSaveCancel onCancel={onCancel} onSave={handleSave} isSaveDisabled={errorMessage.length > 0} />
    </Modal>
  );
};

export default ModalInstrumentSelector;
