/**
 * @since 2023-04-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import _ from "lodash";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Allocation from "./Allocation";
import "./TableAllocations.scss";

const TableAllocations = ({
  allocationSettings,
  dictStrategies,
  dictImported,
  dictPremium,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  /*
  Explanation of the data types:
  - SelectorId: id of the component that allows to select a strategy/imported/premium
  - AllocationId: id of the strategy/imported/premium (e.g. STR-20220101-123456)
  - AllocationType: type of the strategy/imported/premium (e.g. Strategy, Imported, Premium)
  - Percentage: percentage allocated for a specific strategy/imported/premium (e.g. 100%)
  */

  const mapSelected = () => {
    return allocationSettings.map((allocation) => ({
      SelectorId: uuidv4(),
      AllocationId: allocation.AllocationId,
      allocationName: allocation.AllocationName,
      AllocationType: allocation.AllocationType,
      AllocationType_DisplayName: allocation.AllocationType_DisplayName,
      Percentage: allocation.Percentage,
    }));
  };

  const mapAll = () => {
    const listAll = [];
    // Add strategies
    if (dictStrategies) {
      for (const [strategyName, strategyId] of Object.entries(dictStrategies)) {
        listAll.push({
          AllocationId: strategyId,
          AllocationName: strategyName,          
          AllocationType: "Strategy",
          AllocationType_DisplayName: "Custom",
        });
      }
    }
    // Add imported
    if (dictImported) {
      for (const [importedName, importedId] of Object.entries(dictImported)) {
        listAll.push({
          AllocationId: importedId,
          AllocationName: importedName,
          AllocationType: "Imported",
          AllocationType_DisplayName: "Imported",
        });
      }
    }
    if (dictPremium) {
      // Add premium
      for (const [premiumName, premiumId] of Object.entries(dictPremium)) {
        listAll.push({
          AllocationId: premiumId,
          AllocationName: premiumName,
          AllocationType: "Premium",
          AllocationType_DisplayName: "Premium",
        });
      }
    }
    return listAll;
  };

  const mapRemaining = (listNewSelectedEdited) => {
    const list = listNewSelectedEdited ?? listNewSelected;
    if (!list) {
      return [];
    }
    const listAllocationIdsUsed = list.map((allocation) => allocation.AllocationId);
    return listAll.filter((item) => !listAllocationIdsUsed.includes(item.AllocationId));
  };

  const [listAll, setListAll] = useState(mapAll());
  const [listSelected, setListSelected] = useState(mapSelected);
  const [listNewSelected, setListNewSelected] = useState(_.cloneDeep(listSelected));
  const [listRemaining, setListRemaining] = useState(mapRemaining());
  const [dictValidationErrors, setDictValidationErrors] = useState({});
  const [isValidationOk, setIsValidationOk] = useState(true);

  //region Use Effects

  useEffect(() => {
    setListSelected(mapSelected());
  }, [allocationSettings]);

  useEffect(() => {
    setListRemaining(mapRemaining(listNewSelected));
  }, [listNewSelected]);

  useEffect(() => {
    setListAll(mapAll());
    setListRemaining(mapRemaining());
  }, [dictStrategies, dictImported, dictPremium]);

  useEffect(() => {
    const newIsValidationOk = Object.values(dictValidationErrors).every(
      (value) => Array.isArray(value) && value.length === 0
    );
    setIsValidationOk(newIsValidationOk);
  }, [dictValidationErrors]);

  //endregion

  const cancel = () => {
    setListNewSelected(listSelected);
    setDictValidationErrors({});
    onIsEditUpdate(false);
  };

  const handleAddItem = () => {
    const listNewAllocationsEdited = [
      ...listNewSelected,
      {
        SelectorId: uuidv4(),
        AllocationId: "",
        AllocationName: "",
        AllocationType: "",
        Percentage: "100",
      },
    ];
    setListNewSelected(listNewAllocationsEdited);
  };

  const handleAllocationUpdate = async (selectorIndex, selectorId, allocationId, percentage, listValidationErrors) => {
    const item = listAll.find((item) => item.AllocationId === allocationId);
    const listNewAllocationsEdited = [
      ...listNewSelected.slice(0, selectorIndex),
      {
        SelectorId: selectorId,
        AllocationId: allocationId,
        AllocationName: item.AllocationName,
        AllocationType: item.AllocationType,
        AllocationType_DisplayName: item.AllocationType_DisplayName,
        Percentage: percentage,
      },
      ...listNewSelected.slice(selectorIndex + 1),
    ];
    setListNewSelected(listNewAllocationsEdited);
    await handleValidationErrors(selectorId, listValidationErrors);
  };

  const handleAllocationDelete = async (selectorIndex, selectorId) => {
    const listNewAllocationsEdited = [
      ...listNewSelected.slice(0, selectorIndex),
      ...listNewSelected.slice(selectorIndex + 1),
    ];
    setListNewSelected(listNewAllocationsEdited);
    await handleValidationErrors(selectorId, []);
  };

  const handleValidationErrors = async (selectorId, listErrors) => {
    setDictValidationErrors((prevDictValidationErrors) => {
      const newDictValidationErrors = {
        ...prevDictValidationErrors,
        [selectorId]: listErrors,
      };
      if (!listErrors || listErrors.length === 0) {
        delete newDictValidationErrors[selectorId];
      }
      return newDictValidationErrors;
    });
  };

  const renderErrors = () => (
    <div className="errors-container">
      {Object.entries(dictValidationErrors).map(([key, listErrors]) =>
        listErrors.map((error) => (
          <div className="error" key={`${key}-${error}`}>
            {error}
          </div>
        ))
      )}
    </div>
  );

  const save = () => {
    onSettingsChange(listNewSelected);
    onIsEditUpdate(false);
  };

  return (
    <div className="table-allocations">
      <div>
        {listNewSelected.map((allocation, index) => (
          <Allocation
            key={index}
            selectorIndex={index}
            selectorId={allocation.SelectorId}
            allocationId={allocation.AllocationId}
            percentage={allocation.Percentage}
            listAll={listAll}
            listRemaining={listRemaining}
            isEdit={isEdit}
            onUpdate={handleAllocationUpdate}
            onDelete={handleAllocationDelete}
            onValidationErrorsUpdate={handleValidationErrors}
          />
        ))}
        {isEdit && renderErrors()}
        {isEdit && (
          <div className="add-allocation-container">
            <button
              className="add-new-allocation"
              onClick={() => handleAddItem()}
              title={Object.keys(listRemaining).length === 0 ? "All strategies have been used." : ""}
              disabled={Object.keys(listRemaining).length === 0}
            >
              add new strategy
            </button>
            <BarSaveCancel onCancel={cancel} onSave={save} isSaveDisabled={!isValidationOk} />
          </div>
        )}
      </div>
    </div>
  );
};
export default TableAllocations;
