/**
 * @since 2023-06-06
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import Tooltip from "./Tooltip";

export default Tooltip;
