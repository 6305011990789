/**
 * @since 2024-05-06
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Modal } from "antd";
import ButtonBlue from "components/Shared/ButtonBlue";
import ModalSubscription from "components/Shared/ModalSubscription/ModalSubscription";
import { cancelSubscription } from "dataHandling/general.js";
import { useEffect, useState } from "react";
import "./PanelSubscription.scss";

const PanelSubscription = ({
  email,
  menuItems,
  onCancel,
  onSubscriptionChanged,
}) => {
  const [subscription, setSubscription] = useState(null);
  const [isOpen_ModalSubscription, setIsOpen_ModalSubscription] =
    useState(false);

  //region UseEffects

  useEffect(() => {
    const subscriptionCode = menuItems["User"]["subscription"];
    let subscription = null;
    switch (subscriptionCode) {
      case "Lite":
        subscription = "Central Limit AI - Lite";
        break;
      case "PM":
        subscription = "Central Limit AI - Portfolio Manager";
        break;
      case "Q":
        subscription = "Central Limit AI - Quant";
        break;
      case "T":
        subscription = "Central Limit AI - Trader";
        break;
      default:
        subscription = "Unknown";
        break;
    }
    setSubscription(subscription);
  }, [menuItems]);

  //endregion

  //region Methods

  const actuallyCancelSubscription = async () => {
    const isOk = await cancelSubscription(email);
    if (isOk) {
      Modal.info({
        title: "Subscription Canceled",
        content: "You can still use the Lite version of Strategy Architect.",
        okText: "OK",
        className: "cancel-modal",
        icon: null,
      });
    } else {
      Modal.info({
        title: "Subscription not canceled",
        content: "Please contact support@centrallimit.ai.",
        okText: "OK",
        className: "cancel-modal",
        icon: null,
      });
    }
    // to-do
    onSubscriptionChanged();
  };

  const tryCancelSubscription = () => {
    Modal.confirm({
      className: "cancel-modal",
      icon: null,
      title: `Are you sure you want to cancel your subscription?`,
      content: "",
      onOk() {
        actuallyCancelSubscription();
      },
      onCancel() {
        // No action needed on cancel
      },
    });
  };

  const upgradeSubscription = () => {
    setIsOpen_ModalSubscription(true);

    // to-do
    onSubscriptionChanged();
  };

  const closePanel = () => {
    setIsOpen_ModalSubscription(false);
    onCancel();
  };

  //endregion

  return (
    <div className="panel-subscription">
      <div
        style={{
          marginTop: "80px",
          marginBottom: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "rgba(255, 255, 255, 0.5)",
        }}
      >
        Your current subscription is:
      </div>
      <div
        style={{ fontSize: "20px", marginBottom: "30px", textAlign: "center" }}
      >
        {subscription}
      </div>
      {subscription == "Strategy Architect Lite" ? (
        <ButtonBlue
          label="UPGRADE SUBSCRIPTION"
          width="250px"
          height="30px"
          onClick={upgradeSubscription}
        />
      ) : (
        <ButtonBlue
          label="CANCEL SUBSCRIPTION"
          width="250px"
          height="30px"
          onClick={tryCancelSubscription}
        />
      )}
      <ModalSubscription
        isOpen={isOpen_ModalSubscription}
        email={email}
        onCancel={closePanel}
      />
    </div>
  );
};

export default PanelSubscription;
