/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import DateRangeSelector from "components/Shared/DateRangeSelector";
import Tooltip from "components/Shared/Tooltip";
import { useState } from "react";
import { useIntl } from "react-intl";
import { toDollars } from "utils/formatting";
import "./TableSettings.scss";

const TableSettings = ({ email, configuration, settings }) => {
  const intl = useIntl();

  const getUniverseName = (universeId) => {
    if (universeId === null) return "None";
    const universe = listUniverses.find((u) => u.UniverseId === universeId);
    if (universe) return universe.UniverseName;
    return "None";
  };

  const getDefaultSettings = () => {
    return {
      Name: {
        type: "InputBox",
        fieldName: "PortfolioName",
        value: settings.Settings.PortfolioName,
        tooltip: intl.formatMessage({ id: "premiumbuilder-settings-name" }),
      },
      Capital: {
        type: "Input.Dollar",
        fieldName: "Capital",
        value: toDollars(settings.Settings.Capital),
        tooltip: intl.formatMessage({ id: "premiumbuilder-settings-capital" }),
      },
      Description: {
        type: "MultiLineInputBox",
        fieldName: "Description",
        value: settings.Settings.Description,
        tooltip: intl.formatMessage({ id: "premiumbuilder-settings-description" }),
      },
      Benchmark: {
        type: "MultipleChoiceBox",
        fieldName: "Benchmark",
        value: settings.Settings.Benchmark,
        choices: configuration.Benchmarks.map((item) => item.BenchmarkName),
        tooltip: intl.formatMessage({ id: "premiumbuilder-settings-benchmark" }),
      },
      Period: {
        type: "DatePeriod",
        fieldName: "Period",
        startDate: settings.Settings.StartDate,
        endDate: settings.Settings.EndDate,
        tooltip: intl.formatMessage({ id: "premiumbuilder-settings-period" }),
      },
    };
  };

  const settingsFields = getDefaultSettings();
  const listLeftSettings = ["Name", "Description"];
  const listRightSettings = ["Capital", "Benchmark", "Period"];
  const listUniverses = useState(configuration.InstrumentUniverses);

  const renderSettings = (listSettings) => {
    return listSettings.map((name) => {
      // Adding the condition here
      if (name === "Daily Signal Time" && settingsFields["Frequency"].value !== "Daily") {
        return null;
      }

      return (
        <div className="setting-item" key={`${name}-Outer`}>
          <div className="setting-row" key={`${name}-Main`}>
            <div className="setting-name" key={`${name}-Name`}>
              <Tooltip tooltipText={settingsFields[name].tooltip} size="small">
                {name}
              </Tooltip>
            </div>
            <div className="setting-value" key={`${name}-Value`}>
              {renderViewInputField(name, settingsFields[name])}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderViewInputField = (name, item) => {
    if (name === "Benchmark") {
      return item.value === "None" ? "Not selected" : item.value;
    }
    switch (item.type) {
      case "UniverseSelector":
        return getUniverseName(item.value);
      case "DatePeriod":
        return (
          <DateRangeSelector
            startDate={settingsFields["Period"].startDate}
            endDate={settingsFields["Period"].endDate}
            isEditMode={false}
          />
        );
      default:
        return item.value;
    }
  };

  return (
    <div className="table-settings-premium">
      <div className="main-container">
        <div className="left-settings">{renderSettings(listLeftSettings)}</div>
        <div className="right-settings">{renderSettings(listRightSettings)}</div>
      </div>
    </div>
  );
};
export default TableSettings;
