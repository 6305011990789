/**
 * @since 2023-12-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import InputBox from "components/Shared/InputBox";
import "./TimeRangeSelector.scss";

const TimeRangeSelector = ({ startTime, endTime, onUpdateStartTime, onUpdateEndTime, isEditMode }) => {
  const showRange = () => {
    return `${startTime} to ${endTime}`;
  };

  return isEditMode ? (
    <div className="time-range-selector">
      <InputBox name="Start Time" value={startTime} onUpdate={(event, value) => onUpdateStartTime(value.trim())} />
      to
      <InputBox name="End Time" value={endTime} onUpdate={(event, value) => onUpdateEndTime(value.trim())} />
    </div>
  ) : (
    showRange()
  );
};

export default TimeRangeSelector;
