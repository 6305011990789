/**
 * @since 2023-08-10
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import DateRangeSelector from "./DateRangeSelector";

export default DateRangeSelector;
