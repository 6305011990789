/**
 * @since 2023-12-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TimeRangeSelector from "./TimeRangeSelector";

export default TimeRangeSelector;
