/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

/*
A factor has:
- Name
- List of parameters
If Factor model:
- List of Subfactors, each one with its own parameters
*/

import Parameter from "../Parameter";

const Indicator = ({
  indicatorIndex,
  indicatorSettings,
  indicatorInfo,
  isEdit,
  onIndicatorChange,
}) => {
  const handleParameterChange = (parameterIndex, newParameterSettings) => {
    let newIndicatorSettings = { ...indicatorSettings };
    newIndicatorSettings.Parameters[parameterIndex] = newParameterSettings;
    onIndicatorChange(indicatorIndex, newIndicatorSettings);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {
        /* Parameters */
        indicatorSettings?.Parameters?.map((parameterSettings, index) => (
          <Parameter
            key={index}
            parameterIndex={index}
            parameterSettings={{ ...parameterSettings }}
            parameterInfo={indicatorInfo?.Parameters[index]}
            isEdit={isEdit}
            isFactor={false}
            onParameterChange={handleParameterChange}
          />
        ))
      }
    </div>
  );
};

export default Indicator;
