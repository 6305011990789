/**
 * @since 2023-04-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";

const SectionScoresStop = ({ onClick }) => {
  return (
    <div style={{ marginLeft: "30px", marginTop: "60px" }}>
      <p
        style={{
          fontSize: "16px",
          color: "white",
        }}
      >
        The in-depth analysis is currently running on our servers.
        <br />
        <br />
        It might take several hours as many tests will be performed.
        <br />
        <br />
        During the process, you will not be able to modify the strategy,
        although you can still clone it using the menu on the left and modify
        the copy.
        <br />
        <br />
      </p>
      <ButtonBlue label="STOP IN-DEPTH ANALYSIS" width="220px" alignment="left" onClick={onClick} />
    </div>
  );
};
export default SectionScoresStop;
