/**
 * @since 2023-11-23
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TableSpreads from "./TableSpreads";

export default TableSpreads;
