/**
 * @since 2023-04-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import Report from "components/Shared/Report";
import TextError from "components/Shared/TextError";
import TextWait from "components/Shared/TextWait";
import { useEffect, useState } from "react";

import {
  createPortfolioAnalysis,
  getStatusPortfolioAnalysis,
  loadPortfolioPreview,
  waitForPortfolioAnalysis,
} from "dataHandling/portfolio";

const PreviewChart = ({ email, portfolioSettings }) => {
  const [status, setStatus] = useState("");
  const [previewData, setPreviewData] = useState();

  useEffect(() => {
    fetchData();
  }, [portfolioSettings, email]);

  const fetchData = async () => {
    const portfolioId = portfolioSettings.Settings.PortfolioId;
    if (!portfolioId) return;
    let newStatus = await getStatusPortfolioAnalysis(email, portfolioId);
    if (newStatus === "WAITING") {
      setStatus(newStatus);
      newStatus = await waitForPortfolioAnalysis(email, portfolioId);
    }
    if (newStatus === "OK") {
      const newPreviewData = await loadPortfolioPreview(email, portfolioId);
      setPreviewData(newPreviewData);
    }
    setStatus(newStatus);
  };

  const handleCreateAnalysis = async () => {
    setStatus("WAITING");
    const portfolioId = portfolioSettings.Settings.PortfolioId;
    await createPortfolioAnalysis(email, portfolioId);
    fetchData();
  };

  return (
    <>
      {status === "WAITING" && <TextWait />}
      {status === "ERROR" && (
        <>
          <TextError
            error={
              "Something went wrong, we are working on it. Please try again later."
            }
          />
          <ButtonBlue
            label="CREATE PREVIEW"
            alignment="left"
            height="34px"
            onClick={handleCreateAnalysis}
          />
        </>
      )}
      {status === "NEW" && (
        <ButtonBlue
          label="CREATE PREVIEW"
          alignment="left"
          height="34px"
          onClick={handleCreateAnalysis}
        />
      )}
      {status === "OK" && (
        <>
          <Report
            email={email}
            moduleType={"PORTFOLIO"}
            moduleId={portfolioSettings.Settings.PortfolioId}
            reportData={previewData}
          />
          <ButtonBlue
            label="REFRESH PREVIEW"
            alignment="left"
            height="34px"
            onClick={handleCreateAnalysis}
          />
        </>
      )}
    </>
  );
};

export default PreviewChart;
