/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TableSimulationStats from "./TableSimulationStats";

export default TableSimulationStats;
