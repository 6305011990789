/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import DateRangeSelector from "components/Shared/DateRangeSelector";
import DisplayBox from "components/Shared/DisplayBox";
import InputBox from "components/Shared/InputBox";
import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import TimeRangeSelector from "components/Shared/TimeRangeSelector";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toDollars } from "utils/formatting";
import {
  validateAmount,
  validateDate,
  validateName,
  validateTime,
} from "utils/validation";
import ModalUniverseSelector from "./ModalUniverseSelector/ModalUniverseSelector";
import "./TableSettings.scss";

const TableSettings = ({
  email,
  listStrategyNames,
  configuration,
  settings,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
  listStrategyFrequencies,
  listUniverses,
  onRefreshUniverses,
}) => {
  const intl = useIntl();

  const getUniverseName = (universeId) => {
    if (universeId === null) return "None";
    const universe = listUniverses.find((u) => u.UniverseId === universeId);
    if (universe) return universe.UniverseName;
    return "None";
  };

  const getDefaultSettings = () => {
    return {
      Name: {
        type: "InputBox",
        fieldName: "StrategyName",
        value: settings.Settings.StrategyName,
        tooltip: intl.formatMessage({ id: "strategybuilder-settings-name" }),
      },
      Capital: {
        type: "Input.Dollar",
        fieldName: "Capital",
        value: toDollars(settings.Settings.Capital),
        tooltip: intl.formatMessage({ id: "strategybuilder-settings-capital" }),
      },
      Description: {
        type: "MultiLineInputBox",
        fieldName: "Description",
        value: settings.Settings.Description,
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-description",
        }),
      },
      Frequency: {
        type: "MultipleChoiceBox",
        fieldName: "Frequency",
        value: settings.Settings.Frequency,
        choices: listStrategyFrequencies,
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-frequency",
        }),
      },
      "Trading Time": {
        type: "TimeSelector",
        fieldName: "DailySignalTime",
        value: settings.Settings.DailySignalTime,
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-dailysignaltime",
        }),
      },
      "Trading Hours": {
        type: "TimePeriod",
        fieldName: "TimePeriod",
        intradayStartTime: settings.Settings.IntradayStartTime,
        intradayEndTime: settings.Settings.IntradayEndTime,
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-timeperiod",
        }),
      },
      "Strategy Type": {
        type: "MultipleChoiceBox",
        fieldName: "StrategyType",
        value: settings.Settings.StrategyType,
        choices: [
          "CTA",
          "Mean Reversion",
          "Risk Parity",
          "Trend Following",
          "Other",
        ],
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-strategytype",
        }),
      },
      "Long/Short": {
        type: "MultipleChoiceBox",
        fieldName: "Long/Short",
        value: settings.Settings["Long/Short"],
        choices: ["Long Only", "Short Only"],
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-longshort",
        }),
      },
      Benchmark: {
        type: "MultipleChoiceBox",
        fieldName: "Benchmark",
        value: settings.Settings.Benchmark,
        choices: configuration.Benchmarks.map((item) => item.BenchmarkName),
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-benchmark",
        }),
      },
      Instruments: {
        type: "UniverseSelector",
        fieldName: "UniverseId",
        value: settings.Settings.UniverseId,
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-universeid",
        }),
      },
      "Trading Period": {
        type: "DatePeriod",
        fieldName: "DatePeriod",
        startDate: settings.Settings.StartDate,
        endDate: settings.Settings.EndDate,
        tooltip: intl.formatMessage({
          id: "strategybuilder-settings-dateperiod",
        }),
      },
    };
  };

  const [settingsFields, setSettingsFields] = useState(getDefaultSettings());
  const listLeftSettings = [
    "Name",
    "Capital",
    "Frequency",
    "Trading Time",
    "Trading Hours",
    "Description",
  ];
  const listRightSettings = [
    "Strategy Type",
    "Long/Short",
    "Benchmark",
    "Instruments",
    "Trading Period",
  ];
  const [listValidationErrors, setListValidationErrors] = useState([]);
  const [listValidationWarnings, setListValidationWarnings] = useState([]);
  const [isOpenInstrumentSelector, setIsOpenUniverseSelector] = useState(false);

  useEffect(() => {
    validateFields(settingsFields);
  }, [settingsFields]);

  const cancel = () => {
    setSettingsFields(getDefaultSettings());
    onIsEditUpdate(false);
  };

  const showInstrumentSelector = () => {
    setIsOpenUniverseSelector(true);
  };

  const handle_UniverseSelector_Save = (newUniverseId) => {
    setIsOpenUniverseSelector(false);
    const newSettingsFields = {
      ...settingsFields,
      Instruments: {
        ...settingsFields["Instruments"],
        value: newUniverseId,
      },
    };
    setSettingsFields(newSettingsFields);
  };

  const handle_UniverseSelector_Cancel = () => {
    setIsOpenUniverseSelector(false);
  };

  const handleInputChange = (settingName, settingNewValue) => {
    let newValue = String(settingNewValue);
    if (settingsFields[settingName].type === "Input.Dollar") {
      // Add thousand separator
      newValue = toDollars(newValue);
    }
    setSettingsFields({
      ...settingsFields,
      [settingName]: {
        ...settingsFields[settingName],
        value: newValue,
      },
    });
  };

  const handleUpdateStartDate = (newStartDate) => {
    setSettingsFields({
      ...settingsFields,
      "Trading Period": {
        ...settingsFields["Trading Period"],
        startDate: newStartDate,
      },
    });
  };

  const handleUpdateStartTime = (newStartTime) => {
    setSettingsFields({
      ...settingsFields,
      "Trading Hours": {
        ...settingsFields["Trading Hours"],
        intradayStartTime: newStartTime,
      },
    });
  };

  const handleUpdateEndDate = (newEndDate) => {
    setSettingsFields({
      ...settingsFields,
      "Trading Period": {
        ...settingsFields["Trading Period"],
        endDate: newEndDate,
      },
    });
  };

  const handleUpdateEndTime = (newEndTime) => {
    setSettingsFields({
      ...settingsFields,
      "Trading Hours": {
        ...settingsFields["Trading Hours"],
        intradayEndTime: newEndTime,
      },
    });
  };

  const renderErrors = () => {
    return (
      <div className="errors-container">
        {listValidationErrors.map((error) => (
          <div className="error">{error}</div>
        ))}
      </div>
    );
  };

  const renderWarnings = () => {
    return (
      <div className="warnings-container">
        {listValidationWarnings.map((warning) => (
          <div className="warning">{warning}</div>
        ))}
      </div>
    );
  };

  const renderSettings = (listSettings) => {
    return listSettings.map((name) => {
      // Adding the condition here
      if (
        name === "Trading Time" &&
        settingsFields["Frequency"].value !== "Daily"
      ) {
        return null;
      }
      if (
        name === "Trading Hours" &&
        settingsFields["Frequency"].value === "Daily"
      ) {
        return null;
      }

      return (
        <div className="setting-item" key={`${name}-Outer`}>
          <div className="setting-row" key={`${name}-Main`}>
            <div
              className="setting-name"
              key={`${name}-Name`}
              style={{ marginTop: isEdit ? "12px" : "0px" }}
            >
              <Tooltip tooltipText={settingsFields[name].tooltip} size="small">
                {name}
              </Tooltip>
            </div>
            <div className="setting-value" key={`${name}-Value`}>
              {isEdit
                ? renderEditInputField(name, settingsFields[name])
                : renderViewInputField(name, settingsFields[name])}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderEditInputField = (name, item) => {
    switch (item.type) {
      case "InputBox":
      case "Input.Dollar":
        return (
          <InputBox
            name={name}
            value={item.value}
            maxLength={30}
            onUpdate={handleInputChange}
          />
        );
      case "MultiLineInputBox":
        return (
          <InputBox
            name={name}
            value={item.value}
            nRows={7}
            maxLength={400}
            onUpdate={handleInputChange}
          />
        );
      case "MultipleChoiceBox":
        return (
          <MultipleChoiceBox
            name={name}
            choices={item.choices}
            value={item.value}
            onUpdate={handleInputChange}
          />
        );
      case "UniverseSelector":
        return (
          <DisplayBox
            name="UniverseSelector"
            value={getUniverseName(item.value)}
            onClick={showInstrumentSelector}
          />
        );
      case "DatePeriod":
        return (
          <DateRangeSelector
            startDate={settingsFields["Trading Period"].startDate}
            endDate={settingsFields["Trading Period"].endDate}
            onUpdateStartDate={handleUpdateStartDate}
            onUpdateEndDate={handleUpdateEndDate}
            isEditMode={true}
          />
        );
      case "TimePeriod":
        return (
          <TimeRangeSelector
            startTime={settingsFields["Trading Hours"].intradayStartTime}
            endTime={settingsFields["Trading Hours"].intradayEndTime}
            onUpdateStartTime={handleUpdateStartTime}
            onUpdateEndTime={handleUpdateEndTime}
            isEditMode={true}
          />
        );
      case "TimeSelector":
        return (
          <InputBox
            name={name}
            value={item.value}
            maxLength={30}
            onUpdate={handleInputChange}
          />
        );
      default:
        console.log(`Error: ${name} has unknown type ${item.type}`);
    }
  };

  const renderViewInputField = (name, item) => {
    if (name === "Benchmark") {
      return item.value === "None" ? "Not selected" : item.value;
    }
    switch (item.type) {
      case "UniverseSelector":
        return getUniverseName(item.value);
      case "DatePeriod":
        return (
          <DateRangeSelector
            startDate={settingsFields["Trading Period"].startDate}
            endDate={settingsFields["Trading Period"].endDate}
            onUpdateStartDate={handleUpdateStartDate}
            onUpdateEndDate={handleUpdateEndDate}
            isEditMode={false}
          />
        );
      case "TimePeriod":
        return (
          <TimeRangeSelector
            startTime={settingsFields["Trading Hours"].intradayStartTime}
            endTime={settingsFields["Trading Hours"].intradayEndTime}
            onUpdateStartTime={handleUpdateStartTime}
            onUpdateEndTime={handleUpdateEndTime}
            isEditMode={false}
          />
        );
      default:
        return item.value;
    }
  };

  const save = () => {
    const newSettings = { ...settings.Settings };
    for (const name in settingsFields) {
      const fieldName = settingsFields[name].fieldName;
      if (fieldName === "DatePeriod") {
        newSettings["StartDate"] = settingsFields["Trading Period"].startDate;
        newSettings["EndDate"] = settingsFields["Trading Period"].endDate;
      } else if (fieldName === "TimePeriod") {
        newSettings["IntradayStartTime"] =
          settingsFields["Trading Hours"].intradayStartTime;
        newSettings["IntradayEndTime"] =
          settingsFields["Trading Hours"].intradayEndTime;
      } else newSettings[fieldName] = settingsFields[name].value;
    }
    onSettingsChange(newSettings);
    onIsEditUpdate(false);
  };

  const validateFields = (settingsFields) => {
    validateFields_Errors(settingsFields);
    validateFields_Warnings(settingsFields);
  };

  const validateFields_Errors = (settingsFields) => {
    const listErrors = [];
    // Strategy Name
    const strategyName = String(settingsFields["Name"].value).trim();
    let { isValid, errorMessage } = validateName(strategyName);
    if (!isValid) listErrors.push(`StrategyName: ${errorMessage}`);
    // Strategy Name - Already Used
    if (
      strategyName !== settings.Settings.StrategyName &&
      listStrategyNames.includes(strategyName)
    ) {
      listErrors.push(`Strategy name already used.`);
    }
    // Capital
    const capital = String(settingsFields["Capital"].value).trim();
    ({ isValid, errorMessage } = validateAmount(capital));
    if (!isValid) listErrors.push(`Capital: ${errorMessage}`);
    // Start Date
    const startDate = String(settingsFields["Trading Period"].startDate).trim();
    ({ isValid, errorMessage } = validateDate(startDate));
    if (!isValid) listErrors.push(`Start Date: ${errorMessage}`);
    // End Date
    const endDate = String(settingsFields["Trading Period"].endDate).trim();
    ({ isValid, errorMessage } = validateDate(endDate, startDate));
    if (!isValid) listErrors.push(`End Date: ${errorMessage}`);
    // Time for Daily Signals
    const timeForDailySignals = String(
      settingsFields["Trading Time"].value
    ).trim();
    ({ isValid, errorMessage } = validateTime(timeForDailySignals));
    if (!isValid) listErrors.push(`Trading Time: ${errorMessage}`);
    // Intraday Time Range
    const timeForMinuteStart = String(
      settingsFields["Trading Hours"].intradayStartTime
    ).trim();
    ({ isValid, errorMessage } = validateTime(timeForMinuteStart));
    if (!isValid) listErrors.push(`Trading Start Time: ${errorMessage}`);
    const timeForMinuteEnd = String(
      settingsFields["Trading Hours"].intradayEndTime
    ).trim();
    ({ isValid, errorMessage } = validateTime(timeForMinuteEnd));
    if (!isValid) listErrors.push(`Trading End Time: ${errorMessage}`);
    if (timeForMinuteStart > timeForMinuteEnd) {
      listErrors.push(`Trading End Time: should be after Trading Start Time`);
    }
    setListValidationErrors(listErrors);
  };

  const validateFields_Warnings = (settingsFields) => {
    const listWarnings = [];
    // Frequency
    const frequency = String(settingsFields["Frequency"].value).trim();
    if (["1 Minute", "5 Minutes"].includes(frequency)) {
      listWarnings.push(`Frequency ${frequency}: simulations can be slow.`);
    }
    // Completed
    setListValidationWarnings(listWarnings);
  };

  return (
    <div className="table-settings-strategy">
      <div className="table-settings-container">
        <div className="table-settings-left">
          {renderSettings(listLeftSettings)}
          {isEdit && renderErrors()}
          {isEdit && renderWarnings()}
        </div>
        <div className="table-settings-right">
          {renderSettings(listRightSettings)}
        </div>
      </div>
      {isEdit && (
        <div>
          <BarSaveCancel
            onCancel={cancel}
            onSave={save}
            isSaveDisabled={listValidationErrors.length > 0}
          />
          <ModalUniverseSelector
            isOpen={isOpenInstrumentSelector}
            email={email}
            strategyId={settings.Settings.StrategyId}
            universeId={settingsFields.Instruments.value}
            listUniverses={listUniverses}
            dictInstruments={configuration.Instruments}
            onSave={handle_UniverseSelector_Save}
            onCancel={handle_UniverseSelector_Cancel}
            onRefreshUniverses={onRefreshUniverses}
          />
        </div>
      )}
    </div>
  );
};
export default TableSettings;
