/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Rule from "./Rule";
import "./TableRules.scss";

const TableRules = ({
  configuration,
  isEntry,
  isIntradayOnly,
  rules,
  onRulesUpdate,
  isEdit,
  onIsEditUpdate,
}) => {
  const generateUniqueIdForParameters = (params) =>
    params.map((param) => ({
      ...param,
      ParameterId: uuidv4(),
    }));

  const createNewRule = (rule) => {
    let newRule = {
      ...rule,
      RuleId: uuidv4(),
      Left: {
        ...rule.Left,
        Parameters: generateUniqueIdForParameters(rule.Left.Parameters),
      },
    };

    if (rule.Right) {
      newRule.Right = {
        ...rule.Right,
        Parameters: generateUniqueIdForParameters(rule.Right.Parameters),
      };
    }

    return newRule;
  };

  const [newRules, setNewRules] = useState(rules.map(createNewRule));
  const [indicators, setIndicators] = useState([]);

  const operators = configuration["Operators"];

  const [dictValidationErrors, setDictValidationErrors] = useState({});
  const [isValidationOk, setIsValidationOk] = useState(true);

  useEffect(() => {
    validateErrors(newRules, dictValidationErrors);
  }, [dictValidationErrors, newRules]);

  useEffect(() => {
    let indicators = isEntry
      ? configuration["Indicators_Entry"]
      : configuration["Indicators_Exit"];
    if (!isIntradayOnly) {
      indicators = Object.fromEntries(
        Object.entries(indicators).filter(
          ([key, indicator]) => !indicator.IsIntradayOnly
        )
      );
    }
    setIndicators(indicators);
  }, [isEntry, isIntradayOnly]);

  const cancel = () => {
    setNewRules(rules);
    setDictValidationErrors({});
    setIsValidationOk(true);
    onIsEditUpdate(false);
  };

  const handleAddItem = () => {
    const newRulesEdited = [
      ...newRules,
      {
        RuleId: uuidv4(),
        Left: {
          Category: "",
          Name: "-",
          Parameters: [],
          Factors: [],
        },
        Operator: {
          Category: "",
          Name: "-",
          Description: "",
        },
        Right: {
          Category: "",
          Name: "-",
          Description: "Bla bla bla",
          Parameters: [],
          Factors: [],
        },
      },
    ];
    setNewRules(newRulesEdited);
  };

  const handleRuleChanged = async (index, newRuleSettings, listErrors) => {
    const newRulesEdited = [
      ...newRules.slice(0, index),
      newRuleSettings,
      ...newRules.slice(index + 1),
    ];
    setNewRules(newRulesEdited);
    await handleValidationErrors(newRuleSettings.RuleId, listErrors);
  };

  const handleRuleDeleted = async (ruleIndex, ruleId) => {
    const newRulesEdited = [
      ...newRules.slice(0, ruleIndex),
      ...newRules.slice(ruleIndex + 1),
    ];
    setNewRules(newRulesEdited);
    await handleValidationErrors(ruleId, []);
  };

  const handleValidationErrors = async (ruleId, listErrors) => {
    setDictValidationErrors((prevDictValidationErrors) => {
      const newDictValidationErrors = {
        ...prevDictValidationErrors,
        [ruleId]: listErrors,
      };
      if (!listErrors || listErrors.length === 0) {
        delete newDictValidationErrors[ruleId];
      }
      return newDictValidationErrors;
    });
  };

  const renderErrors = () => (
    <div className="errors-container">
      {Object.entries(dictValidationErrors).map(([key, listErrors]) =>
        listErrors.map((error) => (
          <div className="error" key={`${key}-${error}`}>
            {error}
          </div>
        ))
      )}
    </div>
  );

  const save = () => {
    onRulesUpdate(newRules);
    onIsEditUpdate(false);
  };

  const validateErrors = (newRules, dictValidationErrors) => {
    // Check parameters number
    let nParams = 0;
    for (let ruleKey in newRules) {
      const rule = newRules[ruleKey];
      if (rule.Left?.Parameters) {
        for (let paramKey in rule.Left.Parameters) {
          const param = rule.Left.Parameters[paramKey];
          nParams += param.Values.length;
        }
      }
      if (rule.Right?.Parameters) {
        for (let paramKey in rule.Right.Parameters) {
          const param = rule.Right.Parameters[paramKey];
          nParams += param.Values.length;
        }
      }
    }
    if (nParams > 14) {
      dictValidationErrors["nParams"] = [
        `Too many parameters: ${nParams}/14. Try to remove optional parameters.`,
      ];
    } else {
      delete dictValidationErrors["nParams"];
    }
    // Update isValidationOk
    let newIsValidationOk = Object.values(dictValidationErrors).every(
      (value) => Array.isArray(value) && value.length === 0
    );
    setIsValidationOk(newIsValidationOk);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {newRules.map((ruleSettings, ruleIndex) => (
        <Rule
          key={ruleIndex}
          ruleIndex={ruleIndex}
          ruleSettings={ruleSettings}
          operators={operators}
          indicators={indicators}
          isEdit={isEdit}
          onRuleChanged={handleRuleChanged}
          onRuleDeleted={handleRuleDeleted}
          onValidationErrorsUpdate={handleValidationErrors}
        />
      ))}
      {isEdit && renderErrors()}
      {isEdit && (
        <div className="edit-buttons-container" style={{ marginTop: "200px" }}>
          <button className="add-new-rule" onClick={() => handleAddItem()}>
            Add new rule
          </button>
          <BarSaveCancel
            onCancel={cancel}
            onSave={save}
            isSaveDisabled={!isValidationOk}
          />
        </div>
      )}
    </div>
  );
};

export default TableRules;
