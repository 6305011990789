/**
 * @since 2023-05-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Table } from "antd";
import SearchBox from "components/Shared/SearchBox";
import { useEffect, useState } from "react";
import "./PanelInstruments.scss";

const PanelInstruments = ({ listInstruments }) => {
  const [instrumentMapping, setInstrumentMapping] = useState(createMappings(listInstruments));
  const [listSecurityTypes, setlistSecurityTypes] = useState(createListSecurityTypes(listInstruments));
  const [filteredInstrumentMapping, setFilteredInstrumentMapping] = useState(instrumentMapping);
  const [filterSecurityType, setFilterSecurityType] = useState("ALL");
  const [filterText, setFilterText] = useState("");

  const columns = [
    {
      title: "Name",
      dataIndex: "SecurityName",
      key: "SecurityName",
      width: "160px",
    },
    {
      title: "Type",
      dataIndex: "SecurityType",
      key: "SecurityType",
      width: "60px",
    },
    {
      title: "Sector",
      dataIndex: "Sector",
      key: "Sector",
      width: "140px",
    },
    {
      title: "SubSector",
      dataIndex: "SubSector",
      key: "SubSector",
      width: "140px",
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
      width: "80px",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
      width: "80px",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
  ];

  useEffect(() => {
    const newInstrumentMapping = createMappings(listInstruments);
    setInstrumentMapping(newInstrumentMapping);
    setlistSecurityTypes(createListSecurityTypes(listInstruments));
    setFilteredInstrumentMapping(newInstrumentMapping);
    setFilterSecurityType("ALL");
    setFilterText("");
  }, [listInstruments]);

  //region Methods

  function createMappings(listInstruments) {
    return listInstruments.map((item, index) => {
      const values = Object.values(item).join("|").toLowerCase();
      return {
        key: index.toString(),
        ...item,
        Searchable: values,
      };
    });
  }

  function createListSecurityTypes(listInstruments) {
    // Reduce the listInstruments array to an object where each key is a SecurityType
    // and its value is the count of that SecurityType
    const securityTypeCounts = listInstruments.reduce((counts, instrument) => {
      if (!counts[instrument.SecurityType]) {
        counts[instrument.SecurityType] = 0;
      }
      counts[instrument.SecurityType]++;
      return counts;
    }, {});

    // Convert the counts object to an array of objects
    const securityTypes = Object.entries(securityTypeCounts).map(([SecurityType, N]) => ({
      SecurityType,
      N,
    }));

    // Calculate the sum of all N
    const totalN = securityTypes.reduce((sum, { N }) => sum + N, 0);

    // Add the "ALL" item to the start of the array
    securityTypes.unshift({
      SecurityType: "ALL",
      N: totalN,
    });

    // Sort the array by SecurityType, keeping 'ALL' at the first
    securityTypes.sort((a, b) => {
      if (a.SecurityType === "ALL") return -1;
      if (b.SecurityType === "ALL") return 1;
      return a.SecurityType.localeCompare(b.SecurityType);
    });

    return securityTypes;
  }

  const handleFilterTextChanged = (fieldName, newFilterText) => {
    const filterTextLowercase = newFilterText.toLowerCase();
    setFilterText(filterTextLowercase);
    updateFilter(filterTextLowercase, filterSecurityType);
  };

  const handleFilterSecurityTypeSelected = (event, newSecurityType) => {
    if (newSecurityType === null) return;
    setFilterSecurityType(newSecurityType);
    updateFilter(filterText, newSecurityType);
  };

  const updateFilter = (filterText, filterSecurityType) => {
    const filteredInstruments = instrumentMapping.filter(
      (instrument) =>
        (instrument.SecurityType === filterSecurityType || filterSecurityType === "ALL") &&
        (!filterText || (filterText && instrument.Searchable.toLowerCase().includes(filterText.toLowerCase())))
    );
    setFilteredInstrumentMapping(filteredInstruments);
  };

  //endregion

  return (
    <>
      <div className="panel-instruments">
        <div className="search-controls">
          <div>
            <ToggleButtonGroup
              className="button-group-security-types"
              value={filterSecurityType}
              exclusive
              onChange={handleFilterSecurityTypeSelected}
              aria-label="view"
            >
              {listSecurityTypes.map(({ SecurityType, N }, index) => (
                <ToggleButton
                  key={SecurityType}
                  value={SecurityType}
                  aria-label={SecurityType}
                  style={{
                    width: "140px",
                    color: filterSecurityType === SecurityType ? "#78bcfb" : "white",
                    borderColor: filterSecurityType === SecurityType ? "#78bcfb" : "initial",
                    borderWidth: filterSecurityType === SecurityType ? "1px" : "initial",
                    borderStyle: filterSecurityType === SecurityType ? "solid" : "initial",
                    marginRight: index !== listSecurityTypes.length - 1 ? "1px" : "0px",
                  }}
                >
                  {`${SecurityType} (${N})`}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "300px",
              maxWidth: "300px",
            }}
          >
            <SearchBox name="search" value={filterText} onUpdate={handleFilterTextChanged} />
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={filteredInstrumentMapping}
          size="middle"
          pagination={false}
          scroll={{ y: "260px" }}
          rowClassName="table-row"
          style={{ marginTop: "20px" }}
        />
      </div>
    </>
  );
};
export default PanelInstruments;
