/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ItemTableSimStatsCollector from "./ItemTableSimStatsCollector";

export default ItemTableSimStatsCollector;
