/**
 * @since 2024-05-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import {
  downloadCurrentPositions,
  downloadInitialPositions,
  downloadTrades,
} from "dataHandling/signal";
import { BiDownload } from "react-icons/bi";
import "./BarActionsAnalysis.scss";

const BarActionsAnalysis = ({ email, signalId, signalName, date }) => {
  const handleCurrentPositions = async () => {
    await downloadCurrentPositions(email, signalId, signalName, date);
  };

  const handleDownloadInitialPositions = async () => {
    await downloadInitialPositions(email, signalId, signalName, date);
  };

  const handleDownloadTrades = async () => {
    await downloadTrades(email, signalId, signalName, date);
  };

  return (
    <div className="bar-actions-analysis">
      <h2
        style={{
          color: "white",
          marginTop: "30px",
          marginBottom: "30px",
          fontSize: "24px",
          color: "rgb(255, 255, 255, 0.7)",
        }}
      >
        Download
      </h2>
      <div className="download-section">
        <div className="download-item" onClick={handleDownloadInitialPositions}>
          <BiDownload className="download-icon" />
          <span className="download-label">Initial Positions</span>
        </div>
        <div className="download-item" onClick={handleCurrentPositions}>
          <BiDownload className="download-icon" />
          <span className="download-label">Current Positions</span>
        </div>
        <div className="download-item" onClick={handleDownloadTrades}>
          <BiDownload className="download-icon" />
          <span className="download-label">Trades</span>
        </div>
      </div>
    </div>
  );
};

export default BarActionsAnalysis;
