/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Table } from "antd";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import "./ItemTableScores.scss";

const ItemTableScores = ({ itemData, showHeaders = true, showRecommendations = true }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scoresMapping, setScoresMapping] = useState([]);

  //region Use Effects

  useEffect(() => {
    (async () => {
      if (itemData === null) return;
      setIsLoading(true);
      const newScoresMapping = mapTableData(itemData);
      setScoresMapping(newScoresMapping);
      setIsLoading(false);
    })();
  }, [itemData]);

  //endregion

  //region Methods

  const getColumns = () => {
    return itemData["Columns"].map((title, index) => ({
      title,
      dataIndex: index.toString(),
      render: (text, record) => {
        const isHeaderRow = Object.keys(record).length === 4;
        if (isHeaderRow) {
          if (index === 0) {
            return {
              children: <span>{text}</span>,
            };
          } else if (index === 1) {
            return {
              children: <span>{text}%</span>,
            };
          } else {
            return {
              children: "",
              props: {
                colSpan: 1,
              },
            };
          }
        } else if (title === "Name") {
          const description = record[5];
          return (
            <Tooltip tooltipText={description}>
              <span>{text}</span>
            </Tooltip>
          );
        } else if (title === "Score" || title === "Goal") {
          const description = record[6];
          return (
            <Tooltip tooltipText={description}>
              <span>{text}</span>
            </Tooltip>
          );
        } else if (title === "Status") {
          const status = record[index];
          let color;
          if (status === "Pass") {
            color = "green";
          } else if (status === "Warn") {
            color = "orange";
          } else if (status === "Fail") {
            color = "red";
          }
          return <span style={{ color }}>{status}</span>;
        } else if (title === "Recommendations") {
          if (!showRecommendations)
            return null;
          const score = record[index - 1];
          const reccomendation = score === "Pass" ? "" : text;
          const description = record[7];
          return (
            <Tooltip tooltipText={description}>
              <span>{reccomendation}</span>
            </Tooltip>
          );
        } else {
          return <span>{text}</span>;
        }
      },
    }));
  };

  const mapTableData = (itemData) => {
    if (!showRecommendations) {
      itemData.Columns = itemData.Columns.filter(item => item !== 'Recommendations');
    }
    return itemData["ValuesByCategory"].flatMap((category) => {
      let items = showHeaders
        ? [[category.Name, category.OverallScore, ""], ...category.Items]
        : [...category.Items];
      return items.map((item, index) => ({
        ...item,
        key: category["Name"] + index.toString(),
      }));
    });
  };

  const getRowClassName = (record, index) => {
    const isHeaderRow = Object.keys(record).length === 4;
    return isHeaderRow ? "first-row" : "table-row";
  };

  //endregion

  return (
    <>
      {!isLoading && (
        <div className="item-table-scores">
          <Table
            columns={getColumns()}
            dataSource={scoresMapping}
            size="middle"
            pagination={false}
            rowClassName={getRowClassName}
          />
        </div>
      )}
    </>
  );
};

export default ItemTableScores;
