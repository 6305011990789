/**
 * @since 2023-05-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import PanelInstruments from "./PanelInstruments";

export default PanelInstruments;
