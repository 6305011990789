/**
 * @since 2023-10-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import "./ButtonBlue.scss";

const ButtonBlue = ({ label, onClick, alignment = "center", width = "185px", height = "50px" }) => {
  return (
    <div className={`buttonBlue ${alignment}`}>
      <button className="button-blue" onClick={onClick} style={{ width: width, height: height }}>
        {label}
      </button>
    </div>
  );
};

export default ButtonBlue;
