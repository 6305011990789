/**
 * @since 2023-04-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { BASE_BACKEND_URL } from "./constants";

const createPortfolioAnalysis = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/createPortfolioAnalysis/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const createPortfolioSettings = async (email) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/createPortfolioSettings/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const deletePortfolioSettings = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/deletePortfolioSettings/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const duplicatePortfolioSettings = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/duplicatePortfolioSettings/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const getStatusPortfolioAnalysis = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/getStatusPortfolioAnalysis/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPortfolioAnalysis = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadPortfolioAnalysis/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPortfolioPreview = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadPortfolioPreview/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPortfolioSettings = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadPortfolioSettings/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPortfolioReports = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadPortfolioReports/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPortfolioScores = async (email, portfolioId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadPortfolioScores/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, portfolioId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const savePortfolioSettings = async (email, portfolioId, portfolioSettings) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/savePortfolioSettings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, portfolioId, portfolioSettings }),
    });
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const waitForPortfolioAnalysis = async (email, portfolioId, delay = 1000) => {
  const status = await getStatusPortfolioAnalysis(email, portfolioId);
  if (status === "WAITING") {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const result = await waitForPortfolioAnalysis(email, portfolioId, delay);
        resolve(result);
      }, delay);
    });
  } else {
    return status;
  }
};

export {
  createPortfolioAnalysis,
  createPortfolioSettings,
  deletePortfolioSettings,
  duplicatePortfolioSettings,
  getStatusPortfolioAnalysis,
  loadPortfolioAnalysis,
  loadPortfolioPreview,
  loadPortfolioReports,
  loadPortfolioScores,
  loadPortfolioSettings,
  savePortfolioSettings,
  waitForPortfolioAnalysis
};
