/**
 * @since 2023-04-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";

const SectionScoresStart = ({ onClick }) => {
  return (
    <div style={{ marginLeft: "30px", marginTop: "60px" }}>
      <p
        style={{
          fontSize: "16px",
          color: "white",
        }}
      >
        If you are satisfied with the strategy, you may initiate the in-depth analysis.
        <br />
        <br />
        It might take several hours as many tests will be performed.
        <br />
        <br />
        During the process, you will not be able to modify the strategy, although you can still clone it using the menu
        on the left and modify the copy.
        <br />
        <br />
      </p>
      <ButtonBlue label="START IN-DEPTH ANALYSIS" width="220px" alignment="left" onClick={onClick} />
    </div>
  );
};
export default SectionScoresStart;
