/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import PanelReportViewer from "components/Shared/PanelReportViewer";
import TextCopyright from "components/Shared/TextCopyright";
import { loadPremiumReports } from "dataHandling/premium";
import { useEffect, useState } from "react";

const PanelReports = ({ email, portfolioId }) => {
  //region States

  const [status, setStatus] = useState("");
  const [reportsData, setReportsData] = useState({});

  //endregion

  //region Use Effects

  useEffect(() => {
    fetchData();
  }, [portfolioId, email]);

  //endregion

  //region Methods

  const fetchData = async () => {
    setStatus("WAITING");
    const newReportsData = await loadPremiumReports(email, portfolioId);
    setReportsData(newReportsData);
    setStatus("OK");
  };

  //endregion

  return (
    <div>
      {status === "OK" && (
        <>
          <PanelReportViewer reportsData={reportsData} showRecommendations={false} />
          <br />
          <br />
          <TextCopyright />
        </>
      )}
    </div>
  );
};
export default PanelReports;
