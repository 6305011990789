/**
 * @since 2023-09-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TableSettings_Api from "./TableSettings_Api";

export default TableSettings_Api;
