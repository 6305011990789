/**
 * @since 2023-10-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import SignalGenerator from "./SignalGenerator";

export default SignalGenerator;
