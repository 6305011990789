/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { useState } from "react";
import "./InputBox.scss";

const InputBox = ({ name, value, onUpdate, nRows = 1, maxLength = 30 }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    onUpdate(name, event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const isMultiline = nRows > 1;
  const inputHeight = isMultiline ? 50 + 14 * nRows : 40;

  return isMultiline ? (
    <textarea
      key={`InputBox.${name}`}
      className={`clearable-input ${isFocused ? "focused" : ""}`}
      style={{ height: inputHeight }}
      value={value}
      rows={5}
      maxLength={maxLength}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  ) : (
    <>
      <input
        key={`InputBox.${name}`}
        className={`clearable-input ${isFocused ? "focused" : ""}`}
        style={{ height: inputHeight, cursor: "text" }}
        type="text"
        value={value}
        maxLength={maxLength}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </>
  );
};
export default InputBox;
