/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import MultipleChoiceBox from "./MultipleChoiceBox";

export default MultipleChoiceBox;
