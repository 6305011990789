import { Table } from "antd";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import "./TableWithPagination.scss";

const TableWithPagination = ({ dataTable, columns }) => {
  const [pageSize, setPageSize] = useState(10);
  const [paginationEnabled, setPaginationEnabled] = useState(true);

  useEffect(() => {
    setPaginationEnabled(dataTable && dataTable.length > 14);
  }, [dataTable]);

  const enhancedColumns = columns.map((column) => ({
    ...column,
    title: column.tooltip ? (
      <Tooltip tooltipText={column.tooltip}>
        <span>{column.title}</span>
      </Tooltip>
    ) : (
      column.title
    ),
  }));

  return (
    <div className="table-with-pagination">
      <Table
        columns={enhancedColumns}
        dataSource={dataTable}
        size="middle"
        pagination={
          paginationEnabled
            ? {
                simple: true,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                onShowSizeChange: (current, size) => {
                  setPageSize(size);
                },
              }
            : false
        }
        rowClassName={"table-row"}
      />
    </div>
  );
};

export default TableWithPagination;
