/**
 * @since 2023-05-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import "./BarSaveCancel.scss";

const BarSaveCancel = ({
  onSave,
  onCancel,
  isSaveDisabled = false,
  saveText = "save",
  cancelText = "cancel",
  errorMessage = "Please correct errors to save changes",
}) => {
  return (
    <div className="save-cancel-buttons-container">
      <button className="cancel-button" onClick={onCancel}>
        {cancelText}
      </button>
      <button
        className="save-button"
        onClick={onSave}
        title={isSaveDisabled ? errorMessage : ""}
        disabled={isSaveDisabled}
      >
        {saveText}
      </button>
    </div>
  );
};

export default BarSaveCancel;
