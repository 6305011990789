/**
 * @since 2023-11-18
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ItemChartSimStatsCollectorWithDrawdowns from "./ItemChartSimStatsCollectorWithDrawdowns";

export default ItemChartSimStatsCollectorWithDrawdowns;
