/**
 * @since 2024-05-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ModalPasswordChange from "./ModalPasswordChange";

export default ModalPasswordChange;
