/**
 * @since 2023-10-16
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import Tooltip from "components/Shared/Tooltip";
import { useIntl } from "react-intl";
import "./NotificationCheckboxGroup.scss";

const NotificationCheckboxGroup = ({
  sendStartOfDayNotifications,
  sendTradesNotifications,
  sendEndOfDayNotifications,
  sendRiskManagementNotifications,
  onStartOfDayNotificationsChange,
  onTradesNotificationsChange,
  onEndOfDayNotificationsChange,
  onRiskManagementNotificationsChange,
  isEdit,
}) => {
  const intl = useIntl();

  //region Methods

  const handleInputChange_EndOfDay = (settingName, settingNewValue) => {
    onEndOfDayNotificationsChange(settingNewValue === "Yes");
  };

  const handleInputChange_StartOfDay = (settingName, settingNewValue) => {
    onStartOfDayNotificationsChange(settingNewValue === "Yes");
  };

  const handleInputChange_RiskManagement = (settingName, settingNewValue) => {
    onRiskManagementNotificationsChange(settingNewValue === "Yes");
  };

  const handleInputChange_Trades = (settingName, settingNewValue) => {
    onTradesNotificationsChange(settingNewValue === "Yes");
  };

  //endregion

  return (
    <div className="notification-checkbox-group-container">
      {/* Start of day emails */}
      <div className="settings-fields">
        <Tooltip tooltipText={intl.formatMessage({ id: "signalgenerator-settings-startofday" })} size="small">
          <div className="setting-name" style={{ marginTop: isEdit ? "12px" : "0px" }}>
            {"Receive start of day notifications"}
          </div>
        </Tooltip>
        <div className="setting-checkbox-value">
          {isEdit ? (
            <MultipleChoiceBox
              name={"StartOfDay"}
              choices={["Yes", "No"]}
              value={sendStartOfDayNotifications ? "Yes" : "No"}
              onUpdate={handleInputChange_StartOfDay}
            />
          ) : sendStartOfDayNotifications ? (
            "Yes"
          ) : (
            "No"
          )}
        </div>
      </div>
      {/* Trades emails */}
      <div className="settings-fields">
        <Tooltip tooltipText={intl.formatMessage({ id: "signalgenerator-settings-trades" })} size="small">
          <div className="setting-name" style={{ marginTop: isEdit ? "12px" : "0px" }}>
            {"Receive new trades notifications"}
          </div>
        </Tooltip>
        <div className="setting-checkbox-value">
          {isEdit ? (
            <MultipleChoiceBox
              name={"NewTrades"}
              choices={["Yes", "No"]}
              value={sendTradesNotifications ? "Yes" : "No"}
              onUpdate={handleInputChange_Trades}
            />
          ) : sendTradesNotifications ? (
            "Yes"
          ) : (
            "No"
          )}
        </div>
      </div>
      {/* End of day emails */}
      <div className="settings-fields">
        <Tooltip tooltipText={intl.formatMessage({ id: "signalgenerator-settings-endofday" })} size="small">
          <div className="setting-name" style={{ marginTop: isEdit ? "12px" : "0px" }}>
            {"Receive end of day notifications"}
          </div>
        </Tooltip>
        <div className="setting-checkbox-value">
          {isEdit ? (
            <MultipleChoiceBox
              name={"EndOfDay"}
              choices={["Yes", "No"]}
              value={sendEndOfDayNotifications ? "Yes" : "No"}
              onUpdate={handleInputChange_EndOfDay}
            />
          ) : sendEndOfDayNotifications ? (
            "Yes"
          ) : (
            "No"
          )}
        </div>
      </div>
      {/* Risk Management emails */}
      <div className="settings-fields">
        <Tooltip tooltipText={intl.formatMessage({ id: "signalgenerator-settings-riskmanagement" })} size="small">
          <div className="setting-name" style={{ marginTop: isEdit ? "12px" : "0px" }}>
            {"Receive risk management notifications"}
          </div>
        </Tooltip>
        <div className="setting-checkbox-value">
          {isEdit ? (
            <MultipleChoiceBox
              name={"RiskManagement"}
              choices={["Yes", "No"]}
              value={sendRiskManagementNotifications ? "Yes" : "No"}
              onUpdate={handleInputChange_RiskManagement}
            />
          ) : sendRiskManagementNotifications ? (
            "Yes"
          ) : (
            "No"
          )}
        </div>
      </div>
    </div>
  );
};
export default NotificationCheckboxGroup;
