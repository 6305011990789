/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import TableScoresWithFilters from "components/Shared/TableScoresWithFilters";
import TextCopyright from "components/Shared/TextCopyright";
import { loadPremiumScores } from "dataHandling/premium";
import { useEffect, useState } from "react";

const PanelScores = ({ email, portfolioId, onReportsClick }) => {
  //region States

  const [status, setStatus] = useState("");
  const [scoresTable, setScoresTable] = useState(null);

  //endregion

  //region Use Effects

  useEffect(() => {
    fetchData();
  }, [portfolioId, email]);

  //endregion

  //region Methods
  const fetchData = async () => {
    const newScoresTable = await loadPremiumScores(email, portfolioId);
    setScoresTable(newScoresTable);
    setStatus("OK");
  };

  //endregion

  return (
    <div>
      {status === "OK" && (
        <>
          <TableScoresWithFilters scoresTable={scoresTable} showRecommendations={false} />
          <ButtonBlue label="SEE REPORTS" onClick={onReportsClick} />
          <TextCopyright />
        </>
      )}
    </div>
  );
};

export default PanelScores;
