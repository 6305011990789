/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ItemChartFactorExposure from "components/Shared/Report/Items/ItemChartFactorExposure";
import ItemChartSimStats from "components/Shared/Report/Items/ItemChartSimStats";
import ItemChartSimStatsCollector from "components/Shared/Report/Items/ItemChartSimStatsCollector";
import ItemChartSimStatsCollectorWithDrawdowns from "components/Shared/Report/Items/ItemChartSimStatsCollectorWithDrawdowns";
import ItemIndicatorStats from "components/Shared/Report/Items/ItemIndicatorStats";
import ItemPerformanceStats from "components/Shared/Report/Items/ItemPerformanceStats";
import ItemTableCorrelations from "components/Shared/Report/Items/ItemTableCorrelations";
import ItemTableDataFrame from "components/Shared/Report/Items/ItemTableDataFrame";
import ItemTableFactorExposureIndividual from "components/Shared/Report/Items/ItemTableFactorExposureIndividual";
import ItemTableFactorExposureOverall from "components/Shared/Report/Items/ItemTableFactorExposureOverall";
import ItemTableLiveTrades from "components/Shared/Report/Items/ItemTableLiveTrades";
import ItemTablePortfolioWeights from "components/Shared/Report/Items/ItemTablePortfolioWeights";
import ItemTableReturnsByMonth from "components/Shared/Report/Items/ItemTableReturnsByMonth";
import ItemTableScores from "components/Shared/Report/Items/ItemTableScores";
import ItemTableSimStats from "components/Shared/Report/Items/ItemTableSimStats";
import ItemTableSimStatsCollector from "components/Shared/Report/Items/ItemTableSimStatsCollector";
import ItemTableTrades from "components/Shared/Report/Items/ItemTableTrades";
import ItemText from "components/Shared/Report/Items/ItemText";
import TableGeneric from "components/Shared/TableGeneric";

const Section = ({
  email,
  moduleType,
  moduleId,
  sectionData,
  showRecommendations = true,
}) => {
  //region Methods

  const showItem = (itemData, key) => {
    switch (itemData.Type) {
      case "ItemChartDataFrame":
        return (
          <ItemChartSimStatsCollector itemData={itemData.Data} key={key} />
        );
      case "ItemChartSimStats":
        return <ItemChartSimStats itemData={itemData.Data} key={key} />;
      case "ItemChartFactorExposure":
        return <ItemChartFactorExposure itemData={itemData.Data} key={key} />;
      case "ItemChartSimStatsCollector":
        return (
          <ItemChartSimStatsCollector itemData={itemData.Data} key={key} />
        );
      case "ItemChartSimStatsCollectorWithDrawdowns":
        return (
          <ItemChartSimStatsCollectorWithDrawdowns
            itemData={itemData.Data}
            key={key}
          />
        );
      case "ItemIndicatorStats":
        return <ItemIndicatorStats itemData={itemData.Data} key={key} />;
      case "ItemPerformanceStats":
        return <ItemPerformanceStats itemData={itemData.Data} key={key} />;
      case "ItemTableCorrelationMatrix":
        return <TableGeneric itemData={itemData.Data} key={key} />;
      case "ItemTableCorrelations":
        return <ItemTableCorrelations itemData={itemData.Data} key={key} />;
      case "ItemTableDataFrame":
        return <ItemTableDataFrame itemData={itemData.Data} key={key} />;
      case "ItemTableFactorExposureIndividual":
        return (
          <ItemTableFactorExposureIndividual
            itemData={itemData.Data}
            key={key}
          />
        );
      case "ItemTableFactorExposureOverall":
        return (
          <ItemTableFactorExposureOverall itemData={itemData.Data} key={key} />
        );
      case "ItemTableLiveTrades":
        return <ItemTableLiveTrades itemData={itemData.Data} key={key} />;
      case "ItemTablePortfolioWeights":
        return <ItemTablePortfolioWeights itemData={itemData.Data} key={key} />;
      case "ItemTableScores":
        return (
          <ItemTableScores
            itemData={itemData.Data}
            showRecommendations={showRecommendations}
            key={key}
          />
        );
      case "ItemTableSimStats":
        return <ItemTableSimStats itemData={itemData.Data} key={key} />;
      case "ItemTableSimStatsCollector":
        return (
          <ItemTableSimStatsCollector itemData={itemData.Data} key={key} />
        );
      case "ItemTableReturnsByMonth":
        return <ItemTableReturnsByMonth itemData={itemData.Data} key={key} />;
      case "ItemTableTrades":
        return <ItemTableTrades itemData={itemData.Data} key={key} />;
      case "ItemText":
        return <ItemText itemData={itemData.Data} key={key} />;
      default:
        // Just show a label with the component type.
        return <ItemText itemData={itemData.Type} />;
    }
  };

  //endregion

  return (
    <>
      {sectionData && (
        <div>
          <h2
            style={{
              color: "white",
              marginTop: "50px",
              fontSize: "24px",
              color: "rgb(255, 255, 255, 0.7)",
              cursor: "default",
            }}
          >
            {sectionData.Name}
          </h2>
          <p
            style={{
              color: "white",
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: "14px",
              cursor: "default",
            }}
          >
            {sectionData.Description}
          </p>
          {sectionData.Items &&
            sectionData.Items.map((itemData, index) =>
              showItem(itemData, `sectionItem-${index}`)
            )}
        </div>
      )}
    </>
  );
};

export default Section;
