/**
 * @since 2023-05-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import BarSaveCancel from "./BarSaveCancel";

export default BarSaveCancel;
