/**
 * @since 2023-04-19
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

const toDollars = (value) => {
  return "$" + toFloat(value);
};

const toFloat = (value) => {
  let newValue = String(value).replace(/\$|,/g, "");
  const floatValue = parseFloat(newValue);
  if (isNaN(floatValue)) {
    return "";
  }
  return String(new Intl.NumberFormat("en-US").format(floatValue));
};
const toInteger = (value) => {
  let newValue = String(value).replace(/\$|,/g, "");
  const intValue = parseInt(newValue);
  if (intValue >= 0)
    return String(new Intl.NumberFormat("en-US").format(intValue));
  return value;
};

const toPercentage = (value) => {
  const stringValue = String(value).trim();
  if (stringValue.length === 0) return "None";
  let newValue = String(value).replace(/%|,/g, "");
  const floatValue = parseFloat(newValue);
  return String(new Intl.NumberFormat("en-US").format(floatValue)) + "%";
};

const toType = (value, type) => {
  if (type === "Dollar") return toDollars(value);
  if (type === "Percentage") return toPercentage(value);
  return value;
};

export { toDollars, toFloat, toInteger, toPercentage, toType };
