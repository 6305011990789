/**
 * @since 2023-04-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import TextError from "components/Shared/TextError";

const SectionScoresError = ({ onClick }) => {
  return (
    <div style={{ marginLeft: "30px", marginTop: "60px" }}>
      <p
        style={{
          fontSize: "16px",
          color: "white",
        }}
      >
        <TextError
          error={
            "Something went wrong, we are working on it. Please try again later."
          }
        />
        <ButtonBlue
          label="START IN-DEPTH ANALYSIS"
          width="220px"
          alignment="left"
          onClick={onClick}
        />
      </p>
    </div>
  );
};
export default SectionScoresError;
