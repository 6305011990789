/**
 * @since 2024-06-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TableWithPagination from "components/Shared/TableWithPagination";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { parseNumber } from "utils/parsing";

const ItemTablePortfolioWeights = ({ itemData }) => {
  const intl = useIntl();
  const [dataTable, setDataTable] = useState(null);

  console.log("itemData", itemData);

  const columns = [
    {
      title: "Strategy Name",
      dataIndex: "index",
      key: "index",
      sorter: (a, b) => a.index.localeCompare(b.index),
      showSorterTooltip: false,
    },
    {
      title: "Portfolio Weights",
      dataIndex: "portfolioWeights",
      key: "portfolioWeights",
      align: "center",
      tooltip: intl.formatMessage({
        id: "reports-optimization-portfolioweights",
      }),
      sorter: (a, b) =>
        parseNumber(a.portfolioWeights) - parseNumber(b.portfolioWeights),
      showSorterTooltip: false,
    },
    {
      title: "Equal Weights",
      dataIndex: "equalWeights",
      key: "equalWeights",
      align: "center",
      tooltip: intl.formatMessage({ id: "reports-optimization-equalweights" }),
      sorter: (a, b) =>
        parseNumber(a.equalWeights) - parseNumber(b.equalWeights),
      showSorterTooltip: false,
    },
    {
      title: "Min Variance",
      dataIndex: "minVariance",
      key: "minVariance",
      align: "center",
      tooltip: intl.formatMessage({ id: "reports-optimization-minvariance" }),
      sorter: (a, b) => parseNumber(a.minVariance) - parseNumber(b.minVariance),
      showSorterTooltip: false,
    },
    {
      title: "Risk Parity",
      dataIndex: "riskParity",
      key: "riskParity",
      align: "center",
      tooltip: intl.formatMessage({ id: "reports-optimization-riskparity" }),
      sorter: (a, b) => parseNumber(a.riskParity) - parseNumber(b.riskParity),
      showSorterTooltip: false,
    },
    {
      title: "Max Sharpe Ratio",
      dataIndex: "maxSharpeRatio",
      key: "maxSharpeRatio",
      align: "center",
      tooltip: intl.formatMessage({
        id: "reports-optimization-maxsharperatio",
      }),
      sorter: (a, b) =>
        parseNumber(a.maxSharpeRatio) - parseNumber(b.maxSharpeRatio),
      showSorterTooltip: false,
    },
  ];

  useEffect(() => {
    if (itemData === null) return;
    setDataTable(convertToAntTableData(itemData));
  }, [itemData]);

  function convertToAntTableData(data) {
    return data.Index.map((indexName, rowIndex) => {
      let tableItem = {
        key: rowIndex,
        index: indexName,
        portfolioWeights: parseNumber(itemData.Values[rowIndex][0]),
        equalWeights: parseNumber(itemData.Values[rowIndex][1]),
        minVariance: parseNumber(itemData.Values[rowIndex][2]),
        riskParity: parseNumber(itemData.Values[rowIndex][3]),
        maxSharpeRatio: parseNumber(itemData.Values[rowIndex][4]),
      };
      return tableItem;
    });
  }

  return (
    <div style={{ width: "80%", maxWidth: "1000px" }}>
      <TableWithPagination dataTable={dataTable} columns={columns} />
    </div>
  );
};
export default ItemTablePortfolioWeights;
