/**
 * @since 2023-04-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { BASE_BACKEND_URL } from "./constants";
import { createExcelDownload } from "./utils";

const createApiKey = async (signalId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/createApiKey/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ signalId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const createSignalSettings = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/createSignalSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const deleteSignalSettings = async (email, signalId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/deleteSignalSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const downloadCurrentPositions = async (email, signalId, signalName, date) => {
  date = String(date).replace(/-/g, "");
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/downloadLiveCurrentPositions/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId, date }),
      }
    );
    if (response.ok) {
      await createExcelDownload(
        response,
        signalName + "." + date + ".CurrentPositions.xlsx"
      );
    } else {
      console.error("Cannot download initial positions.");
    }
  } catch (error) {
    console.error(error);
  }
};

const downloadInitialPositions = async (email, signalId, signalName, date) => {
  date = String(date).replace(/-/g, "");
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/downloadLiveInitialPositions/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId, date }),
      }
    );
    if (response.ok) {
      await createExcelDownload(
        response,
        signalName + "." + date + ".InitialPositions.xlsx"
      );
    } else {
      console.error("Cannot download initial positions.");
    }
  } catch (error) {
    console.error(error);
  }
};

const downloadTrades = async (email, signalId, signalName, date) => {
  date = String(date).replace(/-/g, "");
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/downloadLiveTrades/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId, date }),
      }
    );
    if (response.ok) {
      await createExcelDownload(
        response,
        signalName + "." + date + ".Trades.xlsx"
      );
    } else {
      console.error("Cannot download trades.");
    }
  } catch (error) {
    console.error(error);
  }
};

const duplicateSignalSettings = async (email, signalId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/duplicateSignalSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const getLiveStatsDates = async (email, signalId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/getLiveStatsDates/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadLiveStats = async (email, signalId, date) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadLiveStats/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId, date }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadSignalSettings = async (email, signalId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadSignalSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const notifyEmailTest = async (email, signalId, listEmails) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/notifyEmailTest/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId, listEmails }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const notifySlackTest = async (slackWebhookUrl) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/notifySlackTest/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ slackWebhookUrl }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const notifyTextMessageTest = async (email, signalId, phoneNumber) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/notifyTextMessageTest/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId, phoneNumber }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const saveSignalSettings = async (email, signalId, signalSettings) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/saveSignalSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, signalId, signalSettings }),
      }
    );
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

export {
  createApiKey,
  createSignalSettings,
  deleteSignalSettings,
  downloadCurrentPositions,
  downloadInitialPositions,
  downloadTrades,
  duplicateSignalSettings,
  getLiveStatsDates,
  loadLiveStats,
  loadSignalSettings,
  notifyEmailTest,
  notifySlackTest,
  notifyTextMessageTest,
  saveSignalSettings,
};
