/**
 * @since 2023-07-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { BASE_BACKEND_URL } from "./constants";

const createImportedAnalysis = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/createImportedAnalysis/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const createImportedSettings = async (email) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/createImportedSettings/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const deleteImportedSettings = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/deleteImportedSettings/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const duplicateImportedSettings = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/duplicateImportedSettings/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const getStatusImportedAnalysis = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/getStatusImportedAnalysis/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};


const loadImportedAnalysis = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadImportedAnalysis/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadImportedPreview = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadImportedPreview/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadImportedSettings = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadImportedSettings/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadImportedReports = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadImportedReports/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadImportedScores = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/loadImportedScores/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, importedId }),
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const saveImportedSettings = async (email, importedId, importedSettings) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/saveImportedSettings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, importedId, importedSettings }),
    });
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const startImportedReports = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/startImportedReports/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, importedId }),
    });
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const stopImportedReports = async (email, importedId) => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/strategy/stopImportedReports/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, importedId }),
    });
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const waitForImportedAnalysis = async (email, importedId, delay = 1000) => {
  const status = await getStatusImportedAnalysis(email, importedId);
  if (status === "WAITING") {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const result = await waitForImportedAnalysis(email, importedId, delay);
        resolve(result);
      }, delay);
    });
  } else {
    return status;
  }
};


export {
  createImportedAnalysis,
  createImportedSettings,
  deleteImportedSettings,
  duplicateImportedSettings,
  getStatusImportedAnalysis,
  loadImportedAnalysis,
  loadImportedPreview,
  loadImportedReports,
  loadImportedScores,
  loadImportedSettings,
  saveImportedSettings,
  startImportedReports,
  stopImportedReports,
  waitForImportedAnalysis
};

