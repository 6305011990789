/**
 * @since 2023-05-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Modal } from "antd";
import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import { sendFeedback } from "dataHandling/general.js";
import { useEffect, useState } from "react";
import "./ModalFeedback.scss";

const ModalFeedback = ({ isOpen, email, onCancel, onSent }) => {
  const categories = ["REQUEST FEATURE", "REPORT BUG", "OTHER"];
  const [category, setCategory] = useState("REQUEST FEATURE");
  const [feedback, setFeedback] = useState("");

  //region UseEffects

  useEffect(() => {
    setCategory("REQUEST FEATURE");
    setFeedback("");
  }, [isOpen]);

  //endregion

  const handleCategoryChange = (event, newCategory) => {
    setCategory(newCategory);
  };

  const handleFeedbackChange = (event, newFeedback) => {
    setFeedback(newFeedback);
  };

  const handleSend = async () => {
    await sendFeedback(email, category, feedback);
    onSent();
  };

  return (
    <Modal open={isOpen} title={"FEEDBACK FORM"} footer={null} onCancel={onCancel} className="modal-feedback">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "40px",
        }}
      >
        <div>
          <ToggleButtonGroup
            className="button-group-category"
            value={category}
            exclusive
            onChange={handleCategoryChange}
            aria-label="view"
          >
            {categories.map((cat, index) => (
              <ToggleButton
                key={cat}
                value={cat}
                aria-label={cat}
                style={{
                  width: "250px",
                  color: category === cat ? "#78bcfb" : "white",
                  borderColor: category === cat ? "#78bcfb" : "initial",
                  borderWidth: category === cat ? "1px" : "initial",
                  borderStyle: category === cat ? "solid" : "initial",
                  marginRight: index !== categories.length - 1 ? "1px" : "0px",
                }}
              >
                {`${cat}`}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        <div
          style={{
            width: "750px",
            marginTop: "40px",
            marginBottom: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "14px",
            color: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <InputBox name={"Feedback"} value={feedback} onUpdate={handleFeedbackChange} nRows={10} maxLength={400} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <BarSaveCancel
            saveText={"submit"}
            onCancel={onCancel}
            onSave={handleSend}
            isSaveDisabled={feedback.length === 0}
            errorMessage={"Add some feedback."}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalFeedback;
