/**
 * @since 2023-10-25
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { VIEW_DEFAULT } from "constants";

class MenuItem {
  constructor(module, itemId, itemName, itemView = null) {
    this.Module = module ? module.toUpperCase() : null; // e.g. STRATEGY
    this.ItemId = itemId; // e.g. PRT-20230123-123456
    this.ItemName = itemName; // e.g. Strategy 01
    this.ItemView = itemView
      ? itemView
      : this.Module
      ? VIEW_DEFAULT[this.Module].toUpperCase()
      : null; // e.g. Settings
  }

  capitalizeFirstLetter(string) {
    if (!string || typeof string !== "string") return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  equals(other) {
    return (
      this.Module === other.Module &&
      this.ItemId === other.ItemId &&
      this.ItemName === other.ItemName &&
      this.ItemView === other.ItemView
    );
  }

  getKey() {
    return `${this.Module}|${this.ItemId}|${this.ItemName}`;
  }

  getTitle() {
    return `${this.ItemName} ${this.capitalizeFirstLetter(this.ItemView)}`;
  }

  getUrl() {
    return `/${this.Module.toLowerCase()}/${
      this.ItemId
    }/${this.ItemView.toLowerCase()}`;
  }
}

export default MenuItem;
