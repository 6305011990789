/**
 * @since 2023-08-08
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import * as echarts from "echarts";
import { useEffect, useRef } from "react";

const ItemChartFactorExposure = ({ itemData, height = 400, showXAxis = true, showLegend = true }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Add this part
    const chartInstance = echarts.getInstanceByDom(chartRef.current);
    if (chartInstance) {
      chartInstance.dispose();
    }
    const myChart = echarts.init(chartRef.current);
    const allReturns = Object.values(itemData.Values).map((item) => item.Returns);
    const flattenedReturns = [].concat(...allReturns);
    const minValue = Math.min(0, ...flattenedReturns);
    const maxValue = Math.max(0, ...flattenedReturns);
    const yAxisPadding = (maxValue - minValue) * 0.1;
    const minY = Math.floor(minValue - yAxisPadding);
    const maxY = Math.ceil(maxValue + yAxisPadding);

    // Chart
    const option = {
      grid: {
        left: "0%",
        right: "0%",
        top: "2 %",
        bottom: "0%",
        containLabel: true,
      },
      legend: {
        type: "scroll",
        textStyle: {
          color: "white",
          fontSize: 14,
        },
        top: 0,
        left: 50,
        data: itemData.Values.map((item) => item.Name),
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: function (params) {
          let res = params[0].axisValueLabel + "<br/>";
          params.forEach((param) => {
            res += param.marker + " " + param.seriesName + ": " + param.value + "<br/>";
          });
          return res;
        },
        backgroundColor: "#191A1D",
        borderColor: "#78BCFB",
        borderWidth: 1,
        textStyle: {
          color: "rgba(255, 255, 255, 0.5)",
          fontSize: 12,
        },
      },
      xAxis: {
        type: "category",
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            opacity: 0.1,
          },
          minorSplitLine: {
            show: true,
            lineStyle: {
              opacity: 0.1,
            },
          },
        },
        data: itemData.Dates,
        axisPointer: {
          show: true,
          type: "line",
          snap: true,
          label: {
            show: true,
            formatter: function (params) {
              return params.value;
            },
          },
        },
        axisLabel: {
          show: showXAxis,
          rotate: 45,
          formatter: function (value) {
            return new Date(value).getFullYear();
          },
        },
      },
      yAxis: {
        type: "value",
        min: minY,
        max: maxY,
        splitLine: {
          show: false,
        },
        axisLine: {
          show: true,
        },
      },
      series: itemData.Values.map((item) => {
        return {
          ...item,
          name: item.Name,
          type: "line",
          showSymbol: false,
          data: item.Returns,
          areaStyle: {
            color: item.Color,
            opacity: 0.5,
            origin: "start",
          },
        };
      }),
    };

    myChart.setOption(option);

    function resizeChart() {
      const chartWidth = myChart?.getDom().offsetWidth * 0.98;
      const chartHeight = myChart?.getDom().offsetHeight * 0.98;
      myChart?.resize({ width: chartWidth, height: chartHeight });
    }

    const onWindowResize = () => {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (!chartInstance) return;

      chartInstance.resize();
      resizeChart();
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [itemData]);

  return (
    <>
      <div ref={chartRef} style={{ width: "100%", height: height }} />
    </>
  );
};

export default ItemChartFactorExposure;
