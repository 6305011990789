/**
 * @since 2023-08-08
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TableSimulationStats from "components/Shared/TableSimulationStats";

const ItemTableSimStats = ({ itemData }) => {
  return <TableSimulationStats isLoading={false} data={itemData} />;
};

export default ItemTableSimStats;
