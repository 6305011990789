/**
 * @since 2023-09-29
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import TableWithPagination from "components/Shared/TableWithPagination";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { parseNumber } from "utils/parsing";
import "./TableLiveTrades.scss";

const TableLiveTrades = ({ itemData }) => {
  const intl = useIntl();

  const [dataTable, setDataTable] = useState(null);

  const columns = [
    {
      title: "Date/Time",
      dataIndex: "datetime",
      key: "datetime",
      tooltip: intl.formatMessage({
        id: "live-trades-datetime",
      }),
      sorter: (a, b) => new Date(a.datetime) - new Date(b.datetime),
      showSorterTooltip: false,
    },
    {
      title: "Strategy",
      dataIndex: "strategy",
      key: "strategy",
      tooltip: intl.formatMessage({
        id: "live-trades-strategy",
      }),
      sorter: (a, b) => a.strategy.localeCompare(b.strategy),
      showSorterTooltip: false,
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      key: "instrument",
      tooltip: intl.formatMessage({
        id: "live-trades-instrument",
      }),
      sorter: (a, b) => a.instrument.localeCompare(b.instrument),
      showSorterTooltip: false,
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      key: "ticker",
      tooltip: intl.formatMessage({
        id: "live-trades-ticker",
      }),
      sorter: (a, b) => a.ticker.localeCompare(b.ticker),
      showSorterTooltip: false,
    },
    {
      title: "Traded",
      dataIndex: "traded",
      key: "traded",
      tooltip: intl.formatMessage({
        id: "live-trades-traded",
      }),
      className: "right-aligned-cell",
      sorter: (a, b) => parseNumber(a.traded) - parseNumber(b.traded),
      showSorterTooltip: false,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      tooltip: intl.formatMessage({
        id: "live-trades-price",
      }),
      className: "right-aligned-cell",
      sorter: (a, b) => parseNumber(a.price) - parseNumber(b.price),
      showSorterTooltip: false,
    },
    {
      title: "New Position",
      dataIndex: "position",
      key: "position",
      tooltip: intl.formatMessage({
        id: "live-trades-newposition",
      }),
      className: "right-aligned-cell",
      sorter: (a, b) => parseNumber(a.position) - parseNumber(b.position),
      showSorterTooltip: false,
    },
  ];

  //region Use Effects

  useEffect(() => {
    (async () => {
      if (itemData === null) return;
      setDataTable(convertToAntDTableData(itemData));
    })();
  }, [itemData]);

  //endregion

  //region Methods

  function convertToAntDTableData(data) {
    return data.map((item, index) => ({
      key: index,
      datetime: item.DateTime,
      strategy: item.Strategy,
      instrument: item.Instrument,
      ticker: item.Ticker,
      traded: item.Traded,
      price: item.Price,
      position: item.Position,
    }));
  }

  //endregion

  return <TableWithPagination dataTable={dataTable} columns={columns} />;
};

export default TableLiveTrades;
