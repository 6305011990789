/**
 * @since 2023-10-16
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import NotificationCheckboxGroup from "./NotificationCheckboxGroup";

export default NotificationCheckboxGroup;
