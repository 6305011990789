/**
 * @since 2023-11-14
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Select, Tooltip } from "antd";
//import Tooltip from "components/Shared/Tooltip";
import React from "react";
import "./IndicatorSelector.scss";

const TooltipOption = ({ children, tooltipText }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && <Tooltip title={tooltipText}>{children}</Tooltip>}
      {!isHovered && children}
    </div>
  );
};

const IndicatorSelector = ({
  indicatorIndex,
  indicators,
  currentValue,
  onIndicatorSelected,
}) => {
  const handleValueChange = (value) => {
    const indicator = indicators[value];
    onIndicatorSelected(indicatorIndex, indicator);
  };

  const groupIndicatorsByCategory = (indicators) => {
    const groupedIndicators = {};

    Object.values(indicators).forEach((indicator) => {
      const category = indicator.Category;
      if (!groupedIndicators[category]) {
        groupedIndicators[category] = [];
      }
      groupedIndicators[category].push(indicator);
    });
    // Sort the items within each category alphabetically
    for (const category in groupedIndicators) {
      groupedIndicators[category].sort((a, b) =>
        a.DisplayName.localeCompare(b.DisplayName)
      );
    }

    return groupedIndicators;
  };

  const groupedIndicators = groupIndicatorsByCategory(indicators);

  const optionsWithTooltip = Object.entries(groupedIndicators).map(
    ([category, items]) => {
      return {
        label: category,
        options: items.map((item) => ({
          label: (
            <TooltipOption tooltipText={item?.Description}>
              {item?.DisplayName}
            </TooltipOption>
          ),
          value: item?.IndicatorName,
          filterText: item?.DisplayName,
        })),
      };
    }
  );

  return (
    <Select
      showSearch
      style={{
        width: "100%",
        position: "relative",
      }}
      value={currentValue}
      onChange={handleValueChange}
      className="indicator-selector"
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.filterText ?? "").toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      getPopupContainer={(trigger) => trigger.parentElement}
      autoFocus={false}
      options={optionsWithTooltip}
    />
  );
};

export default IndicatorSelector;
