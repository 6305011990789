/**
 * @since 2023-05-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import { Modal } from "antd";
import "./ModalSubscription.scss";
import StripePricingTable from "./StripePricingTable";

const ModalSubscription = ({ isOpen, email, onCancel }) => {
  return (
    <Modal
      open={isOpen}
      title={"SUBSCRIPTION OPTIONS"}
      footer={null}
      onCancel={onCancel}
      className="modal-subscription"
    >
      <StripePricingTable email={email} />
    </Modal>
  );
};

export default ModalSubscription;
