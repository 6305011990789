/**
 * @since 2023-10-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright All rights reserved
 */

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "components/Shared/Tooltip";
import { Link } from "react-router-dom";
import "./ToggleButtonGroupResearch.scss";

const ToggleButtonGroupResearch = ({ selectedValue, strategyType }) => {
  return (
    <ToggleButtonGroup
      className="toggleButtonGroupResearch"
      value={selectedValue.toUpperCase()}
      exclusive
      aria-label="view"
    >
      <Tooltip tooltipText={"Change the settings of this " + strategyType}>
        <ToggleButton
          value={"SETTINGS"}
          aria-label={"Settings"}
          test-id="view-settings-button"
        >
          <Link to={"settings"} className="unstyledLink">
            Settings
          </Link>
        </ToggleButton>
      </Tooltip>
      <Tooltip tooltipText={"Backtesting analysis of this " + strategyType}>
        <ToggleButton
          value={"ANALYSIS"}
          aria-label={"Analysis"}
          test-id="view-analysis-button"
        >
          <Link to={"analysis"} className="unstyledLink">
            Analysis
          </Link>
        </ToggleButton>
      </Tooltip>
      <Tooltip tooltipText={"Scores of this " + strategyType}>
        <ToggleButton
          value={"SCORES"}
          aria-label={"Scores"}
          test-id="view-scores-button"
        >
          <Link to={"scores"} className="unstyledLink">
            Scores
          </Link>
        </ToggleButton>
      </Tooltip>
      <Tooltip tooltipText={"In-depth reports for this " + strategyType}>
        <ToggleButton
          value={"REPORTS"}
          aria-label={"Reports"}
          test-id="view-reports-button"
        >
          <Link to={"reports"} className="unstyledLink">
            Reports
          </Link>
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

export default ToggleButtonGroupResearch;
